import axios from 'axios';
import {
  SET_RE_OP_ROLES_STEP_ONE,
  SET_RE_OP_ROLES_STEP_TWO,
  SET_RE_OP_ROLES_STEP_THREE,
  SET_RE_OP_ROLES_STEP_FOUR,
  SET_RE_OP_ROLES_STEP_FIVE,
  RESET_RE_OP_ROLES_WIZARD
} from './mutations-types';
import { O365 } from '@/common/data/constants';

const SET_IS_EDITING = 'O365/WIZARDS/RE_OP_ROLES/SET_IS_EDITING';

const RE_OP_ROLES_ENDPOINT = `${O365}/organizations/:organizationId/restore-roles`;
const SINGLE_RE_OP_ROL_ENDPOINT = `${RE_OP_ROLES_ENDPOINT}/:opRoleId`;

function initialState() {
  return {
    roRoleWizard: {
      stepOne: {},
      stepTwo: {},
      stepThree: {},
      stepFour: {},
      stepFive: {},
      isEditing: false
    }
  };
}

const state = initialState();

// actions
const actions = {
  async ADD_RESTORE_OPERATOR_ROL({ state, commit }) {
    const data = getDataToSend(state.roRoleWizard);
    const response = await axios.post(
      RE_OP_ROLES_ENDPOINT.replace(':organizationId', data.organizationId),
      data
    );
    commit(RESET_RE_OP_ROLES_WIZARD);
    return response.data;
  },
  async GET_RESTORE_OPERATOR_ROL({ commit }, { opRoleId, organizationId, companyId }) {
    const response = await axios.get(
      SINGLE_RE_OP_ROL_ENDPOINT.replace(':organizationId', organizationId).replace(
        ':opRoleId',
        opRoleId
      )
    );
    const { data } = response;
    if (data.success) {
      const role = data.data;
      commit(SET_RE_OP_ROLES_STEP_ONE, { ...role, companyId });
      commit(SET_RE_OP_ROLES_STEP_TWO, { ...role });
      commit(SET_RE_OP_ROLES_STEP_THREE, role.operators);
      commit(SET_RE_OP_ROLES_STEP_FOUR, { ...role });
      commit(SET_RE_OP_ROLES_STEP_FIVE, role.excludedItems);
      commit(SET_IS_EDITING, true);
    }
    return data.success;
  },
  async DELETE_RESTORE_OPERATOR_ROL(context, { opRoleId, organizationId }) {
    const response = await axios.delete(
      SINGLE_RE_OP_ROL_ENDPOINT.replace(':organizationId', organizationId).replace(
        ':opRoleId',
        opRoleId
      )
    );
    return response.data;
  },
  async EDIT_RESTORE_OPERATOR_ROL({ state, commit }) {
    const data = getDataToSend(state.roRoleWizard);
    const response = await axios.put(SINGLE_RE_OP_ROL_ENDPOINT.replace(':opRoleId', data.id), data);
    commit(RESET_RE_OP_ROLES_WIZARD);
    return response.data;
  }
};

function getDataToSend(roRoleWizard) {
  const { stepOne, stepTwo, stepThree, stepFour, stepFive } = roRoleWizard;
  return { ...stepOne, ...stepTwo, ...stepThree, ...stepFour, ...stepFive };
}

const getters = {
  getRORoleWizStepOne: state => state.roRoleWizard.stepOne,
  getRORoleWizStepTwo: state => state.roRoleWizard.stepTwo,
  getRORoleWizStepThree: state => state.roRoleWizard.stepThree,
  getRORoleWizStepFour: state => state.roRoleWizard.stepFour,
  getRORoleWizStepFive: state => state.roRoleWizard.stepFive,
  getRORoleWizIsEditing: state => state.roRoleWizard.isEditing
};

const mutations = {
  /* eslint-disable no-undef */
  [SET_RE_OP_ROLES_STEP_ONE](state, { id, name, description, companyId }) {
    Vue.set(state.roRoleWizard.stepOne, 'id', id);
    Vue.set(state.roRoleWizard.stepOne, 'name', name);
    Vue.set(state.roRoleWizard.stepOne, 'description', description);
    Vue.set(state.roRoleWizard.stepOne, 'companyId', companyId);
  },
  [SET_RE_OP_ROLES_STEP_TWO](state, { organizationId }) {
    Vue.set(state.roRoleWizard.stepTwo, 'organizationId', organizationId);
  },
  [SET_RE_OP_ROLES_STEP_THREE](state, operators) {
    Vue.set(state.roRoleWizard.stepThree, 'operators', operators);
  },
  [SET_RE_OP_ROLES_STEP_FOUR](state, { roleType, selectedItems }) {
    Vue.set(state.roRoleWizard.stepFour, 'roleType', roleType);
    Vue.set(state.roRoleWizard.stepFour, 'selectedItems', selectedItems);
  },
  [SET_RE_OP_ROLES_STEP_FIVE](state, excludedItems) {
    Vue.set(state.roRoleWizard.stepFive, 'excludedItems', excludedItems);
  },
  [RESET_RE_OP_ROLES_WIZARD](state) {
    Vue.set(state.roRoleWizard, 'stepOne', {});
    Vue.set(state.roRoleWizard, 'stepTwo', {});
    Vue.set(state.roRoleWizard, 'stepThree', {});
    Vue.set(state.roRoleWizard, 'stepFour', {});
    Vue.set(state.roRoleWizard, 'stepFive', {});
    Vue.set(state.roRoleWizard, 'isEditing', false);
  },
  [SET_IS_EDITING](state, data) {
    Vue.set(state.roRoleWizard, 'isEditing', data);
  }
};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};
