import axios from 'axios';
import { O365 } from '@/common/data/constants';

const SET_REPOSITORIES = 'O365/REPOSITORIES/SET_REPOSITORIES';
const SET_ALL_REPOSITORIES = 'O365/REPOSITORIES/SET_ALL_REPOSITORIES';

const REPOSITORIES_ENDPOINT = `${O365}/repositories`;
const ALL_ORGANIZATIONS_REPOSITORIES_ENDPOINT = `${O365}/repositories/all`;

function initialState() {
  return {
    repositories: [],
    allRepositories: []
  };
}

const state = initialState();

// actions
const actions = {
  async GET_O365_REPOSITORIES({ commit }, organizationId) {
    const response = await axios.get(REPOSITORIES_ENDPOINT, { params: { organizationId } });
    const { data } = response;
    if (data.success) {
      commit(SET_REPOSITORIES, data.data);
    }
  },
  async GET_O365_REPOSITORY({ commit }, {organizationId, repositoryId}) {
    const response = await axios.get(REPOSITORIES_ENDPOINT, { params: { organizationId, repositoryId } });
    const { data } = response;
    if (data.success) {
      commit(SET_REPOSITORIES, data.data);
    }
  },
  async ADD_O365_REPOSITORY({ commit }, payload) {
    const response = await axios.post(REPOSITORIES_ENDPOINT, payload);
    const { data } = response;
    if (data.success) {
      commit(SET_REPOSITORIES, data.data);
    }
  },
  async EDIT_O365_REPOSITORY({ commit }, payload) {
    try {
      const response = await axios.patch(`${REPOSITORIES_ENDPOINT}/${payload.id}`, payload);
      const { data } = response;
      if (data.success) {
        commit(SET_REPOSITORIES, data.data);
      }
    } catch (error) {
      if (error.response) {
        // Server responded with a status other than 2xx
        throw new Error(error.response.data.message || 'Error in update repository.');
      } else if (error.request) {
        // Request was made but no response was received
        throw new Error('No response received from server. Try again later.');
      } else {
        // Something happened in setting up the request that triggered an Error
        throw new Error('Error setting up the request. Try again later.');
      }
    }
  },
  async DELETE_O365_REPOSITORY(context, payload) {
    try {
      await axios.delete(`${REPOSITORIES_ENDPOINT}/${payload.id}`, { data: { id: payload.id, repository: payload.repository, companyId: payload.companyId } });
    } catch (error) {
      if (error.response) {
        // Server responded with a status other than 2xx
        throw new Error(error.response.data.message || 'Error in remove repository.');
      } else if (error.request) {
        // Request was made but no response was received
        throw new Error('No response received from server. Try again later.');
      } else {
        // Something happened in setting up the request that triggered an Error
        throw new Error('Error setting up the request. Try again later.');
      }
    }
  },
  CLEAR_REPOSITORIES({commit}) {
    commit(SET_REPOSITORIES, []);
  },
  async GET_ALL_ORGANIZATIONS_O365_REPOSITORIES({ commit }, { organizationsIds }) {
    const response = await axios.post(ALL_ORGANIZATIONS_REPOSITORIES_ENDPOINT, {
      organizationsIds
    });
    const { data } = response;
    if (data.success) {
      commit(SET_ALL_REPOSITORIES, data.data);
    }
  },
  CLEAR_ALL_REPOSITORIES({commit}) {
    commit(SET_ALL_REPOSITORIES, []);
  }
};

const getters = {
  getRepositories: state => state.repositories,
  getAllRepositories: state => state.allRepositories
};

const mutations = {
  [SET_REPOSITORIES](state, data) {
    state.repositories = data;
  },
  [SET_ALL_REPOSITORIES](state, data) {
    state.allRepositories = data;
  }
};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};
