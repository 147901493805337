<template>
  <base-dropdown
    ref="dropdown"
    titleTag="span"
    :title-classes="{
      'btn btn-primary': true,
      small,
      'btn-simple border-0': !withBackgroundAndBorder
    }"
    :title="selectedValueTitle"
    :menuOnRight="menuOnRight"
    :direction="direction"
    :preventCloseOptions="['datepickerInput']"
    caret="tim-icons icon-minimal-down"
  >
    <span
      v-if="!hiddenOptions.includes(todayTitle)"
      class="dropdown-item"
      @click="setValueToday()"
    >
      {{ todayTitle }}
    </span>
    <span
      v-if="!hiddenOptions.includes(yesterdayTitle)"
      class="dropdown-item"
      @click="setValueYesterday(yesterdayTitle)"
    >
      {{ yesterdayTitle }}
    </span>
    <span
      v-if="!hiddenOptions.includes(last2DaysTitle)"
      class="dropdown-item"
      @click="setValueLastXDays(last2DaysTitle, 2)"
    >
      {{ last2DaysTitle }}
    </span>
    <span
      v-if="!hiddenOptions.includes(last3DaysTitle)"
      class="dropdown-item"
      @click="setValueLastXDays(last3DaysTitle, 3)"
    >
      {{ last3DaysTitle }}
    </span>
    <span
      v-if="!hiddenOptions.includes(last4DaysTitle)"
      class="dropdown-item"
      @click="setValueLastXDays(last4DaysTitle, 4)"
    >
      {{ last4DaysTitle }}
    </span>
    <span
      v-if="!hiddenOptions.includes(last5DaysTitle)"
      class="dropdown-item"
      @click="setValueLastXDays(last5DaysTitle, 5)"
    >
      {{ last5DaysTitle }}
    </span>
    <span
      v-if="!hiddenOptions.includes(last7DaysTitle)"
      class="dropdown-item"
      @click="setValueLastXDays(last7DaysTitle, 7)"
    >
      {{ last7DaysTitle }}
    </span>
    <span
      v-if="!hiddenOptions.includes(last14DaysTitle)"
      class="dropdown-item"
      @click="setValueLastXDays(last14DaysTitle, 14)"
    >
      {{ last14DaysTitle }}
    </span>
    <span
      v-if="!hiddenOptions.includes(last30DaysTitle)"
      class="dropdown-item"
      @click="setValueLastXDays(last30DaysTitle, 30)"
    >
      {{ last30DaysTitle }}
    </span>
    <span
      v-if="!hiddenOptions.includes(thisMonthTitle)"
      class="dropdown-item"
      @click="setValueThisMonth()"
    >
      {{ thisMonthTitle }}
    </span>
    <span
      v-if="!hiddenOptions.includes(lastMonthTitle)"
      class="dropdown-item"
      @click="setValueLastMonth()"
    >
      {{ lastMonthTitle }}
    </span>
    <span
      v-if="!hiddenOptions.includes(last90DaysTitle)"
      class="dropdown-item"
      @click="setValueLastXDays(last90DaysTitle, 90)"
    >
      {{ last90DaysTitle }}
    </span>
    <span
      v-if="!hiddenOptions.includes(thisQuarterTitle)"
      class="dropdown-item"
      @click="setValueThisQuarter()"
    >
      {{ thisQuarterTitle }}
    </span>
    <span
      v-if="!hiddenOptions.includes(lastQuarterTitle)"
      class="dropdown-item"
      @click="setValueLastQuarter()"
    >
      {{ lastQuarterTitle }}
    </span>
    <span
      v-if="!hiddenOptions.includes(thisYearTitle)"
      class="dropdown-item"
      @click="setValueThisYear()"
    >
      {{ thisYearTitle }}
    </span>
    <span
      v-if="!hiddenOptions.includes(lastYearTitle)"
      class="dropdown-item"
      @click="setValueLastYear()"
    >
      {{ lastYearTitle }}
    </span>
    <span
      v-if="!hiddenOptions.includes('range')"
      class="dropdown-item"
      @mouseenter="handleMouseEnterDatePickerOption"
      @mouseleave="handleMouseLeaveDatePickerOption"
    >
      <date-picker
        ref="datepicker"
        lang="en"
        :shortcuts="false"
        range
        confirm
        v-model="range"
        placeholder="Custom Range"
        format="MMM DD YYYY"
        range-separator="-"
        width="190"
        input-class="mx-input datepicker-input"
        inputName="datepickerInput"
        @confirm="confirmCustomRange"
      />
    </span>
    <span
      v-if="!hiddenOptions.includes('customLastXDays')"
      class="dropdown-item position-relative"
    >
      <input
        class="form-control"
        style="height:32px;border-color:rgb(204, 204, 204);font-size:12px;min-width:142px;"
        v-model="customLastXDays"
        placeholder="Number of Days"
        @click="handleClickCustomLastXDaysInput"
        v-mask="'###'"
      />
      <button
        class="btn-confirm-days btn btn-sm"
        @click="handleClickCustomLastXDaysConfirmButton"
      >
        OK
      </button>
    </span>
  </base-dropdown>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import { DB_COMPLETE_DATE_FORMAT } from '@/common/data/constants';
import moment from 'moment';

export default {
  components: {
    DatePicker
  },
  props: {
    small: {
      type: Boolean,
      default: true
    },
    direction: {
      type: String,
      default: 'down'
    },
    defaultValue: String,
    value: String,
    menuOnRight: { type: Boolean, default: false },
    withBackgroundAndBorder: { type: Boolean, default: false },
    hiddenOptions: { type: Array, default: () => [] }
  },
  data() {
    return {
      datePickerMouseEnterTimeout: null,
      selectedValueTitle: 'Select Date',
      todayTitle: 'Today',
      yesterdayTitle: 'Yesterday',
      last2DaysTitle: 'Last 2 Days',
      last3DaysTitle: 'Last 3 Days',
      last4DaysTitle: 'Last 4 Days',
      last5DaysTitle: 'Last 5 Days',
      last7DaysTitle: 'Last 7 Days',
      last14DaysTitle: 'Last 14 Days',
      last30DaysTitle: 'Last 30 Days',
      last90DaysTitle: 'Last 90 Days',
      thisMonthTitle: `This Month (${moment().format('MMM')})`,
      lastMonthTitle: `Last Month (${moment()
        .subtract(1, 'month')
        .format('MMM')})`,
      thisQuarterTitle: 'This Quarter',
      lastQuarterTitle: 'Last Quarter',
      thisYearTitle: `This Year (${moment().format('YYYY')})`,
      lastYearTitle: `Last Year (${moment()
        .subtract(1, 'year')
        .format('YYYY')})`,
      range: null,
      customLastXDays: null
    };
  },
  mounted() {
    if (this.defaultValue) {
      this.handleDefaultValue();
    }
  },
  watch: {
    value(newValue) {
      if (newValue) {
        switch (true) {
          case newValue === this.todayTitle:
            this.setValueToday();
            break;
          case newValue === this.yesterdayTitle:
            this.setValueYesterday();
            break;
          case newValue === this.last2DaysTitle:
            this.setValueLastXDays(this.last2DaysTitle, 2);
            break;
          case newValue === this.last3DaysTitle:
            this.setValueLastXDays(this.last3DaysTitle, 3);
            break;
          case newValue === this.last4DaysTitle:
            this.setValueLastXDays(this.last4DaysTitle, 4);
            break;
          case newValue === this.last5DaysTitle:
            this.setValueLastXDays(this.last5DaysTitle, 5);
            break;
          case newValue === this.last7DaysTitle:
            this.setValueLastXDays(this.last7DaysTitle, 7);
            break;
          case newValue === this.last14DaysTitle:
            this.setValueLastXDays(this.last14DaysTitle, 14);
            break;
          case newValue === this.last30DaysTitle:
            this.setValueLastXDays(this.last30DaysTitle, 30);
            break;
          case newValue === this.last90DaysTitle:
            this.setValueLastXDays(this.last90DaysTitle, 90);
            break;
          case newValue.includes('This Month'):
            this.setValueThisMonth();
            break;
          case newValue.includes('Last Month'):
            this.setValueLastMonth();
            break;
          case newValue === this.thisQuarterTitle:
            this.setValueThisQuarter();
            break;
          case newValue === this.lastQuarterTitle:
            this.setValueLastQuarter();
            break;
          case newValue.includes('This Year'):
            this.setValueThisYear();
            break;
          case newValue.includes('Last Year'):
            this.setValueLastYear();
            break;
          default: {
            const stringValueSplitted = newValue.split(' / ');
            const dateValues = [
              moment(stringValueSplitted[0], DB_COMPLETE_DATE_FORMAT),
              moment(stringValueSplitted[1], DB_COMPLETE_DATE_FORMAT)
            ];
            this.confirmCustomRange(dateValues);
          }
        }
      }
    }
  },
  methods: {
    handleClickCustomLastXDaysInput() {
      this.$refs.dropdown.toggleDropDown(true);
    },
    handleClickCustomLastXDaysConfirmButton() {
      this.setValueLastXDays(`Last ${this.customLastXDays} Days`, this.customLastXDays);
    },
    setTitleAndRangeAndEmitOnSetValue(title, rangeObject) {
      if (!title.includes('Last')) this.customLastXDays = null;
      this.selectedValueTitle = title;
      this.range = [rangeObject.from, rangeObject.to];
      this.emitOnSetValue(rangeObject);
    },
    setValueToday() {
      this.setTitleAndRangeAndEmitOnSetValue(this.todayTitle, this.getValueToday());
    },
    setValueYesterday() {
      this.setTitleAndRangeAndEmitOnSetValue(
        this.yesterdayTitle,
        this.getValueYesterday()
      );
    },
    setValueLastXDays(selectedTitle, xDays) {
      this.customLastXDays = xDays;
      this.setTitleAndRangeAndEmitOnSetValue(
        selectedTitle,
        this.getDateRangeLastXDays(xDays)
      );
    },
    setValueThisMonth() {
      this.setTitleAndRangeAndEmitOnSetValue(
        this.thisMonthTitle,
        this.getValueThisMonth()
      );
    },
    setValueLastMonth() {
      this.setTitleAndRangeAndEmitOnSetValue(
        this.lastMonthTitle,
        this.getValueLastMonth()
      );
    },
    setValueThisQuarter() {
      this.setTitleAndRangeAndEmitOnSetValue(
        this.thisQuarterTitle,
        this.getValueThisQuarter()
      );
    },
    setValueLastQuarter() {
      this.setTitleAndRangeAndEmitOnSetValue(
        this.lastQuarterTitle,
        this.getValueLastQuarter()
      );
    },
    setValueThisYear() {
      this.setTitleAndRangeAndEmitOnSetValue(this.thisYearTitle, this.getValueThisYear());
    },
    setValueLastYear() {
      this.setTitleAndRangeAndEmitOnSetValue(this.lastYearTitle, this.getValueLastYear());
    },
    confirmCustomRange(value) {
      if (value[0] === null || value[1] === null) {
        this.$refs.dropdown.closeDropDown();
        return;
      }
      const rangeObject = { from: moment(value[0]), to: moment(value[1]) };
      const title = `${rangeObject.from.format('MMM DD YYYY')} - ${rangeObject.to.format(
        'MMM DD YYYY'
      )}`;
      this.setTitleAndRangeAndEmitOnSetValue(title, rangeObject);
      this.$refs.dropdown.closeDropDown();
    },
    getValueToday() {
      return { from: moment(), to: moment() };
    },
    getValueYesterday() {
      return {
        from: moment().subtract(1, 'day'),
        to: moment().subtract(1, 'day')
      };
    },
    getDateRangeLastXDays(days) {
      return { from: moment().subtract(days, 'day'), to: moment() };
    },
    getValueThisMonth() {
      return { from: moment().startOf('month'), to: moment() };
    },
    getValueLastMonth() {
      return {
        from: moment()
          .subtract(1, 'month')
          .startOf('month'),
        to: moment()
          .subtract(1, 'month')
          .endOf('month')
      };
    },
    getValueThisQuarter() {
      return {
        from: moment().startOf('quarter'),
        to: moment().endOf('quarter')
      };
    },
    getValueLastQuarter() {
      return {
        from: moment()
          .subtract(1, 'quarter')
          .startOf('quarter'),
        to: moment()
          .subtract(1, 'quarter')
          .endOf('quarter')
      };
    },
    getValueThisYear() {
      return { from: moment().startOf('year'), to: moment() };
    },
    getValueLastYear() {
      return {
        from: moment()
          .subtract(1, 'year')
          .startOf('year'),
        to: moment()
          .subtract(1, 'year')
          .endOf('year')
      };
    },
    handleDefaultValue() {
      switch (true) {
        case this.defaultValue === this.todayTitle:
          this.setValueToday();
          break;
        case this.defaultValue === this.yesterdayTitle:
          this.setValueYesterday();
          break;
        case this.defaultValue === this.last2DaysTitle:
          this.setValueLastXDays(this.last2DaysTitle, 2);
          break;
        case this.defaultValue === this.last3DaysTitle:
          this.setValueLastXDays(this.last3DaysTitle, 3);
          break;
        case this.defaultValue === this.last4DaysTitle:
          this.setValueLastXDays(this.last4DaysTitle, 4);
          break;
        case this.defaultValue === this.last5DaysTitle:
          this.setValueLastXDays(this.last5DaysTitle, 5);
          break;
        case this.defaultValue === this.last7DaysTitle:
          this.setValueLastXDays(this.last7DaysTitle, 7);
          break;
        case this.defaultValue === this.last14DaysTitle:
          this.setValueLastXDays(this.last14DaysTitle, 14);
          break;
        case this.defaultValue === this.last30DaysTitle:
          this.setValueLastXDays(this.last30DaysTitle, 30);
          break;
        case this.defaultValue === this.last90DaysTitle:
          this.setValueLastXDays(this.last90DaysTitle, 90);
          break;
        case this.defaultValue.includes('This Month'):
          this.setValueThisMonth();
          break;
        case this.defaultValue.includes('Last Month'):
          this.setValueLastMonth();
          break;
        case this.defaultValue === this.thisQuarterTitle:
          this.setValueThisQuarter();
          break;
        case this.defaultValue === this.lastQuarterTitle:
          this.setValueLastQuarter();
          break;
        case this.defaultValue.includes('This Year'):
          this.setValueThisYear();
          break;
        case this.defaultValue.includes('Last Year'):
          this.setValueLastYear();
          break;
        default: {
          const stringValueSplitted = this.defaultValue.split(' / ');
          const rangeObject = {
            from: moment(stringValueSplitted[0], DB_COMPLETE_DATE_FORMAT),
            to: moment(stringValueSplitted[1], DB_COMPLETE_DATE_FORMAT)
          };
          this.setDefaultValue(rangeObject);
        }
      }
    },
    setDefaultValue({ from, to }) {
      let valueToCompare = this.getValueToday();
      if (from.isSame(valueToCompare.from, 'day') && to.isSame(valueToCompare.to, 'day'))
        this.setValueToday();
      else {
        valueToCompare = this.getValueYesterday();
        if (
          from.isSame(valueToCompare.from, 'day') &&
          to.isSame(valueToCompare.to, 'day')
        )
          this.setValueYesterday();
        else {
          valueToCompare = this.getDateRangeLastXDays(7);
          if (
            from.isSame(valueToCompare.from, 'day') &&
            to.isSame(valueToCompare.to, 'day')
          )
            this.setValueLastXDays(this.last7DaysTitle, 7);
          else {
            valueToCompare = this.getDateRangeLastXDays(14);
            if (
              from.isSame(valueToCompare.from, 'day') &&
              to.isSame(valueToCompare.to, 'day')
            )
              this.setValueLastXDays(this.last14DaysTitle, 14);
            else {
              valueToCompare = this.getDateRangeLastXDays(30);
              if (
                from.isSame(valueToCompare.from, 'day') &&
                to.isSame(valueToCompare.to, 'day')
              )
                this.setValueLastXDays(this.last30DaysTitle, 30);
              else {
                valueToCompare = this.getDateRangeLastXDays(90);
                if (
                  from.isSame(valueToCompare.from, 'day') &&
                  to.isSame(valueToCompare.to, 'day')
                )
                  this.setValueLastXDays(this.last90DaysTitle, 90);
                else {
                  valueToCompare = this.getValueThisMonth();
                  if (
                    from.isSame(valueToCompare.from, 'day') &&
                    to.isSame(valueToCompare.to, 'day')
                  )
                    this.setValueThisMonth();
                  else {
                    valueToCompare = this.getValueThisQuarter();
                    if (
                      from.isSame(valueToCompare.from, 'day') &&
                      to.isSame(valueToCompare.to, 'day')
                    )
                      this.setValueThisQuarter();
                    else {
                      valueToCompare = this.getValueLastQuarter();
                      if (
                        from.isSame(valueToCompare.from, 'day') &&
                        to.isSame(valueToCompare.to, 'day')
                      )
                        this.setValueLastQuarter();
                      else {
                        valueToCompare = this.getValueThisYear();
                        if (
                          from.isSame(valueToCompare.from, 'day') &&
                          to.isSame(valueToCompare.to, 'day')
                        )
                          this.setValueThisYear();
                        else {
                          valueToCompare = this.getValueLastYear();
                          if (
                            from.isSame(valueToCompare.from, 'day') &&
                            to.isSame(valueToCompare.to, 'day')
                          )
                            this.setValueLastYear();
                          else this.confirmCustomRange([from, to]);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    emitOnSetValue({ from, to }) {
      let value = this.selectedValueTitle;
      if (value.includes(' - '))
        value = `${from.format(DB_COMPLETE_DATE_FORMAT)} / ${to.format(
          DB_COMPLETE_DATE_FORMAT
        )}`;
      this.$emit('onSetValue', { from, to, value });
    },
    handleMouseEnterDatePickerOption() {
      this.datePickerMouseEnterTimeout = setTimeout(() => {
        if (this.$refs.datepicker) this.$refs.datepicker.showPopup();
      }, 1000);
    },
    handleMouseLeaveDatePickerOption() {
      clearTimeout(this.datePickerMouseEnterTimeout);
    }
  }
};
</script>

<style lang="scss" scoped>
.btn-confirm-days {
  position: absolute;
  top: 4px;
  right: 16px;
  padding: 3px 8px;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  color: #73879c !important;
  background-color: transparent !important;
  font-size: 12px !important;
  background-image: none !important;
  font-weight: 400 !important;
  &:hover,
  &:active,
  &:focus {
    background-color: transparent !important;
    background-image: none !important;
  }
}
</style>
