var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        class: [
          { "col-md-4": _vm.vertical && !_vm.tabNavWrapperClasses },
          { "col-12": _vm.centered && !_vm.tabNavWrapperClasses },
          _vm.tabNavWrapperClasses,
        ],
      },
      [
        _c(
          "ul",
          {
            staticClass: "nav nav-pills",
            class: [
              `nav-pills-${_vm.type}`,
              { "nav-pills-icons": _vm.square },
              { "flex-column": _vm.vertical },
              { "justify-content-center": _vm.centered },
              _vm.tabNavClasses,
            ],
            attrs: { role: "tablist" },
          },
          _vm._l(_vm.tabs, function (tab) {
            return _c(
              "li",
              {
                key: tab.name,
                staticClass: "nav-item active",
                attrs: {
                  "data-toggle": "tab",
                  role: "tablist",
                  "aria-expanded": "true",
                },
              },
              [
                tab.isLink
                  ? _c(
                      "a",
                      {
                        staticClass: "nav-link",
                        class: [{ active: tab.active }, _vm.linkClasses],
                        attrs: {
                          "data-toggle": "tab",
                          role: "tablist",
                          href: `#${tab.name}`,
                          "aria-expanded": tab.active,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.activateTab(tab)
                          },
                        },
                      },
                      [_c("tab-item-content", { attrs: { tab: tab } })],
                      1
                    )
                  : _c(
                      "a",
                      {
                        staticClass: "nav-link",
                        class: [{ active: tab.active }, _vm.linkClasses],
                        attrs: {
                          "data-toggle": "tab",
                          role: "tablist",
                          href: `#${tab.name}`,
                          "aria-expanded": tab.active,
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.activateTab(tab)
                          },
                        },
                      },
                      [_c("tab-item-content", { attrs: { tab: tab } })],
                      1
                    ),
              ]
            )
          }),
          0
        ),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "tab-content",
        class: [
          { "tab-space": !_vm.vertical },
          { "col-md-8": _vm.vertical && !_vm.tabContentClasses },
          _vm.tabContentClasses,
        ],
      },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }