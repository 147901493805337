var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.dynamicTags, function (tag, index) {
        return _c(
          "el-tag",
          {
            key: tag + index,
            attrs: {
              size: "small",
              type: _vm.tagType,
              closable: true,
              "close-transition": false,
            },
            on: {
              close: function ($event) {
                return _vm.handleClose(tag)
              },
            },
          },
          [_vm._v(" " + _vm._s(tag) + " ")]
        )
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.inputValue,
            expression: "inputValue",
          },
        ],
        ref: "saveTagInput",
        staticClass: "form-control input-new-tag",
        attrs: { type: "text", placeholder: "Add new tag", size: "mini" },
        domProps: { value: _vm.inputValue },
        on: {
          input: [
            function ($event) {
              if ($event.target.composing) return
              _vm.inputValue = $event.target.value
            },
            _vm.onInput,
          ],
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.handleInputConfirm.apply(null, arguments)
          },
          blur: _vm.handleInputConfirm,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }