<template>
  <component
    :is="tag"
    :style="{ cursor: disabled ? 'auto' : undefined }"
    :class="[{ show: isOpen }, `drop${direction}`]"
    v-click-outside="closeDropDown"
    @click="handleClick"
  >
    <slot name="title-container" :is-open="isOpen">
      <component
        class="dropdown-toggle btn-rotate"
        data-toggle="dropdown"
        :is="titleTag"
        :class="titleClasses"
        :disabled="disabled"
        :aria-expanded="isOpen"
      >
        <slot name="title" :is-open="isOpen">
          <i :class="icon"></i> {{ title }}
          <i v-if="caret" :class="caret" />
        </slot>
      </component>
    </slot>
    <ul
      class="dropdown-menu"
      :class="[{ show: isOpen }, { 'dropdown-menu-right': menuOnRight }, menuClasses]"
    >
      <slot></slot>
    </ul>
  </component>
</template>

<script>
export default {
  name: 'BaseDropdown',
  props: {
    preventCloseOptions: {
      type: Array,
      default: () => [],
      description: 'Array of DOM element names that will not close the dropdown on click'
    },
    tag: { type: String, default: 'div', description: 'Dropdown html tag (e.g div, ul etc)' },
    titleTag: { type: String, default: 'button', description: 'Dropdown title (toggle) html tag' },
    title: { type: String, description: 'Dropdown title' },
    direction: {
      type: String,
      default: 'down', // up | down
      description: 'Dropdown menu direction (up|down)'
    },
    icon: { type: String, description: 'Dropdown icon' },
    titleClasses: { type: [String, Object, Array], description: 'Title css classes' },
    menuClasses: { type: [String, Object], description: 'Menu css classes' },
    menuOnRight: { type: Boolean, description: 'Whether menu should appear on the right' },
    caret: String,
    disabled: Boolean
  },
  data() {
    return { isOpen: false };
  },
  methods: {
    handleClick(event) {
      if (this.disabled) return;
      if (!this.preventCloseOptions.includes(event.target.name)) {
        this.isOpen = !this.isOpen;
        this.$emit('change', this.isOpen);
      }
    },
    closeDropDown(event) {
      if (event && this.preventCloseOptions.includes(event.target.name)) {
        return;
      }
      this.isOpen = false;
      this.$emit('change', false);
    },
    toggleDropDown(isOpen) {
      if (isOpen !== null && isOpen !== undefined) this.isOpen = isOpen;
      this.isOpen = !this.isOpen;
      this.$emit('change', this.isOpen);
    }
  }
};
</script>
<style lang="scss" scoped>
.dropdown {
  cursor: pointer;
  user-select: none;
}
</style>
