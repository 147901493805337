import axios from 'axios';
import qs from 'qs';
import { datetimeToUTCString } from '@/common/helpers/date';
import { MONITORING } from '@/common/data/constants';

// Endpoints
const TICKETS_ENDPOINT = `${MONITORING}/backoffice/tickets`;
const SINGLE_TICKET_ENDPOINT = `${MONITORING}/backoffice/tickets/:id`;
const TICKETS_REPORTING_ENDPOINT = `${MONITORING}/reporting/tickets`;
const CONNECTWISE_COMPANIES_ENDPOINT = `${MONITORING}/backoffice/tickets/connectwise/companies`;
const CONNECTWISE_CONTACTS_ENDPOINT = `${MONITORING}/backoffice/tickets/connectwise/contacts`;
const CONNECTWISE_MEMBERS_ENDPOINT = `${MONITORING}/backoffice/tickets/connectwise/members`;
const CONNECTWISE_TICKETS_ENDPOINT = `${MONITORING}/backoffice/tickets/connectwise/tickets`;
const AUTOTASK_ACCOUNTS_ENDPOINT = `${MONITORING}/backoffice/tickets/autotask/accounts`;

const SET_TICKETS = 'tickets/SET_TICKETS';
const SET_CONNECTWISE_COMPANIES = 'tickets/SET_CONNECTWISE_COMPANIES';
const SET_CONNECTWISE_CONTACTS = 'tickets/SET_CONNECTWISE_CONTACTS';
const SET_CONNECTWISE_MEMBERS = 'tickets/SET_CONNECTWISE_MEMBERS';
const SET_CONNECTWISE_TICKETS = 'tickets/SET_CONNECTWISE_TICKETS';
const SET_AUTOTASK_ACCOUNTS = 'tickets/SET_AUTOTASK_ACCOUNTS';
// initial state
const state = {
  tickets: {},
  connectWiseCompanies: {},
  connectWiseContacts: {},
  connectWiseMembers: {},
  connectWiseTickets: {},
  autotaskAccounts: {}
};

// actions
const actions = {
  ADD_TICKET(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(TICKETS_ENDPOINT, data)
        .then(response => resolve(response.data))
        .catch(() => {
          reject('ADD_TICKET_ERROR_MESSAGE');
        });
    });
  },
  EDIT_TICKET(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(SINGLE_TICKET_ENDPOINT.replace(':id', data._id), data)
        .then(response => resolve(response.data))
        .catch(() => {
          reject('Error while editing the ticket. Try again later.');
        });
    });
  },
  DELETE_TICKET(context, ticketId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(SINGLE_TICKET_ENDPOINT.replace(':id', ticketId))
        .then(response => resolve(response.data))
        .catch(() => {
          reject('Error while deleting the ticket. Try again later.');
        });
    });
  },
  GET_TICKET(context, ticketId) {
    return new Promise((resolve, reject) => {
      axios
        .get(SINGLE_TICKET_ENDPOINT.replace(':id', ticketId))
        .then(response => resolve(response.data))
        .catch(() => reject('Error while editing the ticket. Try again later.'));
    });
  },
  GET_TICKETS({ commit }, data) {
    let params = data;
    if (params.from) {
      params.from.startOf('day');
      params.from = '"' + datetimeToUTCString(params.from) + '"';
    }
    if (params.to) {
      params.to.endOf('day');
      params.to = '"' + datetimeToUTCString(params.to) + '"';
    }
    return new Promise((resolve, reject) => {
      axios
        .get(TICKETS_REPORTING_ENDPOINT, {
          params,
          paramsSerializer: params => qs.stringify(params, { allowDots: true, indices: false })
        })
        .then(response => {
          commit(SET_TICKETS, response.data);
          resolve(response.data);
        })
        .catch(error => reject(error, 'GET_TICKETS_ERROR_MESSAGE'));
    });
  },
  CLEAR_TICKETS({ commit }) {
    commit(SET_TICKETS, {});
  },
  ADD_TICKET_TO_LIST({ commit, state }, ticket) {
    let tickets = JSON.parse(JSON.stringify(state.tickets));
    const elementIndex = tickets.data.findIndex(c => c._id === ticket._id);
    const ticketToAdd = {
      ...ticket,
      vacId: ticket.applications.vac.id ? ticket.applications.vac.id : '',
      vcdId: ticket.applications.vcd.id ? ticket.applications.vcd.id : '',
      zertoId: ticket.applications.zerto.id ? ticket.applications.zerto.id : ''
    };
    if (elementIndex === -1) {
      tickets.data.unshift(ticketToAdd);
      tickets.count++;
    } else {
      tickets.data[elementIndex] = ticketToAdd;
    }
    commit(SET_TICKETS, tickets);
  },
  DELETE_TICKET_FROM_LIST({ commit, state }, ticketId) {
    let tickets = JSON.parse(JSON.stringify(state.tickets));
    const elementIndex = tickets.data.findIndex(c => c._id === ticketId);
    if (elementIndex !== -1) {
      tickets.data.splice(elementIndex, 1);
      tickets.count--;
    }
    commit(SET_TICKETS, tickets);
  },
  GET_ALL_TICKETS({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(TICKETS_ENDPOINT)
        .then(response => {
          commit(SET_TICKETS, response.data);
          resolve();
        })
        .catch(error => reject(error, 'GET_ALL_TICKETS'));
    });
  },
  GET_CONNECTWISE_COMPANIES({ commit }, { name, id }) {
    return new Promise((resolve, reject) => {
      axios
        .get(CONNECTWISE_COMPANIES_ENDPOINT, { params: { name, id } })
        .then(response => {
          commit(SET_CONNECTWISE_COMPANIES, response.data);
          resolve();
        })
        .catch(() => reject('GET_CONNECTWISE_COMPANIES'));
    });
  },
  CLEAR_CONNECTWISE_COMPANIES({ commit }) {
    commit(SET_CONNECTWISE_COMPANIES, {});
  },
  GET_CONNECTWISE_CONTACTS({ commit }, { name, id }) {
    return new Promise((resolve, reject) => {
      axios
        .get(CONNECTWISE_CONTACTS_ENDPOINT, { params: { name, id } })
        .then(response => {
          commit(SET_CONNECTWISE_CONTACTS, response.data);
          resolve();
        })
        .catch(() => reject('GET_CONNECTWISE_CONTACTS'));
    });
  },
  CLEAR_CONNECTWISE_CONTACTS({ commit }) {
    commit(SET_CONNECTWISE_CONTACTS, {});
  },
  GET_CONNECTWISE_MEMBERS({ commit }, { name, id }) {
    return new Promise((resolve, reject) => {
      axios
        .get(CONNECTWISE_MEMBERS_ENDPOINT, { params: { name, id } })
        .then(response => {
          commit(SET_CONNECTWISE_MEMBERS, response.data);
          resolve();
        })
        .catch(() => reject('GET_CONNECTWISE_MEMBERS'));
    });
  },
  CLEAR_CONNECTWISE_MEMBERS({ commit }) {
    commit(SET_CONNECTWISE_MEMBERS, {});
  },
  GET_AUTOTASK_ACCOUNTS({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(AUTOTASK_ACCOUNTS_ENDPOINT)
        .then(response => {
          commit(SET_AUTOTASK_ACCOUNTS, response.data);
          resolve();
        })
        .catch(() => reject('GET_AUTOTASK_ACCOUNTS'));
    });
  },
  GET_CONNECTWISE_TICKETS({ commit }, { summary, companyId }) {
    return new Promise((resolve, reject) => {
      axios
        .get(CONNECTWISE_TICKETS_ENDPOINT, { params: { summary, companyId } })
        .then(response => {
          commit(SET_CONNECTWISE_TICKETS, response.data);
          resolve();
        })
        .catch(() => reject('GET_CONNECTWISE_TICKETS'));
    });
  },
  CLEAR_CONNECTWISE_TICKETS({ commit }) {
    commit(SET_CONNECTWISE_TICKETS, {});
  }
};

const getters = {
  getTickets: state => state.tickets,
  getConnectWiseCompanies: state => state.connectWiseCompanies,
  getConnectWiseContacts: state => state.connectWiseContacts,
  getConnectWiseMembers: state => state.connectWiseMembers,
  getAutotaskAccounts: state => state.autotaskAccounts,
  getConnectWiseTickets: state => state.connectWiseTickets
};

const mutations = {
  [SET_TICKETS](state, data) {
    state.tickets = data;
  },
  [SET_CONNECTWISE_COMPANIES](state, data) {
    state.connectWiseCompanies = data;
  },
  [SET_CONNECTWISE_CONTACTS](state, data) {
    state.connectWiseContacts = data;
  },
  [SET_CONNECTWISE_MEMBERS](state, data) {
    state.connectWiseMembers = data;
  },
  [SET_CONNECTWISE_TICKETS](state, data) {
    state.connectWiseTickets = data;
  },
  [SET_AUTOTASK_ACCOUNTS](state, data) {
    state.autotaskAccounts = data;
  }
};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};
