var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card card-plain" },
    [
      _c(
        "div",
        {
          staticClass: "card-header",
          attrs: { role: "tab", id: "headingOne" },
        },
        [
          _c(
            "a",
            {
              attrs: {
                "data-toggle": "collapse",
                "data-parent": "#accordion",
                href: `#${_vm.itemId}`,
                "aria-expanded": _vm.active,
                "aria-controls": `content-${_vm.itemId}`,
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.activate.apply(null, arguments)
                },
              },
            },
            [
              _vm._t("title", function () {
                return [_vm._v(" " + _vm._s(_vm.title) + " ")]
              }),
              _vm.showIcon
                ? _c("i", { staticClass: "tim-icons icon-minimal-down" })
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
      _c(
        "collapse-transition",
        { attrs: { duration: _vm.animationDuration } },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.active,
                  expression: "active",
                },
              ],
              staticClass: "collapsed",
              attrs: {
                id: `content-${_vm.itemId}`,
                role: "tabpanel",
                "aria-labelledby": _vm.title,
              },
            },
            [
              _c(
                "div",
                { staticClass: "card-body pt-0" },
                [_vm._t("default")],
                2
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }