var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.tag,
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.closeDropDown,
          expression: "closeDropDown",
        },
      ],
      tag: "component",
      class: [{ show: _vm.isOpen }, `drop${_vm.direction}`],
      style: { cursor: _vm.disabled ? "auto" : undefined },
      on: { click: _vm.handleClick },
    },
    [
      _vm._t(
        "title-container",
        function () {
          return [
            _c(
              _vm.titleTag,
              {
                tag: "component",
                staticClass: "dropdown-toggle btn-rotate",
                class: _vm.titleClasses,
                attrs: {
                  "data-toggle": "dropdown",
                  disabled: _vm.disabled,
                  "aria-expanded": _vm.isOpen,
                },
              },
              [
                _vm._t(
                  "title",
                  function () {
                    return [
                      _c("i", { class: _vm.icon }),
                      _vm._v(" " + _vm._s(_vm.title) + " "),
                      _vm.caret ? _c("i", { class: _vm.caret }) : _vm._e(),
                    ]
                  },
                  { isOpen: _vm.isOpen }
                ),
              ],
              2
            ),
          ]
        },
        { isOpen: _vm.isOpen }
      ),
      _c(
        "ul",
        {
          staticClass: "dropdown-menu",
          class: [
            { show: _vm.isOpen },
            { "dropdown-menu-right": _vm.menuOnRight },
            _vm.menuClasses,
          ],
        },
        [_vm._t("default")],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }