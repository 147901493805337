<template>
  <BaseDashboardLayout>
    <side-bar
      slot="sidebar"
      :background-color="sidebarBackground"
      :title="sidebarTitle"
      :logo="sidebarLogo"
      :logoHeight="logoHeight"
      :logoWidth="logoWidth"
    >
      <template slot-scope="props" slot="links">
        <sidebar-item
          v-if="UI_ABILITIES.check(loggedInUser.acl, 'monitoring.dashboard')"
          :link="{
            name: 'Dashboard',
            icon: 'tim-icons icon-chart-bar-32',
            path: `/${MONITORING}/dashboard`
          }"
        />
        <sidebar-item
          v-if="UI_ABILITIES.check(loggedInUser.acl, 'monitoring.calendar-report')"
          :link="{
            name: 'Calendar view',
            icon: 'tim-icons icon-calendar-60',
            path: `/${MONITORING}/calendar-report`
          }"
        />
        <sidebar-item
          v-if="UI_ABILITIES.check(loggedInUser.acl, 'monitoring.daily-checks')"
          :link="{
            name: 'Daily checks',
            icon: 'tim-icons icon-sound-wave',
            path: `/${MONITORING}/daily-checks`
          }"
        />
        <sidebar-item
          v-if="UI_ABILITIES.check(loggedInUser.acl, 'monitoring.rules') && brandingGetter.features.rules.enabled
          "
          :link="{
            name: 'Rules',
            icon: 'fas fa-asterisk',
            path: `/${MONITORING}/rules`
          }"
        />
        <sidebar-item
          v-if="UI_ABILITIES.check(loggedInUser.acl, 'monitoring.hints') && brandingGetter.features.hints.enabled"
          :link="{
            name: 'Hints',
            icon: 'fas fa-clipboard',
            path: `/${MONITORING}/hints`
          }"
        />
        <sidebar-item
          v-if="UI_ABILITIES.check(loggedInUser.acl, 'monitoring.users')"
          :link="{
            name: 'Users',
            icon: 'tim-icons icon-single-02',
            path: `/${MONITORING}/users`
          }"
        />
        <sidebar-item
          v-if="UI_ABILITIES.check(loggedInUser.acl, 'common.companies')"
          :link="{
            name: 'Companies',
            icon: 'tim-icons icon-bank',
            path: `/${MONITORING}/companies`
          }"
        />
        <sidebar-item
          v-if="UI_ABILITIES.check(loggedInUser.acl, 'monitoring.reporting') && brandingGetter.features.reporting.enabled"
          :link="{ name: 'Reports', icon: 'tim-icons icon-book-bookmark' }"
        >
          <sidebar-item
            v-if="UI_ABILITIES.check(loggedInUser.acl, 'monitoring.userLogs')"
            :link="{
              name: 'Users Logs',
              path: `/${MONITORING}/users-logs`
            }"
          />
          <sidebar-item
            v-if="UI_ABILITIES.check(loggedInUser.acl, 'monitoring.licensing')"
            :link="{
              name: 'Licensing',
              path: `/${MONITORING}/licensing`
            }"
          />
          <sidebar-item
            v-if="UI_ABILITIES.check(loggedInUser.acl, 'monitoring.report-settings') && userIsEnabledForReporting(loggedInUser.acl, brandingGetter.features)"
            :link="{
              name: 'Scheduled',
              path: `/${MONITORING}/report-settings`
            }"
          />
          <sidebar-item
            v-if="UI_ABILITIES.check(loggedInUser.acl, 'monitoring.trends')"
            :link="{
              name: 'Analytics',
              icon: 'tim-icons icon-book-bookmark'
            }"
            isMenuAndHasParent
          >
            <sidebar-item
              v-if="UI_ABILITIES.check(loggedInUser.acl, 'monitoring.trends')"
              :link="{
                name: 'Trends',
                path: `/${MONITORING}/analytics/trends`
              }"
            />
          </sidebar-item>
        </sidebar-item>
        <sidebar-item
          v-if="brandingGetter.features?.supportSection?.enabled"
          :link="{ name: 'Support', icon: 'tim-icons icon-support-17' }"
        >
          <sidebar-item
            v-if="brandingGetter.features?.supportSection?.links?.request"
            :link="{
              name: 'Request',
              path: `/${MONITORING}/support/request`
            }"
          />
          <sidebar-item
            v-if="brandingGetter.features?.supportSection?.links?.live"
            :link="{
              name: 'Live',
              path: `/${MONITORING}/support/live`
            }"
          />
          <sidebar-item
            v-if="brandingGetter.features?.supportSection?.links?.downloads"
            :link="{
              name: 'Downloads',
              path: `/${MONITORING}/downloads`
            }"
          />
        </sidebar-item>
      </template>
    </side-bar>
  </BaseDashboardLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import { UI_ABILITIES } from '@/common/data/role-ui-abilities';
import { MONITORING, MCAST_SP } from '@/common/data/constants';
import BaseDashboardLayout from '../../../common/pages/Layout/BaseDashboardLayout.vue';
import {userIsEnabledForReporting} from "../ReportSettings/ReportSettings.utils";

export default {
  components: { BaseDashboardLayout },
  data() {
    return {
      UI_ABILITIES,
      sidebarLogo: null,
      logoWidth: undefined,
      logoHeight: undefined,
      sidebarTitle: '',
      MONITORING,
      MCAST_SP
    };
  },
  beforeCreate() {
    this.$store.dispatch('SET_SELECTED_APP', MONITORING);
  },
  mounted() {
    this.sidebarLogo = this.brandingGetter ? this.brandingGetter.sidebarLogoUrl : '';
    this.logoWidth = this.brandingGetter ? this.brandingGetter.sidebarLogoWidth : undefined;
    this.logoHeight = this.brandingGetter ? this.brandingGetter.sidebarLogoHeight : undefined;
    this.sidebarTitle = this.brandingGetter ? this.brandingGetter.sidebarTitle : undefined;
  },
  computed: {
    ...mapGetters(['loggedInUser', 'getThemeSettings', 'brandingGetter']),
    sidebarBackground() {
      return this.getThemeSettings ? this.getThemeSettings.sidebarColor : 'blue';
    }
  },
  methods: {
    userIsEnabledForReporting,
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    }
  },
  watch: {
    brandingGetter(newValue) {
      if (newValue) {
        this.sidebarLogo = this.brandingGetter.sidebarLogoUrl || undefined;
        this.logoWidth = this.brandingGetter.sidebarLogoWidth;
        this.logoHeight = this.brandingGetter.sidebarLogoHeight;
        this.sidebarTitle = this.brandingGetter.sidebarTitle;
      }
    }
  }
};
</script>
