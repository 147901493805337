var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "pagination", class: _vm.paginationClass },
    [
      _vm.showArrows
        ? _c(
            "li",
            {
              staticClass: "page-item prev-page",
              class: {
                disabled:
                  (_vm.unknownTotal && !_vm.hasPrevPage) || _vm.value === 1,
              },
            },
            [
              _c(
                "a",
                {
                  staticClass: "page-link",
                  attrs: { "aria-label": "Previous" },
                  on: { click: _vm.prevPage },
                },
                [
                  _c("i", {
                    staticClass: "tim-icons icon-double-left",
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._l(_vm.range(_vm.minPage, _vm.maxPage), function (item) {
        return !_vm.unknownTotal
          ? _c(
              "li",
              {
                key: item,
                staticClass: "page-item",
                class: { active: _vm.value === item },
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "page-link",
                    on: {
                      click: function ($event) {
                        return _vm.changePage(item)
                      },
                    },
                  },
                  [_vm._v(_vm._s(item))]
                ),
              ]
            )
          : _vm._e()
      }),
      _vm.showArrows
        ? _c(
            "li",
            {
              staticClass: "page-item page-pre next-page",
              class: {
                disabled:
                  (_vm.unknownTotal && !_vm.hasNextPage) ||
                  (!_vm.unknownTotal && _vm.value === _vm.totalPages),
              },
            },
            [
              _c(
                "a",
                {
                  staticClass: "page-link",
                  attrs: { "aria-label": "Next" },
                  on: { click: _vm.nextPage },
                },
                [
                  _c("i", {
                    staticClass: "tim-icons icon-double-right",
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              ),
            ]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }