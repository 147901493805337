import axios from 'axios';
import qs from 'qs';
import moment from 'moment';
import { O365 } from '@/common/data/constants';

const SET_IS_O365_RESTORE_USER_SESSION_ACTIVE = 'RESTORE/SET_IS_O365_RESTORE_USER_SESSION_ACTIVE';
const SET_RESTORE_POINTS = 'RESTORE/SET_RESTORE_POINTS';
const SET_RESTORE_SESSION = 'RESTORE/SET_RESTORE_SESSION';
const SET_RESTORE_ORGANIZATION = 'RESTORE/SET_RESTORE_ORGANIZATION';
const SET_DELETED_ITEMS = 'RESTORE/SET_DELETED_ITEMS';

const BASE_RESTORE_ENDPOINT = `${O365}/restore`;
const DEVICE_CODE_ENDPOINT = `${BASE_RESTORE_ENDPOINT}/devicecode`;
const TOKEN_ENDPOINT = `${BASE_RESTORE_ENDPOINT}/token`;
const RESTORE_POINTS_ENDPOINT = `${BASE_RESTORE_ENDPOINT}/restorepoints`;
const RESTORE_SESSIONS_ENDPOINT = `${BASE_RESTORE_ENDPOINT}/restoresessions`;
const RESTORE_SESSION_ENDPOINT = `${RESTORE_SESSIONS_ENDPOINT}/:restoreSessionId`;
const STOP_RESTORE_SESSION_ENDPOINT = `${RESTORE_SESSION_ENDPOINT}/stop`;

function initialState() {
  return {
    isO365UserRestoreSessionActive: false,
    restorePoints: [],
    restoreSession: {},
    restoreOrganizationSelected: null,
    deletedItems: false
  };
}

const state = initialState();

// actions
const actions = {
  async GET_RESTORE_DEVICE_CODE(context, params) {
    try {
      const response = await axios.post(DEVICE_CODE_ENDPOINT, {
        companyId: params.companyObjId,
        organizationId: params.organizationId
      });
      return response.data;
    } catch (error) {
      throw 'Error getting the device code. Try again later.';
    }
  },
  async GET_RESTORE_TOKEN({ commit }, params) {
    try {
      const response = await axios.post(TOKEN_ENDPOINT, {
        companyId: params.companyObjId,
        organizationId: params.organizationId
      });
      if (response.data.success) commit(SET_IS_O365_RESTORE_USER_SESSION_ACTIVE, true);
    } catch (error) {
      commit(SET_IS_O365_RESTORE_USER_SESSION_ACTIVE, false);
      return error.response?.data?.message;
    }
  },
  async GET_RESTORE_POINTS({ commit }, data) {
    try {
      const params = { companyId: data.companyId, organizationId: data.organizationId };
      if (data.from) params.from = moment(data.from).startOf('day').toISOString();
      if (data.to) params.to = moment(data.to).endOf('day').toISOString();
      const response = await axios.get(RESTORE_POINTS_ENDPOINT, {
        params,
        paramsSerializer: params => qs.stringify(params, { allowDots: true, indices: false })
      });
      if (response.data.success)
        commit(SET_RESTORE_POINTS, response.data?.data?.results || response.data?.data);
    } catch (error) {
      commit(SET_RESTORE_POINTS, []);
    }
  },
  CLEAR_RESTORE_POINTS({ commit }) {
    commit(SET_RESTORE_POINTS, []);
  },
  async CREATE_RESTORE_SESSION({ commit, state }, params) {
    const deletedItems = state.deletedItems;
    try {
      const data = {
        companyId: params.companyId,
        datetime: params.datetime,
        sessionType: params.sessionType,
        organizationId: params.organizationId,
        showDeleted: deletedItems
      };
      const response = await axios.post(RESTORE_SESSIONS_ENDPOINT, data);
      commit(SET_RESTORE_SESSION, response.data?.data);
    } catch (error) {
      throw 'Error creating the restore session. Try again later.';
    }
  },
  async STOP_RESTORE_SESSION({ commit }, params) {
    try {
      const data = {
        companyId: params.companyId,
        organizationId: params.organizationId,
        restoreSessionId: params.restoreSessionId
      };
      const response = await axios.post(
        STOP_RESTORE_SESSION_ENDPOINT.replace(':restoreSessionId', params.restoreSessionId),
        data
      );
      if (response?.data?.success) commit(SET_RESTORE_SESSION, initialState().restoreSession);
    } catch (error) {
      throw 'Error stopping restore session.';
    }
  },
  SELECT_RESTORE_ORGANIZATION({ commit }, params) {
    commit(SET_IS_O365_RESTORE_USER_SESSION_ACTIVE, false);
    commit(SET_RESTORE_ORGANIZATION, params);
  },
  CLEAR_RESTORE_ORGANIZATION({ commit }) {
    commit(SET_RESTORE_ORGANIZATION, null);
  },
  SET_DELETED_ITEMS({ commit }, data) {
    commit(SET_DELETED_ITEMS, { deletedItems: data });
  }
};

const getters = {
  getIsO365UserRestoreSessionActive: state => state.isO365UserRestoreSessionActive,
  getRestorePoints: state => state.restorePoints,
  getRestoreSession: state => state.restoreSession,
  getRestoreOrganization: state => state.restoreOrganizationSelected,
  getDeletedItems: state => state.deletedItems
};

const mutations = {
  [SET_IS_O365_RESTORE_USER_SESSION_ACTIVE](state, data) {
    state.isO365UserRestoreSessionActive = data;
  },
  [SET_RESTORE_POINTS](state, data) {
    state.restorePoints = data;
  },
  [SET_RESTORE_SESSION](state, data) {
    state.restoreSession = data;
  },
  [SET_RESTORE_ORGANIZATION](state, data) {
    state.restoreOrganizationSelected = data;
  },
  [SET_DELETED_ITEMS](state, { deletedItems }) {
    state.deletedItems = deletedItems;
  }
};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};
