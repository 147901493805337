var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "form-group mb-3",
      class: {
        "input-group-focus": _vm.focused,
        "has-label": _vm.label,
      },
    },
    [
      _vm._t("label", function () {
        return [
          _vm.label
            ? _c("label", { staticClass: "input-label" }, [
                _c("span", [_vm._v(_vm._s(_vm.label))]),
                _vm.required
                  ? _c("i", { staticClass: "required-field float-left" })
                  : _vm._e(),
              ])
            : _vm._e(),
        ]
      }),
      _c(
        "div",
        {
          staticClass: "input-wrapper mb-0",
          class: {
            "input-group": _vm.hasIcon,
          },
        },
        [
          _vm._t("addonLeft", function () {
            return [
              _vm.addonLeftIcon
                ? _c("span", { staticClass: "input-group-prepend" }, [
                    _c(
                      "div",
                      {
                        staticClass: "input-group-text",
                        class: {
                          "error-brdr": _vm.error,
                          "success-brdr": !_vm.error && _vm.touched,
                        },
                      },
                      [_c("i", { class: _vm.addonLeftIcon })]
                    ),
                  ])
                : _vm._e(),
            ]
          }),
          _vm._t("default", function () {
            return [
              _c(
                "vue-numeric",
                _vm._g(
                  {
                    directives: [
                      {
                        name: "focus",
                        rawName: "v-focus",
                        value: _vm.focus,
                        expression: "focus",
                      },
                    ],
                    ref: "numericInput",
                    staticClass: "form-control",
                    class: {
                      "error-brdr": _vm.error,
                      "success-brdr":
                        _vm.hasValidation && !_vm.error && _vm.touched,
                    },
                    attrs: {
                      placeholder: "Amount",
                      currency: "$",
                      separator: ",",
                      precision: 2,
                      minus: false,
                      value: _vm.value,
                      type: "text",
                    },
                  },
                  _vm.listeners
                )
              ),
            ]
          }),
          _vm.hasValidation && !_vm.error && _vm.touched
            ? _c("span", { staticClass: "success-icon" }, [
                _c("i", {
                  staticClass: "fa fa-check-circle",
                  attrs: { "aria-hidden": "true" },
                }),
              ])
            : _vm.error
            ? _c("span", { staticClass: "error-icon" }, [
                _c("i", {
                  staticClass: "fa fa-exclamation-triangle",
                  attrs: { "aria-hidden": "true" },
                }),
              ])
            : _vm._e(),
        ],
        2
      ),
      _vm.error || _vm.$slots.error
        ? _vm._t("error", function () {
            return [
              _c("i", { staticClass: "error-msg" }, [
                _vm._v(_vm._s(_vm.error)),
              ]),
            ]
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }