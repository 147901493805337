var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-animate",
      class: _vm.switchClass,
      style: _vm.customStyle,
    },
    [
      _c(
        "div",
        {
          staticClass: "bootstrap-switch-container",
          on: {
            click: function ($event) {
              return _vm.triggerToggle()
            },
          },
        },
        [
          _c(
            "span",
            { staticClass: "bootstrap-switch-handle-on" },
            [
              _vm._t("on", function () {
                return [_vm._v(" " + _vm._s(_vm.onText) + " ")]
              }),
            ],
            2
          ),
          _c("span", { staticClass: "bootstrap-switch-label" }),
          _c(
            "span",
            {
              staticClass:
                "bootstrap-switch-handle-off bootstrap-switch-default",
            },
            [
              _vm._t("off", function () {
                return [_vm._v(" " + _vm._s(_vm.offText) + " ")]
              }),
            ],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }