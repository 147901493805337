import moment from 'moment';
import { DB_COMPLETE_DATE_FORMAT, DB_COMPLETE_DATETIME_FORMAT } from '@/common/data/constants';

export const dateToUTCString = date => {
  return moment(date).utc().format(DB_COMPLETE_DATE_FORMAT);
};

export const datetimeToUTCString = datetime => {
  return moment(datetime).utc().format(DB_COMPLETE_DATETIME_FORMAT);
};

export const momentToString = momentDate => momentDate.format(DB_COMPLETE_DATE_FORMAT);

export const momentToDatetimeString = momentDate => momentDate.format(DB_COMPLETE_DATETIME_FORMAT);

export const formatDatetimeToDisplay = dateString => {
  return moment(dateString).format('MM/DD/YY hh:mm A');
};

export const formatDateToDisplay = dateString => {
  return moment(dateString).format('MM/DD/YY');
};

export const formatTimeToDisplay = dateString => {
  return moment(dateString).format('hh:mm A');
};

export const beautifySeconds = seconds => {
  if (!seconds) return '-';
  let formattedString = '';
  const hours = Math.floor(seconds / 3600);
  let remainingSeconds = seconds % 3600;
  const minutes = Math.floor(remainingSeconds / 60);
  remainingSeconds %= 60;
  if (hours >= 1) formattedString = hours + 'h ';
  if (minutes >= 1) formattedString += minutes + 'm ';
  if (remainingSeconds > 0 && !formattedString.includes('h'))
    formattedString += Math.floor(remainingSeconds) + 's';
  return formattedString;
};

export const beautifySecondsToMinutes = seconds => {
  if (seconds === 0) return seconds;
  if (!seconds) return '-';
  const minutes = Math.floor(seconds / 60);
  return minutes + 'm';
};

export const beautifySecondsToHours = seconds => {
  if (seconds === 0) return seconds;
  if (!seconds) return '-';
  const hours = Math.floor(seconds / 3600);
  return hours + 'h';
};
