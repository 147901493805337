import axios from 'axios';
import { O365 } from '@/common/data/constants';

const SET_VERSION = 'O365/ORGANIZATIONS/SET_VERSION';

const VERSION_ENDPOINT = `${O365}/version`;

function initialState() {
  return { version: {} };
}

const state = initialState();

const actions = {
  async GET_O365_VERSION({ commit }, companyObjId) {
    const response = await axios.get(VERSION_ENDPOINT, { params: { companyObjId } });
    const { data } = response;
    if (data.success) {
      commit(SET_VERSION, data.data);
    }
  },
  CLEAR_O365_VERSION({ commit }) {
    commit(SET_VERSION, {});
  }
};

const getters = { getO365Version: state => state.version };

const mutations = {
  [SET_VERSION](state, data) {
    state.version = data;
  }
};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};
