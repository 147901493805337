<template>
  <card class="card-stats" :show-footer-line="true">
    <template slot="header">
      <div class="d-flex mr-2">
        <h2 v-if="subTitle" class="card-title">{{ subTitle }}</h2>
        <date-range-picker
          class="ml-auto mt-2"
          @onSetValue="handleSetDateRange"
          :defaultValue="defaultDateRangeValue"
          :value="getDashboardDatePickerValue"
          :menuOnRight="dropdownMenuOnRight"
        />
      </div>
      <hr />
    </template>
    <div class="row">
      <div class="col-12 d-flex">
        <div
          v-if="$slots.icon || icon"
          class="info-icon text-center"
          :class="`icon-${type}`"
        >
          <slot name="icon"> <i :class="icon"></i> </slot>
        </div>
        <div v-if="$slots.content || title || subTitle" class="numbers mt-2 ml-auto">
          <slot>
            <h3
              v-if="title"
              class="card-title"
              :class="{ link: !!titleClickPath }"
              @click="handleTitleClick"
            >
              {{ title }}
            </h3>
          </slot>
        </div>
      </div>
    </div>
    <div class="stats" slot="footer" v-if="$slots.footer">
      <slot name="footer"></slot>
    </div>
  </card>
</template>

<script>
import { mapGetters } from 'vuex';
import Card from './Card.vue';
import DateRangePicker from '@/common/components/common/DateRangePicker';
import { dateToUTCString } from '@/common/helpers/date';

export default {
  name: 'stats-card',
  components: {
    Card,
    DateRangePicker
  },
  props: {
    type: {
      type: String,
      default: 'primary'
    },
    icon: String,
    title: String,
    subTitle: String,
    onSetDateRange: {
      type: Function,
      required: true
    },
    defaultDateRangeValue: String,
    dropdownMenuOnRight: Boolean,
    titleClickPath: String,
    instructionTypes: Array
  },
  computed: {
    ...mapGetters(['getDashboardDatePickerValue'])
  },
  data() {
    return {
      urlQuery: {
        from: null,
        to: null,
        instructionType: this.instructionTypes
          ? this.instructionTypes.join(',')
          : undefined
      }
    };
  },
  methods: {
    handleSetDateRange(dateRange) {
      this.urlQuery.from = `${dateToUTCString(dateRange.from)}`;
      this.urlQuery.to = `${dateToUTCString(dateRange.to)}`;
      this.onSetDateRange(dateRange);
    },
    handleTitleClick() {
      if (this.titleClickPath) {
        const { from, to, instructionType } = this.urlQuery;
        this.$router.push({
          path: this.titleClickPath,
          query: { from, to, instructionType }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.link {
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
