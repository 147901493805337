import axios from 'axios';
import { O365 } from '@/common/data/constants';

const SET_BACKUP_STATUS = 'O365/DASHBOARD/SET_BACKUP_STATUS';
const SET_USERS_STATUS = 'O365/DASHBOARD/SET_USERS_STATUS';
const SET_LICENSE_ACTIVITY = 'O365/DASHBOARD/SET_LICENSE_ACTIVITY';
const SET_LICENSES = 'O365/DASHBOARD/SET_LICENSES';
const SET_HIGHEST_STORAGE_USERS = 'O365/DASHBOARD/SET_HIGHEST_STORAGE_USER';
const SET_RETENTION = 'O365/DASHBOARD/SET_RETENTION';

const DASHBOARD_ENDPOINT = `${O365}/dashboard`;
const BACKUP_STATUS_ENDPOINT = `${DASHBOARD_ENDPOINT}/backup-status`;
const USERS_STATUS_ENDPOINT = `${DASHBOARD_ENDPOINT}/users`;
const LICENSE_ACTIVITY_ENDPOINT = `${DASHBOARD_ENDPOINT}/license-activity`;
const LICENSES_ENDPOINT = `${DASHBOARD_ENDPOINT}/licenses`;
const HIGHEST_STORAGE_USERS_ENDPOINT = `${DASHBOARD_ENDPOINT}/highest-storage-users`;
const RETENTION_ENDPOINT = `${DASHBOARD_ENDPOINT}/retention`;

function initialState() {
  return {
    backupStatus: {},
    usersStatus: {},
    licenseActivity: {},
    licenses: {},
    highestStorageUsers: {},
    retention: {}
  };
}

const state = initialState();

const actions = {
  async GET_DASHBOARD_BACKUP_STATUS({ commit }, params) {
    const response = await axios.get(BACKUP_STATUS_ENDPOINT, { params });
    const { data } = response;
    commit(SET_BACKUP_STATUS, data);
  },
  async GET_DASHBOARD_USERS_STATUS({ commit }, params) {
    const response = await axios.get(USERS_STATUS_ENDPOINT, { params });
    const { data } = response;
    commit(SET_USERS_STATUS, data);
  },
  async GET_DASHBOARD_LICENSE_ACTIVITY({ commit }, params) {
    const response = await axios.get(LICENSE_ACTIVITY_ENDPOINT, { params });
    const { data } = response;
    commit(SET_LICENSE_ACTIVITY, data);
  },
  async GET_DASHBOARD_LICENSES({ commit }, params) {
    const response = await axios.get(LICENSES_ENDPOINT, { params });
    const { data } = response;
    commit(SET_LICENSES, data);
  },
  async GET_DASHBOARD_HIGHEST_STORAGE_USERS({ commit }, params) {
    const response = await axios.get(HIGHEST_STORAGE_USERS_ENDPOINT, { params });
    const { data } = response;
    commit(SET_HIGHEST_STORAGE_USERS, data);
  },
  async GET_DASHBOARD_RETENTION({ commit }, params) {
    const response = await axios.get(RETENTION_ENDPOINT, { params });
    const { data } = response;
    commit(SET_RETENTION, data);
  },
  CLEAR_DASHBOARD_BACKUP_STATUS({ commit }) {
    commit(SET_BACKUP_STATUS, {});
  }
};

const getters = {
  getBackupStatus: state => state.backupStatus,
  getUsersStatus: state => state.usersStatus,
  getLicenseActivity: state => state.licenseActivity,
  getLicenses: state => state.licenses,
  getHighestStorageUsers: state => state.highestStorageUsers,
  getRetention: state => state.retention
};

const mutations = {
  [SET_BACKUP_STATUS](state, data) {
    state.backupStatus = data;
  },
  [SET_USERS_STATUS](state, data) {
    state.usersStatus = data;
  },
  [SET_LICENSE_ACTIVITY](state, data) {
    state.licenseActivity = data;
  },
  [SET_LICENSES](state, data) {
    state.licenses = data;
  },
  [SET_HIGHEST_STORAGE_USERS](state, data) {
    state.highestStorageUsers = data;
  },
  [SET_RETENTION](state, data) {
    state.retention = data;
  }
};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};
