var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "card",
    { staticClass: "card-stats", attrs: { "show-footer-line": true } },
    [
      _c("template", { slot: "header" }, [
        _c(
          "div",
          { staticClass: "d-flex mr-2" },
          [
            _vm.subTitle
              ? _c("h2", { staticClass: "card-title" }, [
                  _vm._v(_vm._s(_vm.subTitle)),
                ])
              : _vm._e(),
            _c("date-range-picker", {
              staticClass: "ml-auto mt-2",
              attrs: {
                defaultValue: _vm.defaultDateRangeValue,
                value: _vm.getDashboardDatePickerValue,
                menuOnRight: _vm.dropdownMenuOnRight,
              },
              on: { onSetValue: _vm.handleSetDateRange },
            }),
          ],
          1
        ),
        _c("hr"),
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 d-flex" }, [
          _vm.$slots.icon || _vm.icon
            ? _c(
                "div",
                {
                  staticClass: "info-icon text-center",
                  class: `icon-${_vm.type}`,
                },
                [
                  _vm._t("icon", function () {
                    return [_c("i", { class: _vm.icon })]
                  }),
                ],
                2
              )
            : _vm._e(),
          _vm.$slots.content || _vm.title || _vm.subTitle
            ? _c(
                "div",
                { staticClass: "numbers mt-2 ml-auto" },
                [
                  _vm._t("default", function () {
                    return [
                      _vm.title
                        ? _c(
                            "h3",
                            {
                              staticClass: "card-title",
                              class: { link: !!_vm.titleClickPath },
                              on: { click: _vm.handleTitleClick },
                            },
                            [_vm._v(" " + _vm._s(_vm.title) + " ")]
                          )
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              )
            : _vm._e(),
        ]),
      ]),
      _vm.$slots.footer
        ? _c(
            "div",
            { staticClass: "stats", attrs: { slot: "footer" }, slot: "footer" },
            [_vm._t("footer")],
            2
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }