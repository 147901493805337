<template>
    <div class="card card-wizard active m-0">
        <div class="card-header text-center p-0">
            <div class="wizard-navigation">
                <div class="progress-with-circle">
                    <div class="progress-bar real-bar" role="progressbar" aria-valuenow="1" aria-valuemin="1" aria-valuemax="3"
                        :style="{ width: `${progress}%` }"></div>
                    <div class="progress-bar dummy-bar" role="progressbar" aria-valuenow="1" aria-valuemin="1" aria-valuemax="3"
                        :style="{ width: `${100}%`, background: '#1d253b33' }"></div>
                </div>
                <ul class="nav nav-pills" v-if="tabs && tabs.length">
                    <li v-for="(tab, index) in tabs" :key="tab.title" role="tab" :tabindex="tab.checked ? 0 : ''"
                        :id="`step-${index}`" :aria-controls="index" :aria-disabled="!tab.active"
                        :aria-selected="index === activeTabIndex" :ref="`tab-${index}`" class="nav-item wizard-tab-link" :style="linkWidth">
                        <div>
                            <a class="nav-link" :class="[
                                { active: index <= activeTabIndex },
                            ]" data-toggle="tab">
                                <tab-item-content :tab="tab"></tab-item-content>
                            </a>
                        </div>
                        <div class="tab-label" :style="tab.style.label">
                            <span>{{ tab.title }}</span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import { throttle } from './throttle';

export default {
  name: 'wizard-nav-only',
  props: {
    tabs: {
        type: Array,
        default: []
    },
    currentStep: {
        type: Number,
        default: 0
    }
  },
  components: {
    TabItemContent: {
      props: ['tab'],
      render(h) {
        if (this.tab) {
            return h('span', [this.tab.label]);
        }
      }
    }
  },
  data() {
    return {
      activeTabIndex: 0,
      tabLinkWidth: 0,
      tabLinkHeight: 50
    };
  },
  computed: {
    tabCount() {
      return this.tabs.length;
    },
    linkWidth() {
      let width = 100;
      if (this.tabCount > 0) {
        width = 100 / this.tabCount;
      }
      if (this.vertical) {
        width = 100;
      }
      return { width: `${width}%` };
    },
    activeTab() {
      return this.tabs[this.activeTabIndex];
    },
    stepPercentage() {
      return (1 / (this.tabCount * 2)) * 100;
    },
    progress() {
      return this.activeTab.percentage;
    }
  },
  methods: {
    onResize() {
      let tabLinks = document.getElementsByClassName('wizard-tab-link');
      if (tabLinks.length > 0 && this.tabCount > 0) {
        let { clientWidth, clientHeight } = tabLinks[0];
        this.tabLinkWidth = clientWidth;
        this.tabLinkHeight = clientHeight;
      }
    }
  },
  mounted() {
    this.activeTabIndex = this.currentStep;
    this.$nextTick(() => {
      this.tabs[this.activeTabIndex].active = true;
      this.tabs[this.activeTabIndex].checked = true;
      this.onResize();
    });
    window.addEventListener(
      'resize',
      () => {
        throttle(this.onResize, 40);
      },
      false
    );
  },
  watch: {
    currentStep(val) {
      this.activeTabIndex = val;
    },
  }
};
</script>
<style scoped>
    .card-wizard {
        min-height: unset;
    }
    .card-wizard .wizard-navigation {
        position: relative;
        margin: 1.5em auto;
        padding-left: 1px;
    }
    .card-wizard .nav-pills .nav-item .nav-link {
        /* height: 20px;
        width: 20px;
        min-width: unset;
        padding: 14px; */
        padding: 11px 11px !important;
        height: 25px !important;
        width: 25px !important;
        min-width: 25px !important;
        left: 23px;
        z-index: 3;
        top: -10px;
    }
    .white-content .nav-pills .nav-item .nav-link:not(.active) {
        background: #d9d9d9;
    }
    .real-bar {
        z-index: 2;
        position: absolute;
        top: 0;
    }
    .dummy-bar {
        z-index: 1;
        background: #1d253b33;
        position: absolute;
        top: 0;
    }
    .tab-label {
      position: absolute;
      z-index: 5;
      top: 2em;
    }
    .card-header.text-center.p-0 {
      height: 7em;
    }
    .nav-pills.nav-pills:not(.flex-column) .nav-item:last-child .nav-link {
        left: unset !important;
        right: -12px;
    }
    .nav-pills.nav-pills:not(.flex-column) .nav-item:not(:last-child) .nav-link {
      left: 48px;
    }
    .nav-pills.nav-pills:not(.flex-column) .nav-item:nth-child(2) .nav-link {
      left: 33%;
    }
    .nav-pills.nav-pills:not(.flex-column) .nav-item:nth-child(3) .nav-link {
      left: 60%;
    }
    .nav-pills.nav-pills:not(.flex-column) .nav-item:first-child .nav-link {
      left: 12px !important;
    }
    .card-wizard .wizard-navigation .progress-with-circle .progress-bar {
      height: 20% !important;
    }
</style>