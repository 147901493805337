import { O365, MONITORING } from '@/common/data/constants';

export const Helpers = {
  methods: {
    $notifySuccess(text, timeout = 3500) {
      this.$notification('success', text, timeout);
    },
    $notifyError(text, timeout = 5000) {
      this.$notification('danger', text, timeout);
    },
    $notifyInfo(text, timeout = 5000) {
      this.$notification('info', text, timeout);
    },
    $notification(type, text, timeout = 3500) {
      this.$notify({
        message: text,
        timeout,
        // icon: 'tim-icons icon-bell-55',
        horizontalAlign: 'right',
        verticalAlign: 'top',
        type
      });
    },
    $redirectToLandingPage() {
      if (this.getSelectedApp === MONITORING) this.$router.push({ name: 'Dashboard' });
      else if (this.getSelectedApp === O365) this.$router.push({ name: 'O365Organizations' });
    },
    async $handleCopyClick(stringToCopy) {
      if (navigator.clipboard) await navigator.clipboard.writeText(stringToCopy);
      else {
        const dummyElement = document.createElement('textarea');
        dummyElement.value = stringToCopy;
        dummyElement.style.top = '0';
        dummyElement.style.left = '0';
        dummyElement.style.position = 'fixed';
        dummyElement.style.zIndex = -1;
        document.body.appendChild(dummyElement);
        dummyElement.focus();
        dummyElement.select();
        dummyElement.setSelectionRange(0, 99999);
        document.execCommand('copy');
        document.body.removeChild(dummyElement);
      }
    }
  }
};
