var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "bread-crumb",
    _vm._l(_vm.$route.matched.slice(), function (route, index) {
      return _c(
        "BreadCrumbItem",
        { key: route.name, staticStyle: { display: "inline-block" } },
        [
          index < _vm.$route.matched.length - 1
            ? _c(
                "router-link",
                {
                  staticClass: "breadcrumb-link",
                  attrs: { to: { name: route.name } },
                },
                [_vm._v(" " + _vm._s(route.name) + " ")]
              )
            : _c("span", { staticClass: "breadcrumb-current" }, [
                _vm._v(_vm._s(route.name)),
              ]),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }