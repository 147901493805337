import axios from 'axios';
import qs from 'qs';
import { cloneDeep } from 'lodash';
import { datetimeToUTCString } from '@/common/helpers/date';
import { MONITORING } from '@/common/data/constants';

const JOBS_ENDPOINT = `${MONITORING}/reporting/jobs`;
const ANALYTICS_ENDPOINT = `${JOBS_ENDPOINT}/analytics`;
const ANALYTICS_CHARTS_ENDPOINT = `${ANALYTICS_ENDPOINT}/charts`;
const FAILS_WARNINGS_CHART_ENDPOINT = `${ANALYTICS_CHARTS_ENDPOINT}/failures-warnings`;

const SET_TRENDS = 'analytics/SET_TRENDS';
const SET_FAILS_WARNINGS_CHART = 'analytics/SET_FAILS_WARNINGS_CHART';

// initial state
const state = { trends: {}, failsWarningsChart: {} };

// actions
const actions = {
  GET_FAILURE_WARNINGS_TRENDS({ commit }, data) {
    let params = cloneDeep(data);
    if (params.from && params.to) {
      params.from.startOf('day');
      params.to.endOf('day');
      params.from = '"' + datetimeToUTCString(params.from) + '"';
      params.to = '"' + datetimeToUTCString(params.to) + '"';
    }
    return new Promise((resolve, reject) => {
      axios
        .get(ANALYTICS_ENDPOINT, {
          params,
          paramsSerializer: params => qs.stringify(params, { allowDots: true, indices: false })
        })
        .then(response => {
          commit(SET_TRENDS, response.data);
          resolve();
        })
        .catch(error => reject(error, 'Error while trying to get the trends, try again later.'));
    });
  },
  CLEAR_FAILURE_WARNINGS_TRENDS({ commit }) {
    commit(SET_TRENDS, {});
  },
  GET_FAILURES_WARNINGS_CHART({ commit }, data) {
    let params = cloneDeep(data);
    if (params.from && params.to) {
      params.from.startOf('day');
      params.to.endOf('day');
      params.from = '"' + datetimeToUTCString(params.from) + '"';
      params.to = '"' + datetimeToUTCString(params.to) + '"';
    }
    return new Promise((resolve, reject) => {
      axios
        .get(FAILS_WARNINGS_CHART_ENDPOINT, {
          params,
          paramsSerializer: params => qs.stringify(params, { allowDots: true, indices: false })
        })
        .then(response => {
          commit(SET_FAILS_WARNINGS_CHART, response.data);
          resolve();
        })
        .catch(error => reject(error, 'Error while trying to get the chart, try again later.'));
    });
  },
  CLEAR_FAILURES_WARNINGS_CHART({ commit }) {
    commit(SET_FAILS_WARNINGS_CHART, {});
  }
};

const getters = {
  getFailureWarningTrends: state => state.trends,
  getFailureWarningChart: state => state.failsWarningsChart
};

const mutations = {
  [SET_TRENDS](state, data) {
    state.trends = data;
  },
  [SET_FAILS_WARNINGS_CHART](state, data) {
    state.failsWarningsChart = data;
  }
};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};
