var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card card-wizard active m-0" }, [
    _c("div", { staticClass: "card-header text-center p-0" }, [
      _c("div", { staticClass: "wizard-navigation" }, [
        _c("div", { staticClass: "progress-with-circle" }, [
          _c("div", {
            staticClass: "progress-bar real-bar",
            style: { width: `${_vm.progress}%` },
            attrs: {
              role: "progressbar",
              "aria-valuenow": "1",
              "aria-valuemin": "1",
              "aria-valuemax": "3",
            },
          }),
          _c("div", {
            staticClass: "progress-bar dummy-bar",
            style: { width: `${100}%`, background: "#1d253b33" },
            attrs: {
              role: "progressbar",
              "aria-valuenow": "1",
              "aria-valuemin": "1",
              "aria-valuemax": "3",
            },
          }),
        ]),
        _vm.tabs && _vm.tabs.length
          ? _c(
              "ul",
              { staticClass: "nav nav-pills" },
              _vm._l(_vm.tabs, function (tab, index) {
                return _c(
                  "li",
                  {
                    key: tab.title,
                    ref: `tab-${index}`,
                    refInFor: true,
                    staticClass: "nav-item wizard-tab-link",
                    style: _vm.linkWidth,
                    attrs: {
                      role: "tab",
                      tabindex: tab.checked ? 0 : "",
                      id: `step-${index}`,
                      "aria-controls": index,
                      "aria-disabled": !tab.active,
                      "aria-selected": index === _vm.activeTabIndex,
                    },
                  },
                  [
                    _c("div", [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link",
                          class: [{ active: index <= _vm.activeTabIndex }],
                          attrs: { "data-toggle": "tab" },
                        },
                        [_c("tab-item-content", { attrs: { tab: tab } })],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "tab-label", style: tab.style.label },
                      [_c("span", [_vm._v(_vm._s(tab.title))])]
                    ),
                  ]
                )
              }),
              0
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }