<template>
  <div
    class="form-group mb-3"
    :class="{
      'input-group-focus': focused,
      'has-label': label
    }"
  >
    <slot name="label">
      <label v-if="label">
        <span>{{ label }}</span>
        <i v-if="required" class="required-field float-left" />
      </label>
    </slot>
    <div class="input-wrapper mb-0">
      <DatePicker
        lang="en"
        v-model="date"
        v-on="listeners"
        v-bind="$attrs"
        :input-class="inputClass"
      />
      <span class="success-icon" v-if="!error && touched">
        <i class="fa fa-check-circle" aria-hidden="true"></i>
      </span>
      <span class="error-icon" v-else-if="error">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
      </span>
    </div>
    <slot name="error" v-if="error || $slots.error">
      <i class="error-msg">{{ error }}</i>
    </slot>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';

export default {
  inheritAttrs: false,
  name: 'BaseDatePicker',
  components: { DatePicker },
  props: {
    focus: Boolean,
    required: Boolean,
    label: {
      type: String
    },
    error: {
      type: String,
      default: ''
    },
    value: {
      type: Date
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  data() {
    return {
      focused: false,
      touched: false,
      date: null
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.onInput,
        blur: this.onBlur,
        focus: this.onFocus
      };
    },
    inputClass() {
      let inputClass = 'mx-input ';
      if (this.error) inputClass += 'error-brdr';
      else if (this.touched) inputClass += 'success-brdr';
      return inputClass;
    }
  },
  methods: {
    onInput(evt) {
      if (!this.touched) {
        this.touched = true;
      }
      this.$emit('input', evt);
    },
    onFocus() {
      this.focused = true;
    },
    onBlur() {
      this.focused = false;
    }
  },
  directives: {
    focus: {
      inserted: function(el, { value }, { context }) {
        context.$nextTick(() => {
          if (value) el.focus();
        });
      }
    }
  }
};
</script>

<style scoped>
.error {
  display: block;
  width: 100%;
  font-size: 80%;
  color: #ff1721 !important;
  margin-top: 5px;
}
</style>
