<template>
  <BaseDashboardLayout>
    <side-bar
      slot="sidebar"
      :background-color="sidebarBackground"
      :title="sidebarTitle"
      :logo="sidebarLogo"
      :logoHeight="logoHeight"
      :logoWidth="logoWidth"
    >
      <template slot-scope="props" slot="links">
        <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'tim-icons icon-chart-bar-32',
            path: `/${ADMIN}/dashboard`
          }"
        />
      </template>
    </side-bar>
  </BaseDashboardLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import { UI_ABILITIES } from '@/common/data/role-ui-abilities';
import { ADMIN } from '@/common/data/constants';
import BaseDashboardLayout from '../../../common/pages/Layout/BaseDashboardLayout.vue';

export default {
  components: { BaseDashboardLayout },
  data() {
    return {
      UI_ABILITIES,
      sidebarLogo: null,
      logoWidth: undefined,
      logoHeight: undefined,
      sidebarTitle: '',
      ADMIN
    };
  },
  beforeCreate() {
    this.$store.dispatch('SET_SELECTED_APP', ADMIN);
  },
  mounted() {
    this.sidebarLogo = this.brandingGetter ? this.brandingGetter.sidebarLogoUrl : '';
    this.logoWidth = this.brandingGetter ? this.brandingGetter.sidebarLogoWidth : undefined;
    this.logoHeight = this.brandingGetter ? this.brandingGetter.sidebarLogoHeight : undefined;
    this.sidebarTitle = this.brandingGetter ? this.brandingGetter.sidebarTitle : undefined;
  },
  computed: {
    ...mapGetters(['loggedInUser', 'getThemeSettings', 'brandingGetter']),
    sidebarBackground() {
      return this.getThemeSettings ? this.getThemeSettings.sidebarColor : 'blue';
    }
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    }
  },
  watch: {
    brandingGetter(newValue) {
      if (newValue) {
        this.sidebarLogo = this.brandingGetter.sidebarLogoUrl || undefined;
        this.logoWidth = this.brandingGetter.sidebarLogoWidth;
        this.logoHeight = this.brandingGetter.sidebarLogoHeight;
        this.sidebarTitle = this.brandingGetter.sidebarTitle;
      }
    }
  }
};
</script>
