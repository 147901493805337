import axios from 'axios';
import qs from 'qs';
import FileDownload from 'js-file-download';
import { datetimeToUTCString } from '@/common/helpers/date';
import moment from 'moment';

// Endpoints
const USERS_ENDPOINT = 'users';
const SINGLE_USER_ENDPOINT = 'users/:id';
const USER_ACCOUNTS_ENDPOINT = `${SINGLE_USER_ENDPOINT}/accounts/:accountId`;
const USER_CHANGE_PASSWORD_DB_ADMIN_ENDPOINT = `${SINGLE_USER_ENDPOINT}/changePassword/db`;
const USER_CHANGE_PASSWORD_AD_ADMIN_ENDPOINT = `${SINGLE_USER_ENDPOINT}/changePassword/ad`;
const USERS_REPORTING_ENDPOINT = 'reporting/users';
const USERS_LOGS_REPORTING_ENDPOINT = 'reporting/users/logs';
const EXPORT_ENDPOINT = 'reporting/users/logs/export';

const SET_USERS = 'users/SET_USERS';
const SET_COMPANY_USERS = 'users/SET_COMPANY_USERS';
const SET_USERS_LOGS = 'users/SET_USERS_LOGS';

// initial state
const state = { users: {}, companyUsers: {}, userLogs: {} };

// Set Roles function
let setRoles = function (r) {
  r.roles = '';
  if (r.acl.length) {
    let roles = [];
    r.acl.forEach((c, i) => {
      roles[i] = r.acl[i].role;
    });
    r.roles = roles.join(', ');
  }
  return r;
};

// Set Apps function
const def = { username: '', enabled: false };
const apps = ['vac', 'vcd', 'zerto'];
let setApps = function (r) {
  apps.forEach(value => {
    if (!(value in r.sso)) {
      r.sso[value] = def;
      if (value === 'zerto') {
        delete r.sso[value].username;
        r.sso[value].zorgId = '';
      }
    }
  });
  return r;
};

// actions
const actions = {
  ADD_USER(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(USERS_ENDPOINT, data)
        .then(response => resolve(response.data))
        .catch(e => {
          if (e.response && e.response.data && e.response.data.message) {
            reject(e.response.data.message || 'Error while creating the user. Try again later.');
          } else {
            reject('Error while creating the user. Try again later.');
          }
        });
    });
  },
  EDIT_USER(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(SINGLE_USER_ENDPOINT.replace(':id', data._id), data)
        .then(response => resolve(response.data))
        .catch(e => {
          if (e.response && e.response.data && e.response.data.message) {
            reject(e.response.data.message || 'Error while editing the user. Try again later.');
          } else {
            reject('Error while editing the user. Try again later.');
          }
        });
    });
  },
  DELETE_USER(context, userId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(SINGLE_USER_ENDPOINT.replace(':id', userId))
        .then(response => resolve(response.data))
        .catch(e => {
          if (e.response && e.response.data && e.response.data.message) {
            reject(e.response.data.message || 'Error while deleting the user. Try again later.');
          } else {
            reject('Error while deleting the user. Try again later.');
          }
        });
    });
  },
  GET_USERS({ commit }, data) {
    let params = data;
    if (params.roles) {
      params.acl = { role: params.roles };
      delete params.roles;
    }
    if (params.companies) {
      params.companies = { name: params.companies };
    }
    if (params.sort) {
      if (params.sort.includes('company')) {
        params.sort = params.sort.replace('company', 'company.name');
      }
      if (params.sort.includes('companies')) {
        params.sort = params.sort.replace('companies', 'companies.name');
      }
    }
    return new Promise((resolve, reject) => {
      axios
        .get(USERS_REPORTING_ENDPOINT, {
          params,
          paramsSerializer: params => qs.stringify(params, { allowDots: true })
        })
        .then(response => {
          let data = [];
          response.data.data.forEach((c, i) => {
            data[i] = { ...setRoles(c) };
            data[i] = { ...setApps(c) };
          });

          const usersNotDeleted = data.filter(item => !item.deleted);
          commit(SET_USERS, { count: response.data.count, data: usersNotDeleted });
          resolve();
        })
        .catch(error => reject(error));
    });
  },
  CLEAR_USERS({ commit }) {
    commit(SET_USERS, {});
  },
  ADD_USER_TO_LIST({ commit, state }, user) {
    let users = JSON.parse(JSON.stringify(state.users));
    const elementIndex = users.data.findIndex(c => c._id === user._id);
    const userToAdd = { ...user };
    if (elementIndex === -1) {
      users.data.unshift(userToAdd);
      users.count++;
    } else {
      users.data[elementIndex] = userToAdd;
    }
    commit(SET_USERS, users);
  },
  DELETE_USER_FROM_LIST({ commit, state }, userId) {
    let users = JSON.parse(JSON.stringify(state.users));
    const elementIndex = users.data.findIndex(c => c._id === userId);
    if (elementIndex !== -1) {
      users.data.splice(elementIndex, 1);
      users.count--;
    }
    commit(SET_USERS, users);
  },
  GET_COMPANY_USERS({ commit }, companyId) {
    let params = { accounts: { accountId: companyId } };
    return new Promise((resolve, reject) => {
      axios
        .get(USERS_REPORTING_ENDPOINT, {
          params,
          paramsSerializer: params => qs.stringify(params, { allowDots: true })
        })
        .then(response => {
          let data = [];
          response.data.data.forEach((c, i) => {
            data[i] = { ...setRoles(c) };
            data[i] = { ...setApps(c) };
          });
          commit(SET_COMPANY_USERS, { count: response.data.count, data });
          resolve();
        })
        .catch(() => reject('GET_COMPANY_USERS'));
    });
  },
  CLEAR_COMPANY_USERS({ commit }) {
    commit(SET_COMPANY_USERS, {});
  },
  ADD_ACCOUNT_TO_USER(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          USER_ACCOUNTS_ENDPOINT.replace(':id', data.userId).replace(':accountId', data.companyId)
        )
        .then(response => resolve(response.data))
        .catch(() => {
          reject('ADD_ACCOUNT_TO_USER');
        });
    });
  },
  REMOVE_ACCOUNT_FROM_USER(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          USER_ACCOUNTS_ENDPOINT.replace(':id', data.userId).replace(':accountId', data.companyId)
        )
        .then(response => resolve(response.data))
        .catch(() => {
          reject('REMOVE_ACCOUNT_FROM_USER');
        });
    });
  },
  CHANGE_PASSWORD_DB_ADMIN(context, data) {
    return new Promise((resolve, reject) => {
      let user = Object.assign({}, data);
      delete user.id;
      axios
        .put(USER_CHANGE_PASSWORD_DB_ADMIN_ENDPOINT.replace(':id', data.id), user)
        .then(() => resolve())
        .catch(() => reject('There was an error while changing the password. Try again later'));
    });
  },
  CHANGE_PASSWORD_AD_ADMIN(context, data) {
    return new Promise((resolve, reject) => {
      let user = Object.assign({}, data);
      delete user.id;
      axios
        .put(USER_CHANGE_PASSWORD_AD_ADMIN_ENDPOINT.replace(':id', data.id), user)
        .then(() => resolve())
        .catch(() => reject('There was an error while changing the password. Try again later'));
    });
  },
  GET_USER_LOGS({ commit }, data) {
    let params = data;
    if (params.companies) {
      params.companies = { name: params.companies };
    }
    if (params.sort) {
      if (params.sort.includes('company')) {
        params.sort = params.sort.replace('company', 'company.name');
      }
    }
    if (params.createdAt) {
      params.from = moment(params.createdAt).startOf('day').toISOString();
      params.to = moment(params.createdAt).endOf('day').toISOString();
    } else if (params.from && params.to) {
      params.from = moment(params.from).startOf('day').toISOString();
      params.to = moment(params.to).endOf('day').toISOString();
    }

    return new Promise((resolve, reject) => {
      axios
        .get(USERS_LOGS_REPORTING_ENDPOINT, {
          params,
          paramsSerializer: params => qs.stringify(params, { allowDots: true })
        })
        .then(response => {
          commit(SET_USERS_LOGS, response.data);
          resolve();
        })
        .catch(() => reject('GET_USERS_ERROR_MESSAGE'));
    });
  },
  CLEAR_USER_LOGS({ commit }) {
    commit(SET_USERS_LOGS, {});
  },
  EXPORT_LOGS_REPORT(context, query) {
    let params = query;
    if (params.companies) {
      params.companies = { name: params.companies };
    }
    if (params.sort) {
      if (params.sort.includes('company')) {
        params.sort = params.sort.replace('company', 'company.name');
      }
    }
    if (params.from && params.to) {
      params.from.startOf('day');
      params.to.endOf('day');
      params.from = '"' + datetimeToUTCString(params.from) + '"';
      params.to = '"' + datetimeToUTCString(params.to) + '"';
    }
    return new Promise((resolve, reject) => {
      axios
        .get(EXPORT_ENDPOINT, {
          params,
          paramsSerializer: params => qs.stringify(params, { allowDots: true })
        })
        .then(response => {
          FileDownload(response.data, `UserLogs-${Date.now()}.csv`);
          resolve();
        })
        .catch(error => reject(error, 'Error while trying to export, try again later.'));
    });
  }
};

const getters = {
  getUsers: state => state.users,
  getCompanyUsers: state => state.companyUsers,
  getUserLogs: state => state.userLogs
};

const mutations = {
  [SET_USERS](state, data) {
    state.users = data;
  },
  [SET_COMPANY_USERS](state, data) {
    state.companyUsers = data;
  },
  [SET_USERS_LOGS](state, data) {
    state.userLogs = data;
  }
};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};
