import qs from 'qs';
import axios from 'axios';
import { MONITORING } from '@/common/data/constants';

// Mutation types
const SET_RULES = 'rules/SET_RULES';

const ENDPOINTS = {
  RULES: `${MONITORING}/backoffice/rules`,
  SINGLE_RULE: `${MONITORING}/backoffice/rules/:id`,
  TEST_RULES: `${MONITORING}/backoffice/rules/test`
};

function initialState() {
  return {
    rules: {}
  };
}
// initial state
const state = initialState();

// actions
const actions = {
  ADD_RULE(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(ENDPOINTS.RULES, data)
        .then(response => {
          resolve(response.data);
        })
        .catch(() => reject('Error while trying to create the rule. Try again later'));
    });
  },
  EDIT_RULE(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(ENDPOINTS.RULES, data)
        .then(response => {
          resolve(response.data);
        })
        .catch(() => {
          reject('EDIT_RULE');
        });
    });
  },
  DELETE_RULE(context, ruleId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(ENDPOINTS.SINGLE_RULE.replace(':id', ruleId))
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject('DELETE_RULE');
        });
    });
  },
  GET_RULES({ commit }, data) {
    let params = data;
    return new Promise((resolve, reject) => {
      axios
        .get(ENDPOINTS.RULES, {
          params,
          paramsSerializer: params => qs.stringify(params, { allowDots: true, indices: false })
        })
        .then(response => {
          commit(SET_RULES, {
            count: response.data.count,
            data: response.data.data
          });
          resolve();
        })
        .catch(() => {
          reject('Error while getting the rules. Try again later.');
        });
    });
  },
  ADD_RULE_TO_LIST({ commit, state }, rule) {
    let rules = JSON.parse(JSON.stringify(state.rules));
    const elementIndex = rules.data.findIndex(c => c._id === rule._id);
    const ruleToAdd = { ...rule };
    if (elementIndex === -1) {
      rules.data.unshift(ruleToAdd);
      rules.count++;
    } else {
      rules.data[elementIndex] = ruleToAdd;
    }
    commit(SET_RULES, rules);
  },
  DELETE_RULE_FROM_LIST({ commit, state }, ruleId) {
    let rules = JSON.parse(JSON.stringify(state.rules));
    const elementIndex = rules.data.findIndex(c => c._id === ruleId);
    if (elementIndex !== -1) {
      rules.data.splice(elementIndex, 1);
      rules.count--;
    }
    commit(SET_RULES, rules);
  },
  CLEAR_RULES({ commit }) {
    commit(SET_RULES, {});
  },
  TEST_RULES(context, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(ENDPOINTS.TEST_RULES, params)
        .then(response => {
          resolve(response.data);
        })
        .catch(() => reject('Error while trying to test the rules, try again later.'));
    });
  }
};

// getters
const getters = {
  rules: state => state.rules
};

// mutations
const mutations = {
  [SET_RULES](state, data) {
    state.rules = data;
  }
};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};
