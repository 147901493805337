var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("nav", { staticClass: "navbar", class: _vm.classes }, [
    _c(
      "div",
      { class: _vm.containerClasses },
      [
        _vm._t("brand"),
        _vm._t("toggle-button", function () {
          return [
            _vm.hasMenu
              ? _c(
                  "button",
                  {
                    staticClass: "navbar-toggler collapsed",
                    attrs: {
                      type: "button",
                      "aria-expanded": "false",
                      "aria-label": "Toggle navigation",
                    },
                    on: { click: _vm.toggleMenu },
                  },
                  [
                    _c("span", {
                      staticClass: "navbar-toggler-bar navbar-kebab",
                    }),
                    _c("span", {
                      staticClass: "navbar-toggler-bar navbar-kebab",
                    }),
                    _c("span", {
                      staticClass: "navbar-toggler-bar navbar-kebab",
                    }),
                  ]
                )
              : _vm._e(),
          ]
        }),
        _c(
          "CollapseTransition",
          {
            on: {
              "after-leave": _vm.onTransitionEnd,
              "before-enter": _vm.onTransitionStart,
            },
          },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.show,
                    expression: "show",
                  },
                ],
                staticClass: "collapse navbar-collapse show",
                class: _vm.menuClasses,
              },
              [_vm._t("default")],
              2
            ),
          ]
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }