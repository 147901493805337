import Vue from 'vue';
import VueRouter from 'vue-router';
import monitoringRoutes from './monitoring/routes/routes';
import commonRoutes from './common/routes';
import o365Routes from './o365/routes/routes';
import adminRoutes from './admin/routes/routes';
import store from './store';
import NotFound from './common/pages/Common/NotFoundPage.vue';
import swal from 'sweetalert2';
import { SWEET_ALERT_WARNING_CONFIG } from '@/common/helpers/sweet-alert-config';

Vue.use(VueRouter);

// configure router
const router = new VueRouter({
  mode: 'history',
  routes: [
    ...monitoringRoutes,
    ...commonRoutes,
    ...o365Routes,
    ...adminRoutes,
    { path: '*', component: NotFound }
  ],
  linkActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
});

const allowedRoutes = [
  'ResetPassword',
  'Login',
  'Unlock',
  'SecurityQuestions',
  'ForgotPassword',
  'ForgotPasswordDB',
  'ResetPasswordDB',
  'SMSVerification',
  'MobilePhoneAuth',
  'O365Provisioning',
  'O365EmailVerification'
];

const validatePasswordReset = (to, from, next) => {
  if (to && allowedRoutes.indexOf(to.name) < 0) {
    //check if admin set force-reset-password flag to true
    // to force user to set the password again (or first time by default)
    const forceReset = store.getters.loggedInUser.passwordReset;
    const isLoggedInAs = store.getters.getIsLoggedInAs;
    if ((typeof forceReset === 'undefined' || forceReset) && !isLoggedInAs) {
      return next({ name: 'ResetPassword' });
    }
  }
  next();
};

const validateSecurityQuestions = (to, from, next) => {
  if (to && allowedRoutes.indexOf(to.name) < 0) {
    //check if the user has security questions
    // to force it to add them for the first time
    if (store.getters.brandingGetter.authType === 'ad') {
      let forceSecurityQuestions = store.getters.loggedInUser.forceSecurityQuestions;
      if (typeof forceSecurityQuestions === 'undefined' || forceSecurityQuestions) {
        return next({ name: 'SecurityQuestions' });
      }
    }
  }
  next();
};

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters.isAuthenticated;
  const loggedInUser = store.getters.loggedInUser;
  const { features = {} } = store.getters.brandingGetter;
  if (features.office365 && features.office365.selfRegistration.termsOfUse) {
    if (isAuthenticated && !loggedInUser.ackO365TermsOfUse) {
      swal
        .fire({
          html:
            `To use the product you have to accept the <a href="https://www.vmo365.com/terms-of-use/" target="_blank" rel="noopener noreferrer">terms of use</a>`,
          ...SWEET_ALERT_WARNING_CONFIG
        })
        .then(async result => {
          if (result.value) {
            await store.dispatch('UPDATE_USER', {_id: loggedInUser.id, userId: loggedInUser.id, ackO365TermsOfUse: true});
            await store.commit('SET_USER', { ...loggedInUser, ackO365TermsOfUse: true });
            const user = JSON.parse(localStorage.getItem('userInfo'));
            localStorage.setItem('userInfo', JSON.stringify({...user, ackO365TermsOfUse: true}));
          } else {
            store.dispatch('LOG_OUT').then(() => {
              router.push({name: 'Login'});
            });
          }
        });
    }
  }
  next();
})

router.beforeEach((to, from, next) => {
  if (!document.title) {
    const branding = store.getters.brandingGetter;
    if (branding) {
      document.title = branding.title || 'Portal';
      if (branding.favIconUrl) {
        let favIconLink = document.createElement('link');
        favIconLink.rel = 'icon';
        favIconLink.type = 'image/png';
        favIconLink.sizes = '96x96222';
        favIconLink.href = branding.favIconUrl;
      }
    }
  }
  validatePasswordReset(to, from, next);
});
router.beforeEach((to, from, next) => {
  validateSecurityQuestions(to, from, next);
});

export default router;
