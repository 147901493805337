var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "form-group mb-3",
      class: {
        "input-group-focus": _vm.focused,
        "has-label": _vm.label,
      },
    },
    [
      _vm._t("label", function () {
        return [
          _vm.label
            ? _c("label", [
                _c("span", [_vm._v(_vm._s(_vm.label))]),
                _vm.required
                  ? _c("i", { staticClass: "required-field float-left" })
                  : _vm._e(),
              ])
            : _vm._e(),
        ]
      }),
      _c(
        "div",
        { staticClass: "input-wrapper mb-0" },
        [
          _c(
            "DatePicker",
            _vm._g(
              _vm._b(
                {
                  attrs: { lang: "en", "input-class": _vm.inputClass },
                  model: {
                    value: _vm.date,
                    callback: function ($$v) {
                      _vm.date = $$v
                    },
                    expression: "date",
                  },
                },
                "DatePicker",
                _vm.$attrs,
                false
              ),
              _vm.listeners
            )
          ),
          !_vm.error && _vm.touched
            ? _c("span", { staticClass: "success-icon" }, [
                _c("i", {
                  staticClass: "fa fa-check-circle",
                  attrs: { "aria-hidden": "true" },
                }),
              ])
            : _vm.error
            ? _c("span", { staticClass: "error-icon" }, [
                _c("i", {
                  staticClass: "fa fa-exclamation-triangle",
                  attrs: { "aria-hidden": "true" },
                }),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm.error || _vm.$slots.error
        ? _vm._t("error", function () {
            return [
              _c("i", { staticClass: "error-msg" }, [
                _vm._v(_vm._s(_vm.error)),
              ]),
            ]
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }