var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "progress-container",
      class: {
        [`progress-${_vm.type}`]: _vm.type,
        [`progress-${_vm.size}`]: _vm.size,
      },
    },
    [
      _vm.label
        ? _c("span", { staticClass: "progress-badge" }, [
            _vm._v(_vm._s(_vm.label)),
          ])
        : _vm._e(),
      _c("div", { staticClass: "progress" }, [
        _vm.showValue && _vm.valuePosition === "left"
          ? _c("span", { staticClass: "progress-value" }, [
              _vm._v(_vm._s(_vm.value) + "%"),
            ])
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "progress-bar",
            class: _vm.computedClasses,
            style: `width: ${_vm.value}%;`,
            attrs: {
              role: "progressbar",
              "aria-valuenow": _vm.value,
              "aria-valuemin": "0",
              "aria-valuemax": "100",
            },
          },
          [
            _vm._t("default", function () {
              return [
                _vm.showValue && _vm.valuePosition === "right"
                  ? _c("span", { staticClass: "progress-value" }, [
                      _vm._v(_vm._s(_vm.value) + "%"),
                    ])
                  : _vm._e(),
              ]
            }),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }