import axios from 'axios';
import { O365 } from '@/common/data/constants';

const SET_PROXIES = 'O365/PROXIES/SET_PROXIES';

const PROXIES_ENDPOINT = `${O365}/proxies`;

function initialState() {
  return {
    proxies: []
  };
}

const state = initialState();

// actions
const actions = {
  async GET_PROXIES({ commit }, organizationId) {
    const response = await axios.get(PROXIES_ENDPOINT, { params: { organizationId } });
    const { data } = response;
    if (data.success) {
      commit(SET_PROXIES, data.data);
    }
  }
};

const getters = {
  getProxies: state => state.proxies
};

const mutations = {
  [SET_PROXIES](state, data) {
    state.proxies = data;
  }
};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};
