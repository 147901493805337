var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    { class: { "timeline-inverted": _vm.inverted } },
    [
      _vm._t("badge", function () {
        return [
          _c("div", { staticClass: "timeline-badge", class: _vm.badgeType }, [
            _vm.badgeLabel
              ? _c("span", { staticClass: "badge-label" }, [
                  _vm._v(_vm._s(_vm.badgeLabel)),
                ])
              : _c("i", { class: _vm.badgeIcon }),
          ]),
        ]
      }),
      _c("div", { staticClass: "timeline-panel" }, [
        _c("div", { staticClass: "timeline-heading" }, [_vm._t("header")], 2),
        _vm.$slots.content
          ? _c("div", { staticClass: "timeline-body" }, [_vm._t("content")], 2)
          : _vm._e(),
        _vm.$slots.footer
          ? _c("div", { staticClass: "timeline-footer" }, [_vm._t("footer")], 2)
          : _vm._e(),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }