import store from '../store';
import AuthLayout from 'src/common/pages/Layout/AuthLayout';
const Login = () => import(/* webpackChunkName: "Login" */ 'src/common/pages/User/Login');
const Unlock = () => import(/* webpackChunkName: "Unlock" */ 'src/common/pages/User/Unlock');
const ForgotPassword = () =>
  import(/* webpackChunkName: "ForgotPassword" */ 'src/common/pages/User/ForgotPassword');
const ForgotPasswordDB = () =>
  import(/* webpackChunkName: "ForgotPasswordDB" */ 'src/common/pages/User/ForgotPasswordDB');
const ResetPasswordDB = () =>
  import(/* webpackChunkName: "ResetPasswordDB" */ 'src/common/pages/User/ResetPasswordDB');
const SMSVerification = () =>
  import(/* webpackChunkName: "SMSVerification" */ 'src/common/pages/User/SMSVerification');
const MobilePhoneAuth = () =>
  import(/* webpackChunkName: "MobilePhoneAuth" */ 'src/common/pages/User/MobilePhoneAuth');
const Provisioning = () =>
  import(/* webpackChunkName: "Provisioning" */ 'src/o365/pages/Provisioning/Provisioning');
const EmailVerification = () =>
  import(/* webpackChunkName: "Provisioning" */ 'src/o365/pages/Provisioning/EmailVerification');

const ifNotAuthenticated = (to, from, next) => {
  const regex = /^\/o365\/register(\/.+|\?.+)$/;
  if(regex.test(to.fullPath)) {
    store.dispatch('LOG_OUT').then(() => next());
  } else {
    if (!store.getters.isAuthenticated) return next();
    next('/');
  }
};

const routes = [
  {
    path: '/',
    component: AuthLayout,
    name: 'Authentication',
    children: [
      {
        path: '/login',
        name: 'Login',
        beforeEnter: ifNotAuthenticated,
        component: Login
      },
      {
        path: '/unlock',
        name: 'Unlock',
        beforeEnter: ifNotAuthenticated,
        component: Unlock
      },
      {
        path: '/forgot-password',
        name: 'ForgotPassword',
        beforeEnter: ifNotAuthenticated,
        component: ForgotPassword
      },
      {
        path: '/forgot-password-db',
        name: 'ForgotPasswordDB',
        beforeEnter: ifNotAuthenticated,
        component: ForgotPasswordDB
      },
      {
        path: '/reset-password-db',
        name: 'ResetPasswordDB',
        beforeEnter: ifNotAuthenticated,
        component: ResetPasswordDB
      },
      {
        path: '/sms-verification',
        name: 'SMSVerification',
        props: true,
        beforeEnter: ifNotAuthenticated,
        component: SMSVerification
      },
      {
        path: '/phone',
        name: 'MobilePhoneAuth',
        props: true,
        beforeEnter: ifNotAuthenticated,
        component: MobilePhoneAuth
      },
      {
        path: '/o365/register/:userId?',
        name: 'O365Provisioning',
        beforeEnter: ifNotAuthenticated,
        component: Provisioning
      },
      {
        path: '/o365/email-verification',
        name: 'O365EmailVerification',
        beforeEnter: ifNotAuthenticated,
        component: EmailVerification
      }
    ]
  }
];

export default routes;
