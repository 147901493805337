import axios from 'axios';
import qs from 'qs';
import FileDownload from 'js-file-download';
import { O365 } from '@/common/data/constants';
import moment from 'moment';

const SET_TEAMS = 'RESTORE/SET_TEAMS';
const SET_TEAMS_CHANNELS = 'RESTORE/SET_TEAMS_CHANNELS';
const SET_TEAMS_POSTS = 'RESTORE/SET_TEAMS_POSTS';
const SET_TEAMS_FILES = 'RESTORE/SET_TEAMS_FILES';
const SET_TEAMS_TABS = 'RESTORE/SET_TEAMS_TABS';

const TEAMS_ENDPOINT = `${O365}/restore/restoresessions/:restoreSessionId/teams`;
const SINGLE_TEAM_ENDPOINT = `${TEAMS_ENDPOINT}/:teamId`;
const CHANNELS_ENDPOINT = `${SINGLE_TEAM_ENDPOINT}/channels`;
const SINGLE_CHANNEL_ENDPOINT = `${CHANNELS_ENDPOINT}/:channelId`;
const TEAMS_POSTS_ENDPOINT = `${SINGLE_TEAM_ENDPOINT}/posts`;
const TEAMS_FILES_ENDPOINT = `${SINGLE_TEAM_ENDPOINT}/files`;
const TEAMS_TABS_ENDPOINT = `${SINGLE_CHANNEL_ENDPOINT}/tabs`;
const RESTORE_TEAM_ENDPOINT = `${TEAMS_ENDPOINT}/restore`;
const RESTORE_CHANNEL_ENDPOINT = `${SINGLE_CHANNEL_ENDPOINT}/restore`;
const RESTORE_FILES_ENDPOINT = `${SINGLE_TEAM_ENDPOINT}/files/restore`;
const RESTORE_TABS_ENDPOINT = `${SINGLE_CHANNEL_ENDPOINT}/tabs/restore`;
const DOWNLOAD_TEAMS_FILES_ENDPOINT = `${SINGLE_TEAM_ENDPOINT}/files/download`;

function initialState() {
  return {
    teams: []
  };
}

const state = initialState();

// actions
const actions = {
  async GET_TEAMS({ commit }, { restoreSessionId, params }) {
    try {
      const response = await axios.get(
        TEAMS_ENDPOINT.replace(':restoreSessionId', restoreSessionId),
        {
          params: { ...params, companyId: params.companyId },
          paramsSerializer: params => qs.stringify(params, { allowDots: true, indices: false })
        }
      );
      commit(SET_TEAMS, response.data?.data?.results || response.data?.data);
    } catch (error) {
      throw 'Error getting the teams. Try again later.';
    }
  },
  CLEAR_TEAMS({ commit }) {
    commit(SET_TEAMS, []);
  },
  async GET_TEAMS_CHANNELS(
    { commit },
    { restoreSessionId, teamId, params, parentId, breadcrumbIds }
  ) {
    try {
      const response = await axios.get(
        CHANNELS_ENDPOINT.replace(':restoreSessionId', restoreSessionId).replace(':teamId', teamId),
        {
          params: { ...params, companyId: params.companyId, parentId },
          paramsSerializer: params => qs.stringify(params, { allowDots: true, indices: false })
        }
      );
      commit(SET_TEAMS_CHANNELS, {
        teamId,
        channels: response.data?.data?.results || response.data?.data,
        parentId,
        breadcrumbIds
      });
    } catch (error) {
      console.log(error);
      throw 'Error getting the teams channels. Try again later.';
    }
  },
  async GET_TEAMS_POSTS(
    { commit },
    { restoreSessionId, channelId, teamId, params, parentId, breadcrumbIds }
  ) {
    try {
      const response = await axios.get(
        TEAMS_POSTS_ENDPOINT.replace(':restoreSessionId', restoreSessionId).replace(
          ':teamId',
          teamId
        ),
        {
          params: { ...params, companyId: params.companyId, parentId, channelId },
          paramsSerializer: params => qs.stringify(params, { allowDots: true, indices: false })
        }
      );

      commit(SET_TEAMS_POSTS, {
        teamId,
        channelId,
        posts: response.data?.data?.results || response.data?.data,
        parentId,
        breadcrumbIds
      });
    } catch (error) {
      console.log(error);
      throw 'Error getting the teams posts. Try again later.';
    }
  },
  async GET_TEAMS_FILES(
    { commit },
    { restoreSessionId, channelId, teamId, params, parentId, breadcrumbIds, limit, offset }
  ) {
    try {
      const response = await axios.get(
        TEAMS_FILES_ENDPOINT.replace(':restoreSessionId', restoreSessionId).replace(
          ':teamId',
          teamId
        ),
        {
          params: { ...params, companyId: params.companyId, parentId, channelId, limit, offset },
          paramsSerializer: params => qs.stringify(params, { allowDots: true, indices: false })
        }
      );

      commit(SET_TEAMS_FILES, {
        teamId,
        channelId,
        files: response.data?.data?.results || response.data?.data,
        parentId,
        breadcrumbIds
      });
      return response.data?.data;
    } catch (error) {
      console.log(error);
      throw 'Error getting the teams files. Try again later.';
    }
  },
  async GET_TEAMS_TABS(
    { commit },
    { restoreSessionId, channelId, teamId, params, parentId, breadcrumbIds, limit, offset }
  ) {
    try {
      const response = await axios.get(
        TEAMS_TABS_ENDPOINT.replace(':restoreSessionId', restoreSessionId)
          .replace(':teamId', teamId)
          .replace(':channelId', channelId),
        {
          params: { ...params, companyId: params.companyId, parentId, limit, offset },
          paramsSerializer: params => qs.stringify(params, { allowDots: true, indices: false })
        }
      );

      commit(SET_TEAMS_TABS, {
        teamId,
        channelId,
        tabs: response.data?.data?.results || response.data?.data,
        parentId,
        breadcrumbIds
      });
      return response.data?.data;
    } catch (error) {
      console.log(error);
      throw 'Error getting the teams files. Try again later.';
    }
  },
  CLEAR_TEAMS_CHANNELS({ commit }) {
    commit(SET_TEAMS_CHANNELS, []);
  },
  CLEAR_TEAMS_POSTS({ commit }) {
    commit(SET_TEAMS_POSTS, []);
  },
  CLEAR_TEAMS_FILES({ commit }) {
    commit(SET_TEAMS_FILES, []);
  },
  CLEAR_TEAMS_TABS({ commit }) {
    commit(SET_TEAMS_TABS, []);
  },
  async RESTORE_TEAM(context, params) {
    try {
      const data = {
        companyId: params.companyId,
        organizationId: params.organizationId,
        teamId: params.teamId,
        restoreChangedItems: params.restoreChangedItems,
        restoreMissingItems: params.restoreMissingItems,
        restoreMembers: params.restoreMembers,
        restoreSettings: params.restoreSettings
      };
      await axios.post(
        RESTORE_TEAM_ENDPOINT.replace(':restoreSessionId', params.restoreSessionId),
        data
      );
    } catch (error) {
      throw 'Error restoring the team. Try again later.';
    }
  },
  async RESTORE_CHANNEL(context, params) {
    try {
      const data = {
        companyId: params.companyId,
        organizationId: params.organizationId,
        restoreChangedItems: params.restoreChangedItems,
        restoreMissingItems: params.restoreMissingItems
      };
      await axios.post(
        RESTORE_CHANNEL_ENDPOINT.replace(':restoreSessionId', params.restoreSessionId)
          .replace(':teamId', params.teamId)
          .replace(':channelId', params.channelId),
        data
      );
    } catch (error) {
      throw 'Error restoring the channel. Try again later.';
    }
  },
  async RESTORE_FILES(context, params) {
    try {
      const data = {
        companyId: params.companyId,
        organizationId: params.organizationId,
        channelId: params.channelId,
        files: params.files,
        restoreChangedItems: params.restoreChangedItems,
        restoreMissingItems: params.restoreMissingItems,
        fileVersion: params.fileVersion
      };
      await axios.post(
        RESTORE_FILES_ENDPOINT.replace(':restoreSessionId', params.restoreSessionId).replace(
          ':teamId',
          params.teamId
        ),
        data
      );
    } catch (error) {
      throw 'Error restoring the files. Try again later.';
    }
  },
  async RESTORE_TABS(context, params) {
    try {
      const data = {
        companyId: params.companyId,
        organizationId: params.organizationId,
        tabs: params.tabs,
        restoreChangedTabs: params.restoreChangedItems,
        restoreMissingTabs: params.restoreMissingItems
      };
      await axios.post(
        RESTORE_TABS_ENDPOINT.replace(':restoreSessionId', params.restoreSessionId)
          .replace(':teamId', params.teamId)
          .replace(':channelId', params.channelId),
        data
      );
    } catch (error) {
      throw 'Error restoring the tabs. Try again later.';
    }
  },
  async DOWNLOAD_TEAMS_FILES(context, params) {
    try {
      const data = {
        companyId: params.companyId,
        organizationId: params.organizationId,
        channelId: params.channelId,
        files: params.files
      };

      const response = await axios.post(
        DOWNLOAD_TEAMS_FILES_ENDPOINT.replace(':restoreSessionId', params.restoreSessionId).replace(
          ':teamId',
          params.teamId
        ),
        data,
        { headers: { Accept: 'application/octet-stream' }, responseType: 'blob' }
      );
      const blob = new Blob([response.data], { type: 'application/octet-stream' });
      const date = moment().format('YYYY-MM-DD hh:mm:ss');
      FileDownload(blob, `${date}-teams-files.${params.format}`);
    } catch (error) {
      throw 'Error downloading files. Try again later.';
    }
  }
};

const getters = {
  getTeams: state => state.teams
};

const mutations = {
  [SET_TEAMS](state, data) {
    state.teams = data;
  },
  [SET_TEAMS_CHANNELS](state, { teamId, channels, parentId, breadcrumbIds }) {
    const teamIdx = state.teams.findIndex(teams => teams.id === teamId);
    const teams = state.teams[teamIdx];
    if (teams) {
      let copy = { ...teams };
      //If parentId is Root, it means we fetched the channels of the teams.
      if (parentId === 'Root') {
        copy.children = [
          ...channels.map(channel => {
            return {
              displayName: channel.displayName,
              id: channel.id,
              teamId,
              channelObj: { ...channel },
              posts: [],
              files: [],
              tabs: []
            };
          })
        ];
        //In here we replace the team in the array with the copy that contains the channels.
        state.teams.splice(teamIdx, 1, copy);
      } else {
        //If parentId is not Root, we fetched the channels for a subchannel
        //First we check the easiest case which is a direct child of the team.
        const index = copy.children.findIndex(child => child.id === parentId);
        if (index !== -1) {
          copy.children[index].children = [
            ...channels.map(channel => {
              return {
                displayName: channel.displayName,
                id: channel.id,
                teamId,
                channelObj: { ...channel },
                posts: [],
                files: [],
                tabs: []
              };
            })
          ];
          state.teams.splice(teamIdx, 1, copy);
        } else {
          //If it's not a direct child of the team, we follow the IDs in the breadcrumb
          //which allows us to follow the path to the selected channel for which we fetched
          //the subchannel.
          //We remove the first ID of the breadcrumb because it's the team
          breadcrumbIds.shift();
          //We're searching in the children of each channel the ID in the breadcrumb starting
          //with the team children.
          let children = copy.children;
          for (let id of breadcrumbIds) {
            //we get the channel of each id in the breadcrumb and check if it has children:
            //If it does, it means we have to check inside it's children as well
            //If it doesn't, we reached the selected channel and we map the channel to it's children.
            const channel = children.find(child => child.id === id);
            if (channel?.children) {
              children = channel.children;
            } else {
              channel.children = [
                ...channels.map(channel => {
                  return {
                    displayName: channel.displayName,
                    id: channel.id,
                    teamId,
                    channelObj: { ...channel },
                    posts: [],
                    files: [],
                    tabs: []
                  };
                })
              ];
            }
          }
          //Finally, we replace the onedrive in the state with the one with the subfolders.
          state.teams.splice(teamIdx, 1, copy);
        }
      }
    }
  },
  [SET_TEAMS_POSTS](state, { teamId, channelId, posts, parentId, breadcrumbIds }) {
    console.log('SET_TEAMS_POSTS', state, { teamId, channelId, posts, parentId, breadcrumbIds });
  },
  [SET_TEAMS_FILES](state, { teamId, channelId, files, parentId, breadcrumbIds }) {
    const teamIdx = state.teams.findIndex(teams => teams.id === teamId);
    const teams = state.teams[teamIdx];

    if (teams) {
      let teamCopy = { ...teams };
      // First we need to find the channel
      const channelIdx = teams.children.findIndex(channel => channel.id === channelId);
      const channel = teams.children[channelIdx];
      if (channel) {
        // Then format the files and add them to the channel files
        let channelCopy = { ...channel };
        channelCopy.files = [
          ...files.map(file => {
            return {
              name: file.name,
              id: file.id,
              teamId,
              channelId: file.channelId,
              type: file.fileType,
              sizeBytes: file.sizeBytes,
              version: file.version,
              modified: file.modified,
              modifiedBy: file.modifiedBy,
              links: file._links
            };
          })
        ];
        // Replace the channel in the array with the copy that contains the files
        teamCopy.children.splice(channelIdx, 1, channelCopy);
      }
    }
  },
  [SET_TEAMS_TABS](state, { teamId, channelId, tabs, parentId, breadcrumbIds }) {
    const teamIdx = state.teams.findIndex(teams => teams.id === teamId);
    const teams = state.teams[teamIdx];

    if (teams) {
      let teamCopy = { ...teams };
      // First we need to find the channel
      const channelIdx = teams.children.findIndex(channel => channel.id === channelId);
      const channel = teams.children[channelIdx];

      if (channel) {
        // Then format the tabs and add them to the channel tabs
        let channelCopy = { ...channel };
        channelCopy.tabs = [
          ...tabs.map(tab => {
            return {
              displayName: tab.displayName,
              id: tab.id,
              teamId,
              channelId: tab.channelId,
              type: tab.type
            };
          })
        ];
        // Replace the channel in the array with the copy that contains the tabs
        teamCopy.children.splice(channelIdx, 1, channelCopy);
      }
      //Finally, we replace the team in the state with the one with the tabs inside the channel.
      state.teams.splice(teamIdx, 1, teamCopy);
    }
  }
};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};
