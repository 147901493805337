var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-dropdown",
    {
      ref: "dropdown",
      attrs: {
        titleTag: "span",
        "title-classes": {
          "btn btn-primary": true,
          small: _vm.small,
          "btn-simple border-0": !_vm.withBackgroundAndBorder,
        },
        title: _vm.selectedValueTitle,
        menuOnRight: _vm.menuOnRight,
        direction: _vm.direction,
        preventCloseOptions: ["datepickerInput"],
        caret: "tim-icons icon-minimal-down",
      },
    },
    [
      !_vm.hiddenOptions.includes(_vm.todayTitle)
        ? _c(
            "span",
            {
              staticClass: "dropdown-item",
              on: {
                click: function ($event) {
                  return _vm.setValueToday()
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.todayTitle) + " ")]
          )
        : _vm._e(),
      !_vm.hiddenOptions.includes(_vm.yesterdayTitle)
        ? _c(
            "span",
            {
              staticClass: "dropdown-item",
              on: {
                click: function ($event) {
                  return _vm.setValueYesterday(_vm.yesterdayTitle)
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.yesterdayTitle) + " ")]
          )
        : _vm._e(),
      !_vm.hiddenOptions.includes(_vm.last2DaysTitle)
        ? _c(
            "span",
            {
              staticClass: "dropdown-item",
              on: {
                click: function ($event) {
                  return _vm.setValueLastXDays(_vm.last2DaysTitle, 2)
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.last2DaysTitle) + " ")]
          )
        : _vm._e(),
      !_vm.hiddenOptions.includes(_vm.last3DaysTitle)
        ? _c(
            "span",
            {
              staticClass: "dropdown-item",
              on: {
                click: function ($event) {
                  return _vm.setValueLastXDays(_vm.last3DaysTitle, 3)
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.last3DaysTitle) + " ")]
          )
        : _vm._e(),
      !_vm.hiddenOptions.includes(_vm.last4DaysTitle)
        ? _c(
            "span",
            {
              staticClass: "dropdown-item",
              on: {
                click: function ($event) {
                  return _vm.setValueLastXDays(_vm.last4DaysTitle, 4)
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.last4DaysTitle) + " ")]
          )
        : _vm._e(),
      !_vm.hiddenOptions.includes(_vm.last5DaysTitle)
        ? _c(
            "span",
            {
              staticClass: "dropdown-item",
              on: {
                click: function ($event) {
                  return _vm.setValueLastXDays(_vm.last5DaysTitle, 5)
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.last5DaysTitle) + " ")]
          )
        : _vm._e(),
      !_vm.hiddenOptions.includes(_vm.last7DaysTitle)
        ? _c(
            "span",
            {
              staticClass: "dropdown-item",
              on: {
                click: function ($event) {
                  return _vm.setValueLastXDays(_vm.last7DaysTitle, 7)
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.last7DaysTitle) + " ")]
          )
        : _vm._e(),
      !_vm.hiddenOptions.includes(_vm.last14DaysTitle)
        ? _c(
            "span",
            {
              staticClass: "dropdown-item",
              on: {
                click: function ($event) {
                  return _vm.setValueLastXDays(_vm.last14DaysTitle, 14)
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.last14DaysTitle) + " ")]
          )
        : _vm._e(),
      !_vm.hiddenOptions.includes(_vm.last30DaysTitle)
        ? _c(
            "span",
            {
              staticClass: "dropdown-item",
              on: {
                click: function ($event) {
                  return _vm.setValueLastXDays(_vm.last30DaysTitle, 30)
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.last30DaysTitle) + " ")]
          )
        : _vm._e(),
      !_vm.hiddenOptions.includes(_vm.thisMonthTitle)
        ? _c(
            "span",
            {
              staticClass: "dropdown-item",
              on: {
                click: function ($event) {
                  return _vm.setValueThisMonth()
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.thisMonthTitle) + " ")]
          )
        : _vm._e(),
      !_vm.hiddenOptions.includes(_vm.lastMonthTitle)
        ? _c(
            "span",
            {
              staticClass: "dropdown-item",
              on: {
                click: function ($event) {
                  return _vm.setValueLastMonth()
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.lastMonthTitle) + " ")]
          )
        : _vm._e(),
      !_vm.hiddenOptions.includes(_vm.last90DaysTitle)
        ? _c(
            "span",
            {
              staticClass: "dropdown-item",
              on: {
                click: function ($event) {
                  return _vm.setValueLastXDays(_vm.last90DaysTitle, 90)
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.last90DaysTitle) + " ")]
          )
        : _vm._e(),
      !_vm.hiddenOptions.includes(_vm.thisQuarterTitle)
        ? _c(
            "span",
            {
              staticClass: "dropdown-item",
              on: {
                click: function ($event) {
                  return _vm.setValueThisQuarter()
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.thisQuarterTitle) + " ")]
          )
        : _vm._e(),
      !_vm.hiddenOptions.includes(_vm.lastQuarterTitle)
        ? _c(
            "span",
            {
              staticClass: "dropdown-item",
              on: {
                click: function ($event) {
                  return _vm.setValueLastQuarter()
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.lastQuarterTitle) + " ")]
          )
        : _vm._e(),
      !_vm.hiddenOptions.includes(_vm.thisYearTitle)
        ? _c(
            "span",
            {
              staticClass: "dropdown-item",
              on: {
                click: function ($event) {
                  return _vm.setValueThisYear()
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.thisYearTitle) + " ")]
          )
        : _vm._e(),
      !_vm.hiddenOptions.includes(_vm.lastYearTitle)
        ? _c(
            "span",
            {
              staticClass: "dropdown-item",
              on: {
                click: function ($event) {
                  return _vm.setValueLastYear()
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.lastYearTitle) + " ")]
          )
        : _vm._e(),
      !_vm.hiddenOptions.includes("range")
        ? _c(
            "span",
            {
              staticClass: "dropdown-item",
              on: {
                mouseenter: _vm.handleMouseEnterDatePickerOption,
                mouseleave: _vm.handleMouseLeaveDatePickerOption,
              },
            },
            [
              _c("date-picker", {
                ref: "datepicker",
                attrs: {
                  lang: "en",
                  shortcuts: false,
                  range: "",
                  confirm: "",
                  placeholder: "Custom Range",
                  format: "MMM DD YYYY",
                  "range-separator": "-",
                  width: "190",
                  "input-class": "mx-input datepicker-input",
                  inputName: "datepickerInput",
                },
                on: { confirm: _vm.confirmCustomRange },
                model: {
                  value: _vm.range,
                  callback: function ($$v) {
                    _vm.range = $$v
                  },
                  expression: "range",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      !_vm.hiddenOptions.includes("customLastXDays")
        ? _c("span", { staticClass: "dropdown-item position-relative" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.customLastXDays,
                  expression: "customLastXDays",
                },
                {
                  name: "mask",
                  rawName: "v-mask",
                  value: "###",
                  expression: "'###'",
                },
              ],
              staticClass: "form-control",
              staticStyle: {
                height: "32px",
                "border-color": "rgb(204, 204, 204)",
                "font-size": "12px",
                "min-width": "142px",
              },
              attrs: { placeholder: "Number of Days" },
              domProps: { value: _vm.customLastXDays },
              on: {
                click: _vm.handleClickCustomLastXDaysInput,
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.customLastXDays = $event.target.value
                },
              },
            }),
            _c(
              "button",
              {
                staticClass: "btn-confirm-days btn btn-sm",
                on: { click: _vm.handleClickCustomLastXDaysConfirmButton },
              },
              [_vm._v(" OK ")]
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }