export const SWEET_ALERT_WARNING_CONFIG = {
  icon: 'warning',
  confirmButtonText: 'Yes',
  customClass: {
    confirmButton: 'btn btn-info',
    cancelButton: 'mr-3 btn btn-simple btn-secondary'
  },
  buttonsStyling: false,
  showCancelButton: true,
  reverseButtons: true
};
