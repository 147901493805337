import store from '../../store';
import { UI_ABILITIES } from '@/common/data/role-ui-abilities';
import { ADMIN } from '@/common/data/constants';
// const USER_TOKEN_NAME = 'user-token';
const URL_TO_REDIRECT_AFTER_LOGIN = 'urlToRedirectAfterLogin';

// Layouts
import DashboardLayout from 'src/admin/pages/Layout/DashboardLayout.vue';
// Default
const Forbidden = () =>
  import(/* webpackChunkName: "O365Forbidden" */ 'src/common/pages/Forbidden.vue');

// User
const Profile = () => import(/* webpackChunkName: "Profile" */ 'src/common/pages/User/Profile.vue');
const ChangePassword = () =>
  import(/* webpackChunkName: "ChangePassword" */ 'src/common/pages/User/ChangePassword.vue');
const ForceSecurityQuestions = () =>
  import(/* webpackChunkName: "ForceSecurityQuestions" */ 'src/common/pages/User/ForceSecurityQuestions.vue');

// Dashboard
const Dashboard = () =>
  import(/* webpackChunkName: "Dashboard" */ 'src/admin/pages/Dashboard/Dashboard');
// Users

const isUserAuthenticated = to => {
  if (!store.getters.isAuthenticated) {
    if (to && to.name !== 'Session') {
      localStorage.setItem(URL_TO_REDIRECT_AFTER_LOGIN, JSON.stringify(to));
    }
    return false;
  }
  return true;
};

const validateUserSession = (to, from, next) => {
  if (!isUserAuthenticated(to)) {
    return next('/login');
  }
  next();
};

const validateUserSessionAndRol = (to, from, next, page) => {
  if (!isUserAuthenticated(to)) {
    return next('/login');
  }
  const user = store.getters.loggedInUser;
  if (!UI_ABILITIES.check(user.acl, page)) return next({ name: 'AdminForbidden' });

  next();
};

const routes = [
  {
    path: `/${ADMIN}`,
    component: DashboardLayout,
    redirect: `/${ADMIN}/dashboard`,
    name: 'Root',
    beforeEnter: validateUserSession,
    children: [
      {
        path: `dashboard`,
        name: 'AdminDashboard',
        meta: { showFooter: true },
        components: { default: Dashboard },
        beforeEnter: (to, from, next) =>
          validateUserSessionAndRol(to, from, next, 'admin.dashboard')
      },
      {
        path: `profile`,
        name: 'AdminProfile',
        components: { default: Profile }
      },
      {
        path: `change-password`,
        name: 'AdminChangePassword',
        components: { default: ChangePassword }
      },
      {
        path: `reset-password`,
        name: 'AdminResetPassword',
        components: { default: ChangePassword }
      },
      {
        path: `security-questions`,
        name: 'AdminSecurityQuestions',
        components: { default: ForceSecurityQuestions }
      },
      {
        path: `forbidden`,
        name: 'AdminForbidden',
        components: { default: Forbidden }
      }
    ]
  }
];

export default routes;
