import { USER_ROLES } from '@/common/data/constants';

export const UI_ABILITIES = {
  admin: [
    'monitoring.dashboard',
    'monitoring.users',
    'common.companies',
    'monitoring.reporting',
    'monitoring.report-settings',
    'monitoring.userLogs',
    'monitoring.trends',
    'monitoring.licensing',
    'monitoring.licensing.export',
    'monitoring.hints'
  ],
  'read-only': [
    'monitoring.spreadsheet-report',
    'monitoring.overview',
    'monitoring.tickets',
    'monitoring.repositories',
    'monitoring.companies-filter',
    'monitoring.rankings',
    'monitoring.dashboard',
    'monitoring.daily-checks',
    'monitoring.calendar-report',
    'monitoring.reporting',
    'monitoring.report-settings',
  ],
  operator: [
    'monitoring.spreadsheet-report',
    'monitoring.overview',
    'monitoring.tickets',
    'monitoring.repositories',
    'monitoring.companies-filter',
    'monitoring.rankings',
    'monitoring.dashboard',
    'monitoring.daily-checks',
    'monitoring.rules',
    'monitoring.jobs.actions',
    'monitoring.calendar-report',
    'monitoring.reporting',
    'monitoring.report-settings',
    'monitoring.trends'
  ],
  'super-admin': [
    'monitoring.reporting',
    'monitoring.report-settings',
    'monitoring.licensing',
    'monitoring.userLogs',
    'monitoring.trends',
    'admin.dashboard',
    'o365.subscriptions',
    'monitoring.licensing.export'
  ],
  [USER_ROLES.O365_ADMIN]: [
    'o365.users',
    'o365.jobs',
    'o365.organizations',
    'o365.reporting',
    'o365.licensing',
    'o365.repositories',
    'o365.dashboard',
    'o365.restorePortal',
    'o365.restoreOutlook',
    'o365.restoreOnedrive',
    'o365.restoreSharepoint',
    'o365.restoreTeams',
    'o365.consumption',
    'o365.service-level'
  ],
  check: function (acl, ability) {
    try {
      return acl.some(a => UI_ABILITIES[a.role].includes(ability));
    } catch (error) {
      return false;
    }
  }
};
