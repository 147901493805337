import axios from 'axios';
import qs from 'qs';

// Endpoints
const DELETED_COMPANIES_ENDPOINT = `/deleted-companies/`;

const SET_DELETED_COMPANIES = 'deletedCompanies/SET_DELETED_COMPANIES';

// initial state
const state = {
  deletedCompanies: {}
}

// actions
const actions = {
  GET_DELETED_COMPANIES({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(DELETED_COMPANIES_ENDPOINT)
        .then(response => {
          commit(SET_DELETED_COMPANIES, response.data);
          resolve();
        })
        .catch(error => reject(error, 'GET_DELETED_COMPANIES'));
    });
  }
}

const getters = {
  getDeletedCompanies: state => state.deletedCompanies
};

const mutations = {
  [SET_DELETED_COMPANIES](state, data) {
    state.deletedCompanies = data;
  }
}

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};
