var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sidebar", attrs: { data: _vm.backgroundColor } },
    [
      _c(
        "div",
        { ref: "sidebarScrollArea", staticClass: "sidebar-wrapper" },
        [
          _c("div", { staticClass: "logo" }, [
            _c(
              "div",
              { staticClass: "simple-text logo-normal d-flex w-100 pl-2" },
              [
                _c("div", [_vm._v(" " + _vm._s(_vm.title) + " ")]),
                _c("div", { staticClass: "fix-sidebar-icon" }, [
                  _c("div", { staticClass: "fix-icons" }, [
                    _vm.getThemeSettings && _vm.getThemeSettings.sidebarMini
                      ? _c("i", {
                          staticClass: "tim-icons icon-pin",
                          on: {
                            click: function ($event) {
                              return _vm.updateSidebarMini(false)
                            },
                          },
                        })
                      : _c("i", {
                          staticClass: "tim-icons icon-pin rotate-45",
                          on: {
                            click: function ($event) {
                              return _vm.updateSidebarMini(true)
                            },
                          },
                        }),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "navbar-wrapper",
                      staticStyle: { "margin-top": "3px" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "navbar-toggle d-inline",
                          class: { toggled: _vm.$sidebar.showSidebar },
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "navbar-toggler",
                              attrs: { type: "button" },
                              on: { click: _vm.toggleSidebar },
                            },
                            [
                              _c("span", {
                                staticClass: "navbar-toggler-bar bar1",
                              }),
                              _c("span", {
                                staticClass: "navbar-toggler-bar bar2",
                              }),
                              _c("span", {
                                staticClass: "navbar-toggler-bar bar3",
                              }),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]
            ),
          ]),
          _vm._t("default"),
          _c(
            "ul",
            { staticClass: "nav" },
            [
              _vm._t("links", function () {
                return _vm._l(_vm.sidebarLinks, function (link, index) {
                  return _c(
                    "sidebar-item",
                    { key: link.name + index, attrs: { link: link } },
                    _vm._l(link.children, function (subLink, index) {
                      return _c("sidebar-item", {
                        key: subLink.name + index,
                        attrs: { link: subLink },
                      })
                    }),
                    1
                  )
                })
              }),
              _c(
                "div",
                { staticClass: "sidebar-footer" },
                [
                  _vm.userHasAccessToAnotherApp
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "click-outside",
                              rawName: "v-click-outside",
                              value: _vm.hideAppContainer,
                              expression: "hideAppContainer",
                            },
                          ],
                          attrs: { id: "app-container-div" },
                          on: { click: _vm.displayAppContainer },
                        },
                        [
                          _c("sidebar-item", {
                            attrs: {
                              link: {
                                name: `Apps (${
                                  _vm.getSelectedApp === "monitoring"
                                    ? "Monitoring"
                                    : "M365"
                                })`,
                                icon: "fas fa-th-large",
                              },
                              menu: "",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.getSelectedApp === "monitoring" && _vm.lastSync
                    ? _c(
                        "div",
                        { staticClass: "sync" },
                        [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: `Failed: ${
                                  _vm.lastSync.failed &&
                                  _vm.lastSync.failed
                                    .map((f) => f && f.entity)
                                    .join(", ")
                                }`,
                                effect: "light",
                                "open-delay": 400,
                                placement: "top",
                                disabled: _vm.lastSync.status === "SUCCEEDED",
                              },
                            },
                            [
                              _c("ul", { staticClass: "nav" }, [
                                _c(
                                  "li",
                                  {
                                    class: {
                                      "disabled-sync": _vm.gettingLastSync,
                                    },
                                    on: { click: _vm.getLastSync },
                                  },
                                  [
                                    _vm.gettingLastSync
                                      ? _c("Spinner", {
                                          attrs: { id: "spinner", size: "24" },
                                        })
                                      : _c("i", {
                                          staticClass:
                                            "tim-icons icon-refresh-02",
                                        }),
                                    _c("p", [
                                      _vm._v(_vm._s(_vm.lastSync.status)),
                                    ]),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "sidebar-item",
                    {
                      attrs: {
                        link: {
                          name: "Settings",
                          icon: "tim-icons icon-settings",
                        },
                      },
                    },
                    [
                      _c("sidebar-item", {
                        attrs: {
                          link: {
                            name: "Profile",
                            path: `/${_vm.getSelectedApp}/profile`,
                          },
                        },
                      }),
                      _vm.getSelectedApp === "o365" &&
                      _vm.brandingGetter.isStripeEnabled
                        ? _c("sidebar-item", {
                            attrs: {
                              link: {
                                name: "Subscriptions",
                                path: `/${_vm.getSelectedApp}/subscriptions`,
                              },
                            },
                          })
                        : _vm._e(),
                      _c("sidebar-item", {
                        attrs: {
                          link: {
                            name: "Change Password",
                            path: `/${_vm.getSelectedApp}/change-password`,
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      attrs: { id: "logout-div" },
                      on: { click: _vm.doLogout },
                    },
                    [
                      _c("sidebar-item", {
                        attrs: {
                          link: {
                            name: _vm.logOutLabel,
                            icon: "tim-icons icon-button-power",
                          },
                          menu: true,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        2
      ),
      _vm.displayApps
        ? _c("div", { staticClass: "browse-apps-container" }, [
            _c(
              "button",
              {
                class: { selectedApp: _vm.getSelectedApp === "monitoring" },
                on: {
                  click: function ($event) {
                    return _vm.redirectTo(_vm.MONITORING)
                  },
                },
              },
              [
                _c("i", { staticClass: "fas fa-desktop" }),
                _vm._v(" Monitoring "),
              ]
            ),
            _c(
              "button",
              {
                class: { selectedApp: _vm.getSelectedApp === "o365" },
                on: {
                  click: function ($event) {
                    return _vm.redirectTo(_vm.O365)
                  },
                },
              },
              [
                _c("i", { staticClass: "fab fa-windows" }),
                _vm._v(" Microsoft365 "),
              ]
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }