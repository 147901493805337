import axios from 'axios';
import qs from 'qs';
import { O365 } from '@/common/data/constants';

const SET_JOBS = 'O365/JOBS/SET_JOBS';
const SET_LATEST_JOB_DETAIL = 'O365/JOBS/SET_LATEST_JOB_DETAIL';
const SET_LATEST_JOB_LOGS = 'O365/JOBS/SET_LATEST_JOB_LOGS';

const JOBS_ENDPOINT = `${O365}/jobs`;
const SINGLE_JOB_ENDPOINT = `${JOBS_ENDPOINT}/:jobId`;
const START_JOB_ENDPOINT = `${SINGLE_JOB_ENDPOINT}/start`;
const STOP_JOB_ENDPOINT = `${SINGLE_JOB_ENDPOINT}/stop`;
const ENABLE_JOB_ENDPOINT = `${SINGLE_JOB_ENDPOINT}/enable`;
const DISABLE_JOB_ENDPOINT = `${SINGLE_JOB_ENDPOINT}/disable`;
const LATEST_DETAIL_JOB_ENDPOINT = `${SINGLE_JOB_ENDPOINT}/detail/latest`;
const LATEST_JOB_LOGS_ENDPOINT = `${SINGLE_JOB_ENDPOINT}/detail/:sessionId/logs`;
const JOB_NOTIFICATION_ENDPOINT = `${SINGLE_JOB_ENDPOINT}/notifications`;

function initialState() {
  return {
    jobs: [],
    latestJobDetail: {},
    latestJobLogs: []
  };
}

const state = initialState();

// actions
const actions = {
  async GET_O365_JOBS({ commit }, params) {
    const response = await axios.get(JOBS_ENDPOINT, {
      params,
      paramsSerializer: params => qs.stringify(params, { allowDots: true })
    });
    const { data } = response;
    if (data.success) {
      commit(SET_JOBS, data.data);
    }
  },
  async CLEAR_O365_JOBS({ commit }) {
    commit(SET_JOBS, []);
  },
  async START_O365_JOB(context, { jobId, organizationId }) {
    const response = await axios.post(START_JOB_ENDPOINT.replace(':jobId', jobId), {
      organizationId
    });
    const { data } = response;
    return data.success;
  },
  async STOP_O365_JOB(context, { jobId, organizationId }) {
    const response = await axios.post(STOP_JOB_ENDPOINT.replace(':jobId', jobId), {
      organizationId
    });
    const { data } = response;
    return data.success;
  },
  async ENABLE_O365_JOB(context, { jobId, organizationId }) {
    const response = await axios.post(ENABLE_JOB_ENDPOINT.replace(':jobId', jobId), {
      organizationId
    });
    const { data } = response;
    return data.success;
  },
  async DISABLE_O365_JOB(context, { jobId, organizationId }) {
    const response = await axios.post(DISABLE_JOB_ENDPOINT.replace(':jobId', jobId), {
      organizationId
    });
    const { data } = response;
    return data.success;
  },
  async GET_O365_LATEST_JOB_DETAIL({ commit }, { jobId, organizationId }) {
    const response = await axios.get(LATEST_DETAIL_JOB_ENDPOINT.replace(':jobId', jobId), {
      params: { organizationId }
    });
    const { data } = response;
    if (data.success) {
      commit(SET_LATEST_JOB_DETAIL, data.data);
      commit(SET_LATEST_JOB_LOGS, data.data.logs);
    }
  },
  async CLEAR_O365_LATEST_JOB_DETAIL({ commit }) {
    commit(SET_LATEST_JOB_DETAIL, {});
  },
  async GET_O365_LATEST_JOB_LOGS({ commit }, { jobId, organizationId, sessionId, offset, limit }) {
    const response = await axios.get(
      LATEST_JOB_LOGS_ENDPOINT.replace(':jobId', jobId).replace(':sessionId', sessionId),
      { params: { organizationId, sessionId, offset, limit } }
    );
    const { data } = response;
    if (data.success) commit(SET_LATEST_JOB_LOGS, data.data);
  },
  async CLEAR_O365_LATEST_JOB_LOGS({ commit }) {
    commit(SET_LATEST_JOB_LOGS, []);
  },
  async ENABLE_O365_JOB_NOTIFICATION(context, { jobId, userIds, enabled, organizationId, externalAddresses }) {
    const params = { jobId, userIds, enabled, organizationId, externalAddresses };
    const response = await axios.post(JOB_NOTIFICATION_ENDPOINT.replace(':jobId', jobId), params);
    const { data } = response;
    return data.success;
  },
  async GET_O365_JOB_NOTIFICATION(context, { jobId, organizationId }) {
    const params = { jobId, organizationId };
    const response = await axios.get(JOB_NOTIFICATION_ENDPOINT.replace(':jobId', jobId), {
      params
    });
    return response.data;
  }
};

const getters = {
  getO365Jobs: state => state.jobs,
  getO365LatestJobDetail: state => state.latestJobDetail,
  getO365LatestJobLogs: state => state.latestJobLogs
};

const mutations = {
  [SET_JOBS](state, data) {
    state.jobs = data;
  },
  [SET_LATEST_JOB_DETAIL](state, data) {
    state.latestJobDetail = data;
  },
  [SET_LATEST_JOB_LOGS](state, data) {
    state.latestJobLogs = data;
  }
};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};
