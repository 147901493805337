import qs from 'qs';
import axios from 'axios';
import { MONITORING } from '@/common/data/constants';

const SET_HINTS = 'hints/SET_HINTS';

const BASE_HINTS_ENDPOINT = `${MONITORING}/backoffice/hints`;
const SINGLE_HINT_ENDPOINT = `${BASE_HINTS_ENDPOINT}/:hintId`;

function initialState() {
  return {
    hints: {}
  };
}

const state = initialState();

const actions = {
  ADD_HINT(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(BASE_HINTS_ENDPOINT, data)
        .then(response => resolve(response.data))
        .catch(() => reject('Error while trying to create the hint. Try again later'));
    });
  },
  EDIT_HINT(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(SINGLE_HINT_ENDPOINT.replace(':hintId', data._id), data)
        .then(response => resolve(response.data))
        .catch(() => reject('Error while trying to update the hint. Try again later'));
    });
  },
  DELETE_HINT(context, hintId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(SINGLE_HINT_ENDPOINT.replace(':hintId', hintId))
        .then(() => resolve())
        .catch(() => reject('Error while trying to delete the hint. Try again later'));
    });
  },
  GET_HINTS({ commit }, data) {
    let params = data;
    return new Promise((resolve, reject) => {
      axios
        .get(BASE_HINTS_ENDPOINT, {
          params,
          paramsSerializer: params => qs.stringify(params, { allowDots: true, indices: false })
        })
        .then(response => {
          commit(SET_HINTS, {
            count: response.data.count,
            data: response.data.data
          });
          resolve();
        })
        .catch(() => reject('Error while getting the hints. Try again later.'));
    });
  },
  ADD_HINT_TO_LIST({ commit, state }, hint) {
    let hints = JSON.parse(JSON.stringify(state.hints));
    const elementIndex = hints.data.findIndex(c => c._id === hint._id);
    const hintToAdd = { ...hint };
    if (elementIndex === -1) {
      hints.data.unshift(hintToAdd);
      hints.count++;
    } else hints.data[elementIndex] = hintToAdd;
    commit(SET_HINTS, hints);
  },
  DELETE_HINT_FROM_LIST({ commit, state }, hintId) {
    let hints = JSON.parse(JSON.stringify(state.hints));
    const elementIndex = hints.data.findIndex(c => c._id === hintId);
    if (elementIndex !== -1) {
      hints.data.splice(elementIndex, 1);
      hints.count--;
    }
    commit(SET_HINTS, hints);
  },
  CLEAR_HINTS({ commit }) {
    commit(SET_HINTS, {});
  }
};

const getters = {
  getHints: state => state.hints
};

const mutations = {
  [SET_HINTS](state, data) {
    state.hints = data;
  }
};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};
