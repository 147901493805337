<template>
  <div
    class="main-div"
    style="margin-bottom: 1rm"
    :class="{
      'input-group-focus': focused,
      'has-label': label
    }"
  >
    <slot name="label">
      <label v-if="label" class="input-label">
        <span>{{ label }}</span>
        <i v-if="required" class="required-field float-left" />
      </label>
    </slot>
    <div
      class="input-wrapper mb-0"
      :class="{
        'input-group': hasIcon
      }"
    >
      <slot name="addonLeft">
        <span v-if="addonLeftIcon" class="input-group-prepend">
          <div
            class="input-group-text"
            :class="{
              'error-brdr': error,
              'success-brdr': !error && touched
            }"
          >
            <i :class="addonLeftIcon"></i>
          </div>
        </span>
      </slot>
      <slot>
        <Select
          ref="select"
          class="select-primary"
          :class="{
            'error-border': hasValidation && error,
            'success-border': hasValidation && !error && touched
          }"
          v-focus="focus"
          v-on="listeners"
          filterable
          :value="value"
          :placeholder="placeholder"
          :disabled="disabled"
          :filter-method="filterMethod"
          :multiple="multiple"
          :allow-create="allowCreate"
          :collapse-tags="collapseTags"
          :clearable="clearable"
          :remote="remote"
          :remote-method="executeRemoteMethod"
          :loading="loading"
          :popper-class="popperClass"
        >
          <Option v-if="multiple && showSelectAll" label="Select All" :value="SELECT_ALL">
            <button class="btn btn-primary btn-sm">Select All</button>
          </Option>
          <Option v-if="(multiple && showClear)" label="Clear" :value="CLEAR">
            <button class="btn btn-primary btn-sm">Clear</button>
          </Option>
          <slot name="options">
            <Option
              v-for="option in options"
              class="select-primary"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            />
          </slot>
        </Select>
      </slot>
      <span
        class="success-icon hidden-xs d-none d-lg-block"
        v-if="hasValidation && !error && touched"
      >
        <i class="fa fa-check-circle" aria-hidden="true"></i>
      </span>
      <span class="error-icon" v-else-if="error">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
      </span>
    </div>
    <slot name="error" v-if="error || $slots.error">
      <i class="error-msg">{{ error }}</i>
    </slot>
  </div>
</template>

<script>
import { Select, Option } from 'element-ui';

export default {
  inheritAttrs: false,
  components: { Select, Option },
  name: 'BaseSelect',
  props: {
    options: Array,
    focus: Boolean,
    required: Boolean,
    label: { type: String },
    error: { type: String, default: '' },
    value: { type: [String, Number, Array] },
    addonLeftIcon: { type: String, description: 'Input icon on the left' },
    hasValidation: { type: Boolean, default: false },
    placeholder: String,
    disabled: Boolean,
    filterMethod: Function,
    multiple: Boolean,
    allowCreate: Boolean,
    collapseTags: Boolean,
    clearable: Boolean,
    remote: Boolean,
    remoteMethod: Function,
    popperClass: String,
    showSelectAll: Boolean,
    showClear: Boolean,
  },
  model: { prop: 'value', event: 'input' },
  data() {
    return {
      focused: false,
      touched: false,
      loading: false
    };
  },
  computed: {
    hasIcon() {
      const { addonLeft } = this.$slots;
      return addonLeft !== undefined || this.addonLeftIcon !== undefined;
    },
    listeners() {
      return {
        ...this.$listeners,
        input: this.onInput,
        blur: this.onBlur,
        focus: this.onFocus
      };
    },
    SELECT_ALL() {
      return 'selectAll';
    },
    CLEAR() {
      return 'clear';
    }
  },
  methods: {
    onInput(evt) {
      if (Array.isArray(evt)) {
        if (evt.length > 0) if (!this.touched) this.touched = true;
        if (evt.includes(this.SELECT_ALL)) {
          const allIdx = evt.indexOf(this.SELECT_ALL);
          evt.splice(allIdx, 1);
          if (this.options) evt.push(...this.options.map(o => o.value));
          else evt.push(...this.$slots.options.map(o => o.componentInstance.value));
        }
        if (evt.includes(this.CLEAR)) {
          evt = [];
        }
        return this.$emit('input', [...new Set(evt)]);
      } else if (!this.touched) this.touched = true;
      this.$emit('input', evt);
    },
    onFocus() {
      this.focused = true;
      this.$emit('onFocus');
    },
    onBlur() {
      this.focused = false;
    },
    reset() {
      this.touched = false;
    },
    setFocus() {
      this.$refs.select.focus();
    },
    async executeRemoteMethod(filter) {
      this.loading = true;
      await this.remoteMethod(filter);
      this.loading = false;
    }
  },
  directives: {
    focus: {
      inserted: function(el, { value }, { context }) {
        context.$nextTick(() => {
          if (value) el.children[0].children[0].focus();
        });
      }
    }
  }
};
</script>

<style scoped>
.error {
  display: block;
  width: 100%;
  font-size: 80%;
  color: #ff1721 !important;
  margin-top: 5px;
}
.success-icon {
  position: absolute;
  top: 12px;
  right: 10px;
  font-size: 10px;
}
.error-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  line-height: 1.5;
}
.main-div div {
  width: 100%;
}
</style>
