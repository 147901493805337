var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "footer",
    { staticClass: "footer" },
    [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-auto" }, [
            _vm._v("© " + _vm._s(_vm.year) + " Managecast"),
          ]),
          _c("div", { staticClass: "col-auto" }, [
            _c(
              "a",
              {
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    _vm.policyModal = true
                  },
                },
              },
              [_vm._v("Privacy Policy")]
            ),
          ]),
          _c("div", { staticClass: "phone ml-auto col-auto" }, [
            _vm.supportPhone
              ? _c("div", [
                  _c("i", { staticClass: "fas fa-phone" }),
                  _vm._v(" " + _vm._s(_vm.supportPhone) + " "),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "email col-auto" }, [
            _vm.supportEmail
              ? _c(
                  "div",
                  [
                    _c("i", { staticClass: "fas fa-envelope mr-1" }),
                    _c(
                      "router-link",
                      {
                        staticClass: "address",
                        attrs: { to: "/support/request" },
                      },
                      [_vm._v(" " + _vm._s(_vm.supportEmail) + " ")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _vm.enabledSocialMedia
            ? _c("div", { staticClass: "social col-auto" }, [
                _vm.twitterLink
                  ? _c(
                      "a",
                      { attrs: { href: _vm.twitterLink, target: "_blank" } },
                      [_c("i", { staticClass: "fab fa-twitter mr-2" })]
                    )
                  : _vm._e(),
                _vm.facebookLink
                  ? _c(
                      "a",
                      { attrs: { href: _vm.facebookLink, target: "_blank" } },
                      [_c("i", { staticClass: "fab fa-facebook-square mr-2" })]
                    )
                  : _vm._e(),
                _vm.youtubeLink
                  ? _c(
                      "a",
                      { attrs: { href: _vm.youtubeLink, target: "_blank" } },
                      [_c("i", { staticClass: "fab fa-youtube mr-2" })]
                    )
                  : _vm._e(),
                _vm.linkedinLink
                  ? _c(
                      "a",
                      { attrs: { href: _vm.linkedinLink, target: "_blank" } },
                      [_c("i", { staticClass: "fab fa-linkedin" })]
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
        ]),
      ]),
      _c(
        "modal",
        {
          attrs: { show: _vm.policyModal, modalClasses: "policy-modal" },
          on: {
            "update:show": function ($event) {
              _vm.policyModal = $event
            },
          },
        },
        [
          _c(
            "h4",
            {
              staticClass: "modal-title",
              attrs: { slot: "header" },
              slot: "header",
            },
            [_vm._v(" Privacy Policy ")]
          ),
          _c("div", { staticClass: "modal-div" }, [
            _c("p", [_c("strong", [_vm._v("Privacy Statement")])]),
            _c("p", [
              _vm._v(
                " We do not sell or rent personal information received through your use of this web site. This web site has security measures in place to help protect against the loss, misuse, or alteration of information under our control. These measures include encryption of data using the Secure Socket Layer (SSL) system. Despite these measures, the confidentiality of any communication or material transmitted to or from us via this Service by Internet or e-mail cannot be guaranteed. At your discretion, you may contact us at the mailing address or telephone number listed on this site’s contact page. "
              ),
            ]),
            _c("p", [
              _c("strong", [_vm._v("Use of Data Received in Web site Forms")]),
            ]),
            _c("p", [
              _vm._v(
                " Should you submit information on a form using this web site, the data (“Data”) therein may become part of a designated record set that we create for you. "
              ),
            ]),
            _c("p", [_c("strong", [_vm._v("Site Visitor Data")])]),
            _c("p", [
              _vm._v(
                " Managecast routinely gathers data on activity at this web site, such as how many people visit the site, the pages they visit, where they come from, how long they stay on the site, etc. The data is collected on an aggregate, anonymous basis, which means that no personally identifiable information is associated with the data. This data helps us improve the site for you, including site content and overall usage. The information is not shared with other organizations for their independent use. "
              ),
            ]),
            _c("p", [
              _c("strong", [
                _vm._v("Collecting and Using Personal Information"),
              ]),
            ]),
            _c("p", [
              _vm._v(
                " Except as disclosed in this policy, we do not collect any identifiable information about visitors to this site. "
              ),
            ]),
            _c("ol", [
              _c("li", [
                _c("strong", [_vm._v("Web logs")]),
                _vm._v(
                  " We maintain standard web logs that record data about all visitors and customers who use this site and we store this information for a while. These logs may contain the Internet domain from which you access the site (such as aol.com, abc.org, etc.); the IP address which is automatically assigned to your computer when you get on the Internet (a static IP address may be identifiable as being connected to you, while a dynamic address is usually not identifiable); the type of browser and operating system you use; the date and time you visited the site; the pages you viewed on the site; the address of the website you linked from, if any. If you sign on to this web site to use its secured features, our web logs will also contain an individual identifier and show the pages you have accessed. All web logs are stored securely, and may only be accessed by Managecast employees or designees on a need-to-know basis for a specific purpose.Managecast uses web log information to help us design our site, to identify popular features, to resolve user, hardware, and software problems, and to make the site more useful to visitors. "
                ),
              ]),
              _c("li", [
                _c("strong", [_vm._v("Internet cookies ")]),
                _vm._v(
                  "We may place Internet “cookies” on the computer hard drives of visitors to this site. Information we obtain from cookies helps us to tailor our site to be more helpful and efficient for you. The cookie consists of a unique identifier that does not contain information about you. We use two types of cookies, “session” cookies and “persistent” cookies. A session cookie is temporary, and expires after you end a session and close your web browser. We use session cookies to help customize your experience on our site, maintain your signed-on status as you navigate through our features, and to track your “click path” through our web pages. Persistent cookies remain on your hard drive after you’ve exited from our site, and we use them for several reasons. For instance, when you give us permission to “remember” a feature about you when asked by the web site, we place a persistent cookie on your hard drive so If you’ve given us permission to e-mail you with information about your data services, or for other reasons, we may use the persistent cookie on your hard drive to let us know when you come back to visit our site. We sometimes use this type of persistent cookie with a “web beacon” (see below). Persistent cookies will not contain any personal information about you. "
                ),
              ]),
            ]),
            _c("p", [
              _vm._v(
                " You may have software on your computer that will allow you to decline or deactivate Internet cookies, but if you do so, some features of this site may not work properly for you. For instructions on how to remove cookies from your hard drive, go to your browser’s help file for detailed instructions and browser updates available from the browser licensor. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " In addition, further information regarding cookies may be available on other websites or from your Internet service provider. Firefox and Internet Explorer are two common browsers. "
              ),
            ]),
            _c("ol", [
              _c("li", [
                _c("strong", [_vm._v("Web beacons ")]),
                _vm._v(
                  "We may also occasionally use “web beacons” (also known as “clear gifs,” “web bugs, ” “1-pixel gifs,” etc.) that allow us to collect non-personal information about your response to our e-mail communications, and for other purposes. Web beacons are tiny images, placed on a Web page or e-mail, that can tell us if you’ve gone to a particular area on our Service site. For example, if you’ve given us permission to send you e-mails, we may send you an e-mail urging you to use a certain feature of this Service. If you do respond to that e-mail and use that feature, the web beacon will tell us that our e-mail communication with you has been successful. We do not collect any personal information with a web beacon, and do not link web beacons with any other personal information you’ve given us. .Since Web beacons are used in conjunction with persistent cookies (described above), if you set your browser to decline or deactivate cookies, Web beacons cannot function. "
                ),
              ]),
              _c("li", [
                _c("strong", [_vm._v("E-mails")]),
                _vm._v(
                  " We may use a third-party vendor to help us manage some of our e-mail communications with you. While we may supply this vendor with e-mail addresses of those we wish them to contact, your e-mail address is never used for any purpose other than to communicate with you on our behalf. When you click on a link in an e-mail, you may temporarily be redirected through one of the vendor’s servers (although this process will be invisible to you) which will register that you’ve clicked on that link, and have visited our site. Managecast never shares any information, other than your e-mail address, with our third-party e-mail vendor, which does not share these e-mail addresses with anyone else. Even if you have given us permission to send e-mails to you, you may revoke that permission at any time by following the “unsubscribe” information at the bottom of each such e-mail. "
                ),
              ]),
              _c("li", [
                _c("strong", [_vm._v("Evaluation and research")]),
                _vm._v(
                  " We will periodically ask users to complete surveys asking about their experiences with features of the web site. Our surveys ask visitors for demographic information such as age, gender, and education, but will not request in such surveys that users provide specific information about any medical condition. We use survey information for research and quality improvement purposes, including helping Managecast to improve information and services offered through this web site. In addition, users giving feedback may be individually contacted for follow-up due to concerns raised during the course of such evaluation. Demographic information and web log data may be stored for future research and evaluation. "
                ),
              ]),
              _c("li", [
                _c("strong", [_vm._v("Messages and transactions")]),
                _vm._v(
                  " Comments or questions sent to us using e-mail or secure messaging forms will be shared with Managecast staff who are most able to address your concerns. We will archive your messages once we have made our best effort to provide you with a complete and satisfactory response. "
                ),
              ]),
              _c("li", [
                _c("strong", [_vm._v("Credit card transactions")]),
                _vm._v(
                  " If you provide us with your credit card number for payments, we will treat your credit card number in a secure manner. But your credit card number should never be entered into an unsecured website page. Not all pages on this site are intended to be secure. Only provide a credit card number in the box that we specifically designate. "
                ),
              ]),
              _c("li", [
                _c("strong", [_vm._v("Children")]),
                _vm._v(
                  " We do not knowingly allow or solicit anyone under the age of 13 to participate in any activity at this site. "
                ),
              ]),
              _c("li", [
                _c("strong", [_vm._v("Disclosures")]),
                _vm._v(
                  " We may disclose personal information to any person performing audit, legal, operational, or other services for us. We will use information which does not identify the individual for these activities whenever feasible. Personal information disclosed to vendors or contractors for our operational purposes may not be re-disclosed to others by such a vendor or contractor. We may disclose personal information when required to do so by a subpoena, court order, or search warrant. We may disclose personal information as we deem it appropriate to protect the safety of an individual or for an investigation related to public safety or to report an activity that appears to be in violation of law. We may disclose personal information to protect the security and reliability of this Service and to take precautions against liability. "
                ),
              ]),
              _c("li", [
                _c("strong", [_vm._v("Opt out")]),
                _vm._v(
                  " If a user makes a request to receive information in an ongoing manner through this site by providing their e-mail address (for example, requesting a subscription to one of our online publications), a user may make a request to discontinue future mailings. Similarly, if you receive information about a Managecast service through e-mail, you may make a request to discontinue receiving similar messages in the future. All such materials sent to you by e-mail will contain information about how to opt out. "
                ),
              ]),
            ]),
          ]),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "base-button",
                {
                  staticClass: "ml-auto",
                  attrs: { type: "secondary" },
                  on: {
                    click: function ($event) {
                      _vm.policyModal = false
                    },
                  },
                },
                [_vm._v(" Close ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }