import axios from 'axios';

const SET_USER_NOTIFICATIONS = 'notifications/SET_USER_NOTIFICATIONS';

const GET_USER_NOTIFICATIONS_ENDPOINT = 'notifications';
const ACK_NOTIFICATION_ENDPOINT = `${GET_USER_NOTIFICATIONS_ENDPOINT}/ack`;

function initialState() {
  return {
    userNotifications: [
    ]
  };
}

const actions = {
  SET_USER_NOTIFICATIONS({ commit }, data) {
    commit(SET_USER_NOTIFICATIONS, data);
  },
  GET_USER_NOTIFICATIONS({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(GET_USER_NOTIFICATIONS_ENDPOINT, data)
        .then(response => {
          const { data } = response;
          if (data.success) {
            commit(SET_USER_NOTIFICATIONS, data.data);
            resolve(data.data);
          }
        })
        .catch(error => {
          reject(error.response.data.message);
        });
    });
  },
  ACK_NOTIFICATION({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(ACK_NOTIFICATION_ENDPOINT, params)
        .then(response => {
          const { data } = response;
          if (data.success) {
            const filteredNotifications = state.userNotifications.filter(notification => notification._id !== params.notificationId);
            commit(SET_USER_NOTIFICATIONS, filteredNotifications);
          }
        })
        .catch(error => {
          reject(error.response.data.message);
        });
    });
  },
};

const getters = {
  getUserNotifications: state => state.userNotifications,
};

const mutations = {
  [SET_USER_NOTIFICATIONS](state, data) {
    state.userNotifications = data;
  },
};

export default {
  state: { ...initialState() },
  actions,
  getters,
  mutations
};
