import qs from 'qs';
import axios from 'axios';
import FileDownload from 'js-file-download';
import { O365 } from '@/common/data/constants';
import { datetimeToUTCString } from '@/common/helpers/date';

const SET_LICENSING = 'O365/REPOSITORIES/SET_LICENSING';
const SET_CONSUMPTION = 'O365/REPOSITORIES/SET_CONSUMPTION';

const LICENSING_ENDPOINT = `${O365}/licensing`;
const EXPORT_ENDPOINT = `${LICENSING_ENDPOINT}/export`;

const CONSUMPTION_ENDPOINT = `${O365}/consumption`;

function initialState() {
  return {
    licensing: [],
    consumption: []
  };
}

const state = initialState();

// actions
const actions = {
  async GET_O365_LICENSING({ commit }, params) {
    if (params.from && params.to) {
      params.from.startOf('day');
      params.to.endOf('day');
      params.from = '"' + datetimeToUTCString(params.from) + '"';
      params.to = '"' + datetimeToUTCString(params.to) + '"';
    }
    const response = await axios.get(LICENSING_ENDPOINT, {
      params,
      paramsSerializer: params => qs.stringify(params, { allowDots: true })
    });
    const { data } = response;
    if (data.success) {
      commit(SET_LICENSING, data);
    }
  },
  async EXPORT_O365_LICENSING_REPORT(context, query) {
    let params = query;
    if (params.from && params.to) {
      params.from.startOf('day');
      params.to.endOf('day');
      params.from = '"' + datetimeToUTCString(params.from) + '"';
      params.to = '"' + datetimeToUTCString(params.to) + '"';
    }
    const response = await axios.get(EXPORT_ENDPOINT, {
      params,
      paramsSerializer: params => qs.stringify(params, { allowDots: true, indices: false })
    });
    FileDownload(response.data, `O365Licensing-${Date.now()}.csv`);
  },
  async GET_O365_CONSUMPTION({ commit }, params) {
    if (params.from && params.to) {
      params.from.startOf('day');
      params.to.endOf('day');
      params.from = '"' + datetimeToUTCString(params.from) + '"';
      params.to = '"' + datetimeToUTCString(params.to) + '"';
    }
    const response = await axios.get(CONSUMPTION_ENDPOINT, {
      params,
      paramsSerializer: params => qs.stringify(params, { allowDots: true })
    });
    const { data } = response;
    if (data.success) {
      commit(SET_CONSUMPTION, data);
    }
  },
};

const getters = {
  getO365Licensing: state => state.licensing,
  getO365Consumption: state => state.consumption,
};

const mutations = {
  [SET_LICENSING](state, data) {
    if (data.length === 0) state.licensing = [];
    else if (state.licensing.length === 0) state.licensing.push(data);
    else {
      const idx = state.licensing.findIndex(l => l.serviceProvider.id === data.serviceProvider.id);
      // eslint-disable-next-line no-undef
      if (idx !== -1) Vue.set(state.licensing, idx, data);
      else state.licensing.push(data);
    }
  },
  [SET_CONSUMPTION](state, data) {
    if (data.length === 0) state.consumption = [];
    else if (state.consumption.length === 0) state.consumption.push(data);
    else {
      const idx = state.consumption.findIndex(l => l.serviceProvider.id === data.serviceProvider.id);
      // eslint-disable-next-line no-undef
      if (idx !== -1) Vue.set(state.consumption, idx, data);
      else state.consumption.push(data);
    }
  }
};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};
