var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "centered" }, [
      _c("h1", [
        _c("i", { staticClass: "not-found-icon nc-icon nc-puzzle-10" }),
        _vm._v("404"),
      ]),
      _c("p", [_vm._v("The page you requested could not be found.")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }