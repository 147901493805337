import axios from 'axios';
import qs from 'qs';
import { O365 } from '@/common/data/constants';

const SET_RESTORE_OPERATOR_ROLES = 'O365/RE_OP_ROLES/SET_RESTORE_OPERATOR_ROLES';

const RE_OP_ROLES_ENDPOINT = `${O365}/organizations/restore-roles`;

function initialState() {
  return { roRoles: [] };
}

const state = initialState();

// actions
const actions = {
  async GET_O365_RESTORE_OPERATOR_ROLES({ commit }, params) {
    const response = await axios.get(RE_OP_ROLES_ENDPOINT, {
      params,
      paramsSerializer: params => qs.stringify(params, { allowDots: true })
    });
    const { data } = response;
    if (data.success) {
      commit(SET_RESTORE_OPERATOR_ROLES, data.data);
    }
  },
  async CLEAR_O365_RESTORE_OPERATOR_ROLES({ commit }) {
    commit(SET_RESTORE_OPERATOR_ROLES, []);
  }
};

const getters = {
  getO365RORoles: state => state.roRoles
};

const mutations = {
  [SET_RESTORE_OPERATOR_ROLES](state, data) {
    state.roRoles = data;
  }
};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};
