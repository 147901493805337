import axios from 'axios';
import qs from 'qs';
import moment from 'moment';
import { O365 } from '@/common/data/constants';
import FileDownload from 'js-file-download';

const SET_SHAREPOINT_SITES = 'RESTORE/SET_SHAREPOINT_SITES';
const SET_SHAREPOINT_CONTENT = 'RESTORE/SET_SHAREPOINT_CONTENT';
const SET_SHAREPOINT_FOLDERS = 'RESTORE/SET_SHAREPOINT_FOLDERS';
const SET_SHAREPOINT_DOCUMENTS = 'RESTORE/SET_SHAREPOINT_DOCUMENTS';

const BASE_RESTORE_ENDPOINT = `${O365}/restore`;
const RESTORE_SESSIONS_ENDPOINT = `${BASE_RESTORE_ENDPOINT}/restoresessions`;
const RESTORE_SESSION_ENDPOINT = `${RESTORE_SESSIONS_ENDPOINT}/:restoreSessionId`;
const SHAREPOINT_SITES_ENDPOINT = `${RESTORE_SESSION_ENDPOINT}/sharepoint`;
const RESTORE_SHAREPOINT_SITE_ENDPOINT = `${SHAREPOINT_SITES_ENDPOINT}/restore/original`;
const RESTORE_DIFF_LOC_SHAREPOINT_SITE_ENDPOINT = `${SHAREPOINT_SITES_ENDPOINT}/restore/another`;
const SHAREPOINT_FOLDERS_ENDPOINT = `${SHAREPOINT_SITES_ENDPOINT}/:siteId/folders`;
const SHAREPOINT_CONTENT_ENDPOINT = `${SHAREPOINT_SITES_ENDPOINT}/:siteId/content`;
const RESTORE_SHAREPOINT_FOLDER_ENDPOINT = `${SHAREPOINT_SITES_ENDPOINT}/restore-folder/original`;
const RESTORE_DIFF_LOC_SHAREPOINT_FOLDER_ENDPOINT = `${SHAREPOINT_SITES_ENDPOINT}/restore-folder/another`;
const DOWNLOAD_SHAREPOINT_FOLDER_ENDPOINT = `${SHAREPOINT_SITES_ENDPOINT}/restore-folder/download`;
const RESTORE_SHAREPOINT_LIST_ENDPOINT = `${SHAREPOINT_SITES_ENDPOINT}/restore-list/original`;
const RESTORE_DIFF_LOC_SHAREPOINT_LIST_ENDPOINT = `${SHAREPOINT_SITES_ENDPOINT}/restore-list/another`;
const RESTORE_SHAREPOINT_LIBRARY_ENDPOINT = `${SHAREPOINT_SITES_ENDPOINT}/restore-library/original`;
const RESTORE_DIFF_LOC_SHAREPOINT_LIBRARY_ENDPOINT = `${SHAREPOINT_SITES_ENDPOINT}/restore-library/another`;
const DOWNLOAD_SHAREPOINT_LIBRARY_ENDPOINT = `${SHAREPOINT_SITES_ENDPOINT}/restore-library/download`;
const SHAREPOINT_DOCUMENTS_ENDPOINT = `${SHAREPOINT_SITES_ENDPOINT}/:siteId/folders/documents`;
const RESTORE_SHAREPOINT_DOCUMENT_ENDPOINT = `${SHAREPOINT_SITES_ENDPOINT}/restore-document/original`;
const RESTORE_DIFF_LOC_SHAREPOINT_DOCUMENT_ENDPOINT = `${SHAREPOINT_SITES_ENDPOINT}/restore-document/another`;
const DOWNLOAD_SHAREPOINT_DOCUMENT_ENDPOINT = `${SHAREPOINT_SITES_ENDPOINT}/restore-document/download`;
const SHAREPOINT_ITEMS_ENDPOINT = `${SHAREPOINT_SITES_ENDPOINT}/:siteId/folders/items`;
const RESTORE_SHAREPOINT_ITEM_ENDPOINT = `${SHAREPOINT_SITES_ENDPOINT}/restore-item/original`;
const RESTORE_DIFF_LOC_SHAREPOINT_ITEM_ENDPOINT = `${SHAREPOINT_SITES_ENDPOINT}/restore-item/another`;

function initialState() {
  return {
    sites: []
  };
}

const state = initialState();

// actions
const actions = {
  async GET_SHAREPOINT_SITES({ commit }, { restoreSessionId, params, parentId, breadcrumbIds }) {
    try {
      const response = await axios.get(
        SHAREPOINT_SITES_ENDPOINT.replace(':restoreSessionId', restoreSessionId),
        {
          params: { ...params, companyId: params.companyId, parentId },
          paramsSerializer: params => qs.stringify(params, { allowDots: true, indices: false })
        }
      );
      commit(SET_SHAREPOINT_SITES, {
        data: response.data?.data?.results || response.data?.data,
        parentId,
        breadcrumbIds
      });
    } catch (error) {
      throw 'Error getting the sites. Try again later.';
    }
  },
  async RESTORE_SHAREPOINT_SITE({ commit }, params) {
    try {
      const data = {
        companyId: params.companyId,
        organizationId: params.organizationId,
        siteId: params.siteId,
        options: params.options
      };
      const response = await axios.post(
        RESTORE_SHAREPOINT_SITE_ENDPOINT.replace(':restoreSessionId', params.restoreSessionId),
        data
      );
    } catch (error) {
      throw 'Error restoring the site. Try again later.';
    }
  },
  async RESTORE_SHAREPOINT_SITE_DIFF_LOC({ commit }, params) {
    try {
      const data = {
        companyId: params.companyId,
        organizationId: params.organizationId,
        siteId: params.siteId,
        options: params.options,
        siteURL: params.siteURL
      };
      const response = await axios.post(
        RESTORE_DIFF_LOC_SHAREPOINT_SITE_ENDPOINT.replace(
          ':restoreSessionId',
          params.restoreSessionId
        ),
        data
      );
    } catch (error) {
      throw 'Error restoring the site. Try again later.';
    }
  },
  async GET_SHAREPOINT_SITE_CONTENT(
    { commit },
    { restoreSessionId, siteId, params, parentId, breadcrumbIds }
  ) {
    try {
      const response = await axios.get(
        SHAREPOINT_CONTENT_ENDPOINT.replace(':restoreSessionId', restoreSessionId).replace(
          ':siteId',
          siteId
        ),
        {
          params: { ...params, companyId: params.companyId, parentId },
          paramsSerializer: params => qs.stringify(params, { allowDots: true, indices: false })
        }
      );
      commit(SET_SHAREPOINT_CONTENT, {
        siteId,
        data: response.data.data,
        parentId,
        breadcrumbIds
      });
    } catch (error) {
      throw 'Error getting the site folders. Try again later.';
    }
  },
  async RESTORE_SHAREPOINT_LIST({ commit }, params) {
    try {
      const data = {
        companyId: params.companyId,
        organizationId: params.organizationId,
        siteId: params.siteId,
        list: params.list,
        listId: params.listId,
        options: params.options
      };
      const response = await axios.post(
        RESTORE_SHAREPOINT_LIST_ENDPOINT.replace(':restoreSessionId', params.restoreSessionId),
        data
      );
    } catch (error) {
      throw 'Error restoring the folder. Try again later.';
    }
  },
  async RESTORE_SHAREPOINT_LIST_DIFF_LOC({ commit }, params) {
    try {
      const data = {
        companyId: params.companyId,
        organizationId: params.organizationId,
        siteId: params.siteId,
        list: params.list,
        listId: params.listId,
        options: params.options,
        siteURL: params.siteURL
      };
      const response = await axios.post(
        RESTORE_DIFF_LOC_SHAREPOINT_LIST_ENDPOINT.replace(
          ':restoreSessionId',
          params.restoreSessionId
        ),
        data
      );
    } catch (error) {
      throw 'Error restoring the folder. Try again later.';
    }
  },
  async RESTORE_SHAREPOINT_LIBRARY({ commit }, params) {
    try {
      const data = {
        companyId: params.companyId,
        organizationId: params.organizationId,
        siteId: params.siteId,
        list: params.list,
        libraryId: params.libraryId,
        options: params.options
      };
      const response = await axios.post(
        RESTORE_SHAREPOINT_LIBRARY_ENDPOINT.replace(':restoreSessionId', params.restoreSessionId),
        data
      );
    } catch (error) {
      throw 'Error restoring the folder. Try again later.';
    }
  },
  async RESTORE_SHAREPOINT_LIBRARY_DIFF_LOC({ commit }, params) {
    try {
      const data = {
        companyId: params.companyId,
        organizationId: params.organizationId,
        siteId: params.siteId,
        list: params.list,
        libraryId: params.libraryId,
        options: params.options,
        siteURL: params.siteURL
      };
      const response = await axios.post(
        RESTORE_DIFF_LOC_SHAREPOINT_LIBRARY_ENDPOINT.replace(
          ':restoreSessionId',
          params.restoreSessionId
        ),
        data
      );
    } catch (error) {
      throw 'Error restoring the folder. Try again later.';
    }
  },
  async GET_SHAREPOINT_FOLDERS(
    { commit },
    { restoreSessionId, siteId, params, parentId, breadcrumbIds, parentName }
  ) {
    try {
      const response = await axios.get(
        SHAREPOINT_FOLDERS_ENDPOINT.replace(':restoreSessionId', restoreSessionId).replace(
          ':siteId',
          siteId
        ),
        {
          params: { ...params, companyId: params.companyId, parentId },
          paramsSerializer: params => qs.stringify(params, { allowDots: true, indices: false })
        }
      );
      commit(SET_SHAREPOINT_FOLDERS, {
        siteId,
        folders: response.data?.data?.results || response.data?.data,
        parentId,
        breadcrumbIds,
        parentName
      });
    } catch (error) {
      throw 'Error getting the site folders. Try again later.';
    }
  },
  async RESTORE_SHAREPOINT_FOLDER({ commit }, params) {
    try {
      const data = {
        companyId: params.companyId,
        organizationId: params.organizationId,
        siteId: params.siteId,
        folders: params.folders,
        list: params.list
      };
      const response = await axios.post(
        RESTORE_SHAREPOINT_FOLDER_ENDPOINT.replace(':restoreSessionId', params.restoreSessionId),
        data
      );
    } catch (error) {
      throw 'Error restoring the folder. Try again later.';
    }
  },
  async RESTORE_SHAREPOINT_FOLDER_DIFF_LOC({ commit }, params) {
    try {
      const data = {
        companyId: params.companyId,
        organizationId: params.organizationId,
        siteId: params.siteId,
        list: params.list,
        options: params.options,
        folders: params.folders,
        siteURL: params.siteURL,
        folderId: params.folderId
      };
      const response = await axios.post(
        RESTORE_DIFF_LOC_SHAREPOINT_FOLDER_ENDPOINT.replace(
          ':restoreSessionId',
          params.restoreSessionId
        ),
        data
      );
    } catch (error) {
      throw 'Error restoring the folder. Try again later.';
    }
  },
  async GET_SHAREPOINT_DOCUMENTS(
    { commit, state },
    { restoreSessionId, siteId, params, parentId, breadcrumbIds, parentName, limit, offset }
  ) {
    try {
      const response = await axios.get(
        SHAREPOINT_DOCUMENTS_ENDPOINT.replace(':restoreSessionId', restoreSessionId).replace(
          ':siteId',
          siteId
        ),
        {
          params: { ...params, companyId: params.companyId, parentId, limit, offset },
          paramsSerializer: params => qs.stringify(params, { allowDots: true, indices: false })
        }
      );
      const documents = response.data?.data?.results || response.data?.data;
      commit(SET_SHAREPOINT_DOCUMENTS, {
        siteId,
        documents,
        parentId,
        breadcrumbIds,
        parentName
      });
      const siteIdx = state.sites.findIndex(site => site.id === siteId);
      const site = state.sites[siteIdx];
      return {
        _links: response.data?.data?._links,
        results: documents?.map(doc => {
          return {
            ...doc,
            list: parentName,
            siteURL: site.url
          };
        })
      }
    } catch (error) {
      throw 'Error getting the onedrive folders. Try again later.';
    }
  },
  async RESTORE_SHAREPOINT_DOCUMENT({ commit }, params) {
    try {
      const data = {
        companyId: params.companyId,
        organizationId: params.organizationId,
        siteId: params.siteId,
        documents: params.documents,
        list: params.list,
        options: params.options
      };
      const response = await axios.post(
        RESTORE_SHAREPOINT_DOCUMENT_ENDPOINT.replace(':restoreSessionId', params.restoreSessionId),
        data
      );
    } catch (error) {
      throw 'Error restoring the folder. Try again later.';
    }
  },
  async RESTORE_SHAREPOINT_DOCUMENT_DIFF_LOC({ commit }, params) {
    try {
      const data = {
        companyId: params.companyId,
        organizationId: params.organizationId,
        siteId: params.siteId,
        documents: params.documents,
        list: params.list,
        options: params.options,
        siteURL: params.siteURL
      };
      const response = await axios.post(
        RESTORE_DIFF_LOC_SHAREPOINT_DOCUMENT_ENDPOINT.replace(
          ':restoreSessionId',
          params.restoreSessionId
        ),
        data
      );
    } catch (error) {
      throw 'Error restoring the folder. Try again later.';
    }
  },
  async GET_SHAREPOINT_ITEMS(
    { commit, state },
    { restoreSessionId, siteId, params, parentId, breadcrumbIds, parentName, limit, offset }
  ) {
    try {
      const response = await axios.get(
        SHAREPOINT_ITEMS_ENDPOINT.replace(':restoreSessionId', restoreSessionId).replace(
          ':siteId',
          siteId
        ),
        {
          params: { ...params, companyId: params.companyId, parentId, limit, offset },
          paramsSerializer: params => qs.stringify(params, { allowDots: true, indices: false })
        }
      );
      const documents = response.data?.data?.results || response.data?.data;
      commit(SET_SHAREPOINT_DOCUMENTS, {
        siteId,
        documents,
        parentId,
        breadcrumbIds
      });
      const siteIdx = state.sites.findIndex(site => site.id === siteId);
      const site = state.sites[siteIdx];
      return documents?.map(item => {
        return {
          ...item,
          list: parentName,
          siteURL: site.url
        };
      });
    } catch (error) {
      throw 'Error getting the onedrive folders. Try again later.';
    }
  },
  async RESTORE_SHAREPOINT_ITEM({ commit }, params) {
    try {
      const data = {
        companyId: params.companyId,
        organizationId: params.organizationId,
        siteId: params.siteId,
        items: params.items,
        list: params.list,
        options: params.options
      };
      const response = await axios.post(
        RESTORE_SHAREPOINT_ITEM_ENDPOINT.replace(':restoreSessionId', params.restoreSessionId),
        data
      );
    } catch (error) {
      throw 'Error restoring the folder. Try again later.';
    }
  },
  async RESTORE_SHAREPOINT_ITEM_DIFF_LOC({ commit }, params) {
    try {
      const data = {
        companyId: params.companyId,
        organizationId: params.organizationId,
        siteId: params.siteId,
        items: params.items,
        list: params.list,
        options: params.options,
        siteURL: params.siteURL
      };
      const response = await axios.post(
        RESTORE_DIFF_LOC_SHAREPOINT_ITEM_ENDPOINT.replace(
          ':restoreSessionId',
          params.restoreSessionId
        ),
        data
      );
    } catch (error) {
      throw 'Error restoring the folder. Try again later.';
    }
  },
  async DOWNLOAD_SHAREPOINT_LIBRARY({ commit }, params) {
    try {
      const data = {
        companyId: params.companyId,
        organizationId: params.organizationId,
        siteId: params.siteId,
        libraryId: params.libraryId,
        fileName: params.fileName
      };
      const response = await axios.post(
        DOWNLOAD_SHAREPOINT_LIBRARY_ENDPOINT.replace(':restoreSessionId', params.restoreSessionId),
        data,
        {
          headers: { Accept: 'application/zip' },
          responseType: 'blob'
        }
      );
      const blob = new Blob([response.data], { type: 'application/octet-stream' });
      const date = moment().format('YYYY-MM-DD hh:mm:ss');
      FileDownload(blob, `${date}-${params.fileName}-library.zip`);
    } catch (error) {
      throw 'Error restoring the folder. Try again later.';
    }
  },
  async DOWNLOAD_SHAREPOINT_FOLDER({ commit }, params) {
    try {
      const data = {
        companyId: params.companyId,
        organizationId: params.organizationId,
        siteId: params.siteId,
        folderId: params.folderId,
        fileName: params.fileName
      };
      const response = await axios.post(
        DOWNLOAD_SHAREPOINT_FOLDER_ENDPOINT.replace(':restoreSessionId', params.restoreSessionId),
        data,
        {
          headers: { Accept: 'application/zip' },
          responseType: 'blob'
        }
      );
      const blob = new Blob([response.data], { type: 'application/octet-stream' });
      const date = moment().format('YYYY-MM-DD hh:mm:ss');
      FileDownload(blob, `${date}-${params.fileName}-folder.zip`);
    } catch (error) {
      throw 'Error restoring the folder. Try again later.';
    }
  },
  async DOWNLOAD_SHAREPOINT_DOCUMENTS({ commit }, params) {
    try {
      const data = {
        companyId: params.companyId,
        organizationId: params.organizationId,
        siteId: params.siteId,
        documents: params.documents,
        fileName: params.fileName
      };
      const response = await axios.post(
        DOWNLOAD_SHAREPOINT_DOCUMENT_ENDPOINT.replace(':restoreSessionId', params.restoreSessionId),
        data,
        {
          headers: { Accept: 'application/zip' },
          responseType: 'blob'
        }
      );
      const blob = new Blob([response.data], { type: 'application/octet-stream' });
      const date = moment().format('YYYY-MM-DD hh:mm:ss');
      FileDownload(blob, `${date}-${params.fileName}-documents.zip`);
    } catch (error) {
      throw 'Error restoring the folder. Try again later.';
    }
  },
  CLEAR_SHAREPOINT_SITES({ commit }) {
    commit(SET_SHAREPOINT_SITES, { clear: true });
  }
};

const getters = {
  getSharepointSites: state => state.sites
};

const mutations = {
  [SET_SHAREPOINT_SITES](state, { data, parentId, breadcrumbIds, clear = false }) {
    if (clear) {
      state.sites = [];
      return;
    }
    if (!parentId) {
      state.sites = data.map(site => {
        return {
          ...site,
          type: 'site',
          children: [
            {
              name: 'Subsites',
              id: `${site.id}-subsites`,
              siteId: site.id,
              children: [],
              type: 'subsites'
            },
            {
              name: 'Content',
              id: `${site.id}-content`,
              siteId: site.id,
              children: [],
              type: 'content'
            }
          ]
        };
      });
    } else {
      const siteIdx = state.sites.findIndex(site => site.id === parentId);
      const site = state.sites[siteIdx];
      const childIdx = site.children.findIndex(child => child.id === `${parentId}-subsites`);
      const child = site.children[childIdx];
      child.children = [
        ...data.map(folder => {
          return {
            name: folder.name,
            id: folder.id,
            siteId: site.id,
            folderObj: { ...folder },
            data: []
          };
        })
      ];
      site.children.splice(childIdx, 1, child);
      state.sites.splice(siteIdx, 1, site);
    }
  },
  [SET_SHAREPOINT_CONTENT](state, { data, parentId, breadcrumbIds }) {
    const siteIdx = state.sites.findIndex(site => site.id === parentId);
    const site = state.sites[siteIdx];
    const childIdx = site.children.findIndex(child => child.id === `${parentId}-content`);
    const child = site.children[childIdx];
    child.children = [
      ...data.map(folder => {
        return {
          name: folder.name,
          id: folder.id,
          siteId: site.id,
          type: folder.type,
          folderObj: { ...folder },
          data: [],
          siteURL: site.url
        };
      })
    ];
    site.children.splice(childIdx, 1, child);
    state.sites.splice(siteIdx, 1, site);
  },
  [SET_SHAREPOINT_FOLDERS](state, { siteId, folders, parentId, breadcrumbIds, parentName }) {
    const siteIdx = state.sites.findIndex(site => site.id === siteId);
    const site = state.sites[siteIdx];
    if (site) {
      let copy = { ...site };
      //If parentId is null, it means we fetched the folders of the site.
      if (!parentId) {
        copy.children = [
          ...folders.map(folder => {
            return {
              name: folder.name,
              id: folder.id,
              siteId,
              list: parentName,
              type: 'folder',
              folderObj: { ...folder },
              data: [],
              folderType: folder.type,
              siteURL: site.url
            };
          })
        ];
        //In here we replace the site in the array with the copy that contains the folders.
        state.sites.splice(siteIdx, 1, copy);
      } else {
        //If parentId is not Root, we fetched the folders for a subfolder
        //First we check the easiest case which is a direct child of the site.
        const index = copy.children.findIndex(child => child.id === parentId);
        if (index !== -1) {
          copy.children[index].children = [
            ...folders.map(folder => {
              return {
                name: folder.name,
                id: folder.id,
                siteId,
                list: parentName,
                type: 'folder',
                folderObj: { ...folder },
                data: [],
                folderType: folder.type,
                siteURL: site.url
              };
            })
          ];
          state.sites.splice(siteIdx, 1, copy);
        } else {
          //If it's not a direct child of the site, we follow the IDs in the breadcrumb
          //which allows us to follow the path to the selected folder for which we fetched
          //the subfolders.
          //We remove the first ID of the breadcrumb because it's the siteId
          breadcrumbIds.shift();
          //We're searching in the children of each folder the ID in the breadcrumb starting
          //with the site children.
          let children = copy.children;
          for (let id of breadcrumbIds) {
            //we get the folder of each id in the breadcrumb and check if it has children:
            //If it does, it means we have to check inside it's children as well
            //If it doesn't, we reached the selected folder and we map the folders to it's children.
            const folder = children.find(child => child.id === id);
            if (folder?.children) {
              children = folder.children;
            } else {
              folder.children = [
                ...folders.map(folder => {
                  return {
                    name: folder.name,
                    id: folder.id,
                    siteId,
                    list: parentName,
                    type: 'folder',
                    folderObj: { ...folder },
                    data: [],
                    folderType: folder.type,
                    siteURL: site.url
                  };
                })
              ];
            }
          }
          //Finally, we replace the site in the state with the one with the subfolders.
          state.sites.splice(siteIdx, 1, copy);
        }
      }
    }
  },
  [SET_SHAREPOINT_DOCUMENTS](state, { siteId, documents, parentId, breadcrumbIds, parentName }) {
    const siteIdx = state.sites.findIndex(site => site.id === siteId);
    const site = state.sites[siteIdx];
    documents = documents.map(doc => {
      return {
        ...doc,
        list: parentName,
        siteURL: site.url
      };
    });
    if (site) {
      let copy = { ...site };
      //If parentId is Root, it means we fetched the documents of the site.
      if (parentId === 'Root') {
        copy.data = [...documents];
        //In here we replace the site in the array with the copy that contains the documents.
        state.sites.splice(siteIdx, 1, copy);
      } else {
        //If parentId is not Root, we fetched the documents for a subfolder
        //First we check the easiest case which is a direct child of the site.
        const index = copy.children.findIndex(child => child.id === parentId);
        documents = [...documents];
        if (index !== -1) {
          copy.children[index].data = [...documents];
          state.sites.splice(siteIdx, 1, copy);
        } else {
          //If it's not a direct child of the site, we follow the IDs in the breadcrumb
          //which allows us to follow the path to the selected folder for which we fetched
          //the documents.
          //We remove the first ID of the breadcrumb because it's the siteId
          breadcrumbIds.shift();
          //We're searching in the children of each folder the ID in the breadcrumb starting
          //with the site children.
          let children = copy.children;
          for (let [index, value] of breadcrumbIds.entries()) {
            //we get the folder of each id in the breadcrumb and check if it's the last index:
            //If it is, we assign the documents to it's data property
            //If it is not, we search in the children of the current folder.
            const folder = children.find(child => child.id === value);
            if (index === breadcrumbIds.length - 1) {
              folder.data = [...documents];
            } else {
              children = folder.children;
            }
          }
          //Finally, we replace the site in the state with the one with the documents.
          state.sites.splice(siteIdx, 1, copy);
        }
      }
    }
  }
};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};
