import axios from 'axios';

// Endpoints
const GET_IP_LOCATION_ENDPOINT = 'app/get_ip_location';

const SET_SELECTED_APP = 'app/SET_SELECTED_APP';
const SET_SIDEBAR_OPEN_MENU = 'app/SET_SIDEBAR_OPEN_MENU';
const SET_IP_LOCATION = 'app/SET_IP_LOCATION';

function initialState() {
  return { selectedApp: null, sidebarOpenMenu: '', ipLocation: '' };
}

const actions = {
  SET_SELECTED_APP({ commit }, data) {
    commit(SET_SELECTED_APP, data);
  },
  UPDATE_SIDEBAR_OPEN_MENU({ commit }, openMenuName) {
    commit(SET_SIDEBAR_OPEN_MENU, openMenuName);
  },
  async GET_IP_LOCATION({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(GET_IP_LOCATION_ENDPOINT).then(response => {
        if (response.data.success) {
          commit(SET_IP_LOCATION, response.data.country.trim());
        }
      })
    })
  }
};

const getters = {
  getSelectedApp: state => state.selectedApp,
  getSidebarOpenMenu: state => state.sidebarOpenMenu,
  getIpLocation: state => state.ipLocation
};

const mutations = {
  [SET_SELECTED_APP](state, data) {
    state.selectedApp = data;
  },
  [SET_SIDEBAR_OPEN_MENU](state, data) {
    state.sidebarOpenMenu = data;
  },
  [SET_IP_LOCATION](state, data) {
    state.ipLocation = data;
  }
};

export default {
  state: { ...initialState() },
  actions,
  getters,
  mutations
};
