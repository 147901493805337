<template>
  <div>
    <div class="wrapper wrapper-full-page">
      <notifications></notifications>
      <div
        ref="fullPage"
        class="full-page"
        :class="[pageClass, fullPageClass]"
      >
        <div ref="content" class="content">
          <zoom-center-transition :duration="pageTransitionDuration" mode="out-in">
            <router-view></router-view>
          </zoom-center-transition>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { BaseNav } from 'src/common/components';
import { ZoomCenterTransition } from 'vue2-transitions';
import { mapGetters } from 'vuex';

export default {
  components: {
    BaseNav,
    ZoomCenterTransition
  },
  props: {
    backgroundColor: {
      type: String,
      default: 'black'
    }
  },
  data() {
    return {
      showMenu: false,
      menuTransitionDuration: 250,
      pageTransitionDuration: 200,
      year: new Date().getFullYear(),
      pageClass: 'login-page'
    };
  },
  computed: {
    ...mapGetters(['isVMOGetter', 'isHunterTechGetter', 'isDifferentDevGetter', 'isProdatixGetter']),
    title() {
      return `${this.$route.name} Page`;
    },
    fullPageClass() {
      return this.isVMOGetter
        ? 'vmo' : this.isHunterTechGetter
        ? 'hunter-tech' : this.isDifferentDevGetter
        ? 'different-dev' : this.isProdatixGetter
        ? 'prodatix' : null;
    }
  },
  mounted() {
    this.setPageClass();
  },
  beforeDestroy() {
    this.closeMenu();
  },
  beforeRouteUpdate(to, from, next) {
    // Close the mobile menu first then transition to next page
    if (this.showMenu) {
      this.closeMenu();
      setTimeout(() => {
        next();
      }, this.menuTransitionDuration);
    } else {
      next();
    }
  },
  methods: {
    toggleNavbar() {
      document.body.classList.toggle('nav-open');
      this.showMenu = !this.showMenu;
    },
    closeMenu() {
      document.body.classList.remove('nav-open');
      this.showMenu = false;
    },
    setPageClass() {
      this.pageClass = `${this.$route.name}-page`.toLowerCase();
    }
  },
  watch: {
    $route() {
      this.setPageClass();
    }
  }
};
</script>
<style lang="scss">
.singlesignon-page {
  background-image: none !important;
}
.navbar.auth-navbar {
  top: 0;
}

$scaleSize: 0.8;
@keyframes zoomIn8 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  100% {
    opacity: 1;
  }
}

.wrapper-full-page .zoomIn {
  animation-name: zoomIn8;
}

@keyframes zoomOut8 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.wrapper-full-page .zoomOut {
  animation-name: zoomOut8;
}
</style>
