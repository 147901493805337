import axios from 'axios';

// Endpoints
const SERVICE_PROVIDERS_ENDPOINT = 'service-providers';
const SERVICE_PROVIDERS_BRANDING_ENDPOINT = `${SERVICE_PROVIDERS_ENDPOINT}/branding`;
const SERVICE_PROVIDERS_HS_ENDPOINT = `${SERVICE_PROVIDERS_ENDPOINT}/hs-token`;
const SERVICE_PROVIDERS_VALIDATE_VACID_ENDPOINT = `${SERVICE_PROVIDERS_ENDPOINT}/validate-vacid`;

const SET_BRANDING = 'serviceProviders/SET_BRANDING';
const SET_HS_TOKEN = 'serviceProviders/SET_HS_TOKEN';
const SET_IS_VMO = 'serviceProviders/SET_IS_VMO';
const SET_IS_HUNTERTECH = 'serviceProviders/SET_IS_HUNTERTECH';
const SET_IS_DIFFERENTDEV = 'serviceProviders/SET_IS_DIFFERENTDEV';
const SET_IS_PRODATIX = 'serviceProviders/SET_IS_PRODATIX';

// initial state
const state = {
  branding: {},
  usersToNotify: {},
  company: {},
  hsTokenData: {},
  isVMO: false,
  isHunterTech: false,
  isDifferentDev: false,
  isProdatix: false
};

// actions
const actions = {
  GET_BRANDING({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(SERVICE_PROVIDERS_BRANDING_ENDPOINT)
        .then(response => {
          commit(SET_BRANDING, response.data);
          document.title = response.data.title || 'Portal';
          if (response.data.favIconUrl) {
            let favIconLink = document.createElement('link');
            favIconLink.rel = 'icon';
            favIconLink.type = 'image/png';
            favIconLink.sizes = '96x96';
            favIconLink.href = response.data.favIconUrl;
            document.head.appendChild(favIconLink);
          }
          resolve();
        })
        .catch(error => reject(error, 'GET_BRANDING'));
    });
  },
  GET_HS_TOKEN({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(SERVICE_PROVIDERS_HS_ENDPOINT, payload)
        .then(response => {
          const { data } = response;
          commit(SET_HS_TOKEN, data.token);
          resolve();
        })
        .catch(error => reject(error, 'GET_HS_TOKEN'));
    });
  },
  VALIDATE_VACID(context, value) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${SERVICE_PROVIDERS_VALIDATE_VACID_ENDPOINT}/${value}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => reject(error, 'VALIDATE_VACID'));
    });
  }
};

const getters = {
  brandingGetter: state => state.branding,
  hsTokenGetter: state => state.hsTokenData,
  isVMOGetter: state => state.isVMO,
  isHunterTechGetter: state => state.isHunterTech,
  isDifferentDevGetter: state => state.isDifferentDev,
  isProdatixGetter: state => state.isProdatix
};

const mutations = {
  [SET_BRANDING](state, data) {
    state.branding = data;
    axios.defaults.baseURL = data.apiUrl;
  },
  [SET_HS_TOKEN](state, data) {
    state.hsTokenData = data;
  },
  [SET_IS_VMO](state, data) {
    state.isVMO = data;
  },
  [SET_IS_HUNTERTECH](state, data) {
    state.isHunterTech = data;
  },
  [SET_IS_DIFFERENTDEV](state, data) {
    state.isDifferentDev = data;
  },
  [SET_IS_PRODATIX](state, data) {
    state.isProdatix = data;
  }
};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};
