var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.tag,
    {
      tag: "component",
      staticClass: "btn",
      class: [
        { "btn-round": _vm.round },
        { "btn-block": _vm.block },
        { "btn-wd": _vm.wide },
        { "btn-icon btn-fab": _vm.icon },
        { [`btn-${_vm.type}`]: _vm.type },
        { [`btn-${_vm.size}`]: _vm.size },
        { "btn-simple": _vm.simple },
        { "btn-link": _vm.link },
        { disabled: _vm.disabled && _vm.tag !== "button" },
      ],
      attrs: {
        type: _vm.tag === "button" ? _vm.nativeType : "",
        disabled: _vm.disabled || _vm.loading,
      },
      on: { click: _vm.handleClick },
    },
    [
      _vm._t("loading", function () {
        return [
          _vm.loading
            ? _c("i", { staticClass: "fas fa-spinner fa-spin" })
            : _vm._e(),
        ]
      }),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }