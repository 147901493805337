import axios from 'axios';
import { MONITORING } from '@/common/data/constants';

const SINGLE_JOB_EXECUTION_ENDPOINT = `${MONITORING}/backoffice/jobExecutions/:id`;
const JOB_EXECUTION_BULK_ENDPOINT = `${MONITORING}/backoffice/jobExecutions/bulk`;
const DIDNT_RUN_IGNORE_ENDPOINT = `${MONITORING}/backoffice/jobs/didnt-run/:jobInstanceUid/ignore`;
const DISABLED_IGNORE_ENDPOINT = `${MONITORING}/backoffice/jobs/disabled/:jobInstanceUid/ignore`;

// initial state
const state = { jobs: {}, didntRunJobs: {} };

// actions
const actions = {
  UPDATE_JOB_EXECUTION(context, params) {
    return new Promise((resolve, reject) => {
      axios
        .put(SINGLE_JOB_EXECUTION_ENDPOINT.replace(':id', params._id), params)
        .then(response => resolve(response.data))
        .catch(() => reject('Error while trying to update the job execution, try again later.'));
    });
  },
  BULK_ACTION(context, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(JOB_EXECUTION_BULK_ENDPOINT, params)
        .then(response => resolve(response.data))
        .catch(e =>
          reject(
            e.response && e.response.data && e.response.data.message
              ? e.response.data.message
              : 'Error while trying to perform the bulk action, try again later.'
          )
        );
    });
  },
  IGNORE_DIDNT_RUN(context, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(DIDNT_RUN_IGNORE_ENDPOINT.replace(':jobInstanceUid', params.jobInstanceUid))
        .then(response => resolve(response.data))
        .catch(() => reject('Error while trying to add the job to the ignore, try again later.'));
    });
  },
  REMOVE_IGNORE_DIDNT_RUN(context, params) {
    return new Promise((resolve, reject) => {
      axios
        .delete(DIDNT_RUN_IGNORE_ENDPOINT.replace(':jobInstanceUid', params.jobInstanceUid))
        .then(response => resolve(response.data))
        .catch(() => reject('Error while trying to add the job to the ignore, try again later.'));
    });
  },
  GET_JOB(context, _id) {
    return new Promise((resolve, reject) => {
      axios
        .get(SINGLE_JOB_EXECUTION_ENDPOINT.replace(':id', _id))
        .then(response => resolve(response.data))
        .catch(() => reject('Error while trying get the job execution, try again later.'));
    });
  },
  IGNORE_DISABLED(context, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(DISABLED_IGNORE_ENDPOINT.replace(':jobInstanceUid', params.jobInstanceUid))
        .then(response => resolve(response.data))
        .catch(() => reject('Error while trying to add the job to the ignore, try again later.'));
    });
  },
  REMOVE_IGNORE_DISABLED(context, params) {
    return new Promise((resolve, reject) => {
      axios
        .delete(DISABLED_IGNORE_ENDPOINT.replace(':jobInstanceUid', params.jobInstanceUid))
        .then(response => resolve(response.data))
        .catch(() => reject('Error while trying to add the job to the ignore, try again later.'));
    });
  }
};

const getters = {};

const mutations = {};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};
