var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "BaseDashboardLayout",
    [
      _c("side-bar", {
        attrs: {
          slot: "sidebar",
          "background-color": _vm.sidebarBackground,
          title: _vm.sidebarTitle,
          logo: _vm.sidebarLogo,
          logoHeight: _vm.logoHeight,
          logoWidth: _vm.logoWidth,
        },
        slot: "sidebar",
        scopedSlots: _vm._u([
          {
            key: "links",
            fn: function (props) {
              return [
                _c("sidebar-item", {
                  attrs: {
                    link: {
                      name: "Dashboard",
                      icon: "tim-icons icon-chart-bar-32",
                      path: `/${_vm.ADMIN}/dashboard`,
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }