export const SET_ORG_STEP_ONE = 'O365/WIZARDS/ORGANIZATIONS/SET_STEP_ONE';
export const SET_ORG_STEP_TWO = 'O365/WIZARDS/ORGANIZATIONS/SET_STEP_TWO';
export const SET_ORG_STEP_THREE_BASIC = 'O365/WIZARDS/ORGANIZATIONS/SET_STEP_THREE_BASIC';
export const SET_ORG_STEP_FOUR_BASIC = 'O365/WIZARDS/ORGANIZATIONS/SET_STEP_FOUR_BASIC';
export const SET_ORG_STEP_FOUR_MODERN = 'O365/WIZARDS/ORGANIZATIONS/SET_STEP_FOUR_MODERN';
export const SET_ORG_STEP_FIVE_MODERN = 'O365/WIZARDS/ORGANIZATIONS/SET_STEP_FIVE_MODERN';
export const SET_ORG_STEP_THREE_LEGACY = 'O365/WIZARDS/ORGANIZATIONS/SET_STEP_THREE_LEGACY';
export const SET_ORG_STEP_FOUR_LEGACY = 'O365/WIZARDS/ORGANIZATIONS/SET_STEP_FOUR_LEGACY';
export const SET_ORG_REPO_STEP = 'O365/WIZARDS/ORGANIZATIONS/SET_REPO_STEP';
export const RESET_ORG_WIZARD = 'O365/WIZARDS/ORGANIZATIONS/RESET';

export const SET_JOB_STEP_ONE = 'O365/WIZARDS/JOBS/SET_STEP_ONE';
export const SET_JOB_STEP_TWO = 'O365/WIZARDS/JOBS/SET_STEP_TWO';
export const SET_JOB_STEP_THREE = 'O365/WIZARDS/JOBS/SET_STEP_THREE';
export const SET_JOB_STEP_FOUR = 'O365/WIZARDS/JOBS/SET_STEP_FOUR';
export const SET_JOB_STEP_FIVE = 'O365/WIZARDS/JOBS/SET_STEP_FIVE';
export const RESET_JOB_WIZARD = 'O365/WIZARDS/JOBS/RESET';

export const SET_RE_OP_ROLES_STEP_ONE = 'O365/WIZARDS/RE_OP_ROLES/SET_STEP_ONE';
export const SET_RE_OP_ROLES_STEP_TWO = 'O365/WIZARDS/RE_OP_ROLES/SET_STEP_TWO';
export const SET_RE_OP_ROLES_STEP_THREE = 'O365/WIZARDS/RE_OP_ROLES/SET_STEP_THREE';
export const SET_RE_OP_ROLES_STEP_FOUR = 'O365/WIZARDS/RE_OP_ROLES/SET_STEP_FOUR';
export const SET_RE_OP_ROLES_STEP_FIVE = 'O365/WIZARDS/RE_OP_ROLES/SET_STEP_FIVE';
export const RESET_RE_OP_ROLES_WIZARD = 'O365/WIZARDS/RE_OP_ROLES/RESET';
