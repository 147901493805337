var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "fileinput text-center" }, [
    _c(
      "div",
      {
        staticClass: "fileinput-new thumbnail",
        class: { "img-circle": _vm.type === "avatar" },
      },
      [_c("img", { attrs: { src: _vm.image, alt: "preview" } })]
    ),
    _c(
      "div",
      [
        _c("span", { staticClass: "btn btn-primary btn-simple btn-file" }, [
          _c("span", { staticClass: "fileinput-new" }, [
            _vm._v(_vm._s(_vm.fileExists ? _vm.changeText : _vm.selectText)),
          ]),
          _c("input", { attrs: { type: "hidden", value: "", name: "" } }),
          _c("input", {
            staticClass: "valid",
            attrs: {
              accept: "image/*",
              type: "file",
              name: "...",
              multiple: false,
              "aria-invalid": "false",
            },
            on: { change: _vm.handlePreview },
          }),
        ]),
        _vm.fileExists
          ? _c(
              "base-button",
              {
                attrs: { round: "", type: "danger" },
                on: { click: _vm.removeFile },
              },
              [
                _c("i", { staticClass: "fas fa-times" }),
                _vm._v(" " + _vm._s(_vm.removeText) + " "),
              ]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }