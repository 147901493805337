import axios from 'axios';
import {
  SET_JOB_STEP_ONE,
  SET_JOB_STEP_TWO,
  SET_JOB_STEP_THREE,
  SET_JOB_STEP_FOUR,
  SET_JOB_STEP_FIVE,
  RESET_JOB_WIZARD
} from './mutations-types';
import { O365 } from '@/common/data/constants';

const SET_IS_EDITING = 'O365/WIZARDS/JOBS/SET_IS_EDITING';

const JOBS_ENDPOINT = `${O365}/jobs`;
const SINGLE_JOB_ENDPOINT = `${JOBS_ENDPOINT}/:jobId`;

function initialState() {
  return {
    jobWizard: {
      stepOne: {},
      stepTwo: {},
      stepThree: {},
      stepFour: {},
      stepFive: {},
      isEditing: false
    }
  };
}

const state = initialState();

// actions
const actions = {
  async ADD_JOB({ state, commit }) {
    const data = getDataToSend(state.jobWizard);
    const response = await axios.post(JOBS_ENDPOINT, data);
    commit(RESET_JOB_WIZARD);
    return response.data;
  },
  async GET_O365_JOB({ commit }, { jobId, organizationId }) {
    const response = await axios.get(SINGLE_JOB_ENDPOINT.replace(':jobId', jobId), {
      params: { organizationId }
    });
    const { data } = response;
    if (data.success) {
      const job = data.data;
      commit(SET_JOB_STEP_ONE, { ...job });
      commit(SET_JOB_STEP_TWO, { ...job });
      commit(SET_JOB_STEP_THREE, job.excludedItems);
      commit(SET_JOB_STEP_FOUR, { ...job });
      commit(SET_JOB_STEP_FIVE, { ...job.schedulePolicy });
      commit(SET_IS_EDITING, true);
    }
    return data.success;
  },
  async DELETE_JOB(context, { jobId, organizationId }) {
    const response = await axios.delete(SINGLE_JOB_ENDPOINT.replace(':jobId', jobId), {
      data: { organizationId }
    });
    return response.data;
  },
  async EDIT_JOB({ state, commit }) {
    const data = getDataToSend(state.jobWizard);
    const response = await axios.put(SINGLE_JOB_ENDPOINT.replace(':jobId', data.id), data);
    commit(RESET_JOB_WIZARD);
    return response.data;
  }
};

function getDataToSend(jobWizard) {
  const { stepOne, stepTwo, stepThree, stepFour, stepFive } = jobWizard;
  return {
    ...stepOne,
    ...stepTwo,
    ...stepThree,
    ...stepFour,
    runNow: stepFive.runNow,
    schedulePolicy: { ...stepFive }
  };
}

const getters = {
  getJobWizStepOne: state => state.jobWizard.stepOne,
  getJobWizStepTwo: state => state.jobWizard.stepTwo,
  getJobWizStepThree: state => state.jobWizard.stepThree,
  getJobWizStepFour: state => state.jobWizard.stepFour,
  getJobWizStepFive: state => state.jobWizard.stepFive,
  getJobWizIsEditing: state => state.jobWizard.isEditing
};

const mutations = {
  /* eslint-disable no-undef */
  [SET_JOB_STEP_ONE](state, { id, name, description, organizationId, isTeamsChatsEnabled }) {
    Vue.set(state.jobWizard.stepOne, 'id', id);
    Vue.set(state.jobWizard.stepOne, 'name', name);
    Vue.set(state.jobWizard.stepOne, 'description', description);
    Vue.set(state.jobWizard.stepOne, 'organizationId', organizationId);
    Vue.set(state.jobWizard.stepOne, 'isTeamsChatsEnabled', isTeamsChatsEnabled);
  },
  [SET_JOB_STEP_TWO](state, { backupType, selectedItems }) {
    Vue.set(state.jobWizard.stepTwo, 'backupType', backupType);
    if (backupType.toLowerCase() === 'selecteditems') {
      // Set "site" when it's not present (PLAT-680)
      selectedItems?.forEach((item, index) => {
        if (item.personalSite === false && !item.hasOwnProperty('site')) {
          selectedItems[index].site = selectedItems[index].personalSite;
        }
      });
      Vue.set(state.jobWizard.stepTwo, 'selectedItems', selectedItems);
    }
  },
  [SET_JOB_STEP_THREE](state, excludedItems) {
    excludedItems?.forEach((item, index) => {
      if (item.personalSite === false && !item.hasOwnProperty('site')) {
        excludedItems[index].site = excludedItems[index].personalSite;
      }
    });
    Vue.set(state.jobWizard.stepThree, 'excludedItems', excludedItems);
  },
  [SET_JOB_STEP_FOUR](state, { proxyId, repositoryId }) {
    Vue.set(state.jobWizard.stepFour, 'proxyId', proxyId);
    Vue.set(state.jobWizard.stepFour, 'repositoryId', repositoryId);
  },
  [SET_JOB_STEP_FIVE](
    state,
    {
      runAuto,
      type,
      dailyType,
      dailyTime,
      runNow,
      retryEnabled,
      backupWindowEnabled,
      periodicallyEvery
    }
  ) {
    Vue.set(state.jobWizard.stepFive, 'runAuto', runAuto);
    Vue.set(state.jobWizard.stepFive, 'type', type);
    Vue.set(state.jobWizard.stepFive, 'dailyType', dailyType);
    Vue.set(state.jobWizard.stepFive, 'dailyTime', dailyTime);
    Vue.set(state.jobWizard.stepFive, 'runNow', runNow);
    Vue.set(state.jobWizard.stepFive, 'retryEnabled', retryEnabled);
    Vue.set(state.jobWizard.stepFive, 'backupWindowEnabled', backupWindowEnabled);
    Vue.set(state.jobWizard.stepFive, 'periodicallyEvery', periodicallyEvery);
  },
  [RESET_JOB_WIZARD](state) {
    Vue.set(state.jobWizard, 'stepOne', {});
    Vue.set(state.jobWizard, 'stepTwo', {});
    Vue.set(state.jobWizard, 'stepThree', {});
    Vue.set(state.jobWizard, 'stepFour', {});
    Vue.set(state.jobWizard, 'stepFive', {});
    Vue.set(state.jobWizard, 'isEditing', false);
  },
  [SET_IS_EDITING](state, data) {
    Vue.set(state.jobWizard, 'isEditing', data);
  }
};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};
